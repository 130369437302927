import axios from "axios";
import { API_BASE_URL } from "../base_url/Base_URL";

export const get_team_Intervie_wWith_Round = async (headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/get-teamInterviewWithRound`, {}, { headers })
        return response
    } catch (error) {
        return error
    }
}

export const get_team_Interview_With_Round_Details = async (data, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/get-teamInterviewWithRound`, data, { headers })
        return response
    } catch (error) {
        return error
    }
}

export const team_interview_round_feedback = async (data, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team-interview-round-feedback`, data, { headers })
        return response
    } catch (error) {
        return error
    }
}

export const Team_Profile = async (data, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team-profile`, data, { headers })
        return response
    } catch (error) {
        return error
    }
}
// <---------- Team Dashboard ----------->
export const Team_Dashboard = async (data, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team-dashboard`, data, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-------- team-complete-interview-round ---------->
export const Team_Complete_Interview_Round = async (data, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team-complete-interview-round`, data, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-------- recruiter-team-info ---------->
export const Recruiter_Team_Info = async (data, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/recruiter-team-info`, data, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-------- recruiter-team-info ---------->
export const Team_Post_Interview_Round = async (data, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team-post-interview-round`, data, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------- Complete Reject InterView ---------------->
export const Team_Complete_Reject_Interview = async (CompleteInterviewRoundData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team-complete-reject-interview`,
            CompleteInterviewRoundData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------- Complete Reject InterView ---------------->
export const team_post_support_data = async (CompleteInterviewRoundData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team-post-support-data`,
            CompleteInterviewRoundData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-------------- Student Support List ------------>
export const team_get_support_data = async(headers)=>{
    try{
        const response = await axios.get(`${API_BASE_URL}/team-get-user-support-data`,{headers})
        return response
    }catch(error){
     return error
    }
}

// <------------- Get Recuriter Job Post sections ----------------->
export const Team_Recruiter_Job = async (data,headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team-recruiter-job`, data, { headers })
        return response
    } catch (error) {
        return error
    }
}