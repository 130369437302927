import axios from "axios";
import { API_BASE_URL } from "../base_url/Base_URL";


// <----------------- Check Email ----------------------->
export const CheckEmail = async (emailData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/email-check`, emailData)
        return response
    } catch (error) {
        return error
    }
}

// <---------------- Student Register Api's Function--------------->
export const Register = async (userData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/register`, userData)
        return response;
    } catch (error) {
        return error
    }

}

// <-------------- Send Otp for the Student Register's -------------------->
export const SendOtp = async (SendOtpData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/send-otp`, SendOtpData)
        return response
    } catch (error) {
        return error
    }
}

// <----------- Otp Verify Api function's ------------------>
export const OtpVerify = async (OtpVerifyData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/confirm-otp`, OtpVerifyData);
        return response;
    } catch (error) {
        return error;
    }
};


// <---------------- Login Api's Methods -------------------------->
export const UserLogin = async (credentials) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/login`, credentials);
        return response;
    } catch (error) {
        return error;
    }
};


// <---------------- Login Api's Methods -------------------------->
export const ForgotPass = async (ForgotPassData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/reset-password`, ForgotPassData);
        return response;
    } catch (error) {
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.message;
            if (errorMessage === "Incorrect password") {
                alert("Incorrect password !!!");
            }
        }
        return error;
    }
};

// <---------------- Change Password ------------------------------->
export const ChangePassword = async (ChangePasswordData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/password-update`, ChangePasswordData, { headers });
        return response
    } catch (error) {
        if (error.response.data.msg == "Incorrect old password") {
            alert("Incorrect Current password !!!")
        }
        return error
    }
}

// <---------------- Login Api's Methods -------------------------->
export const ProfileSections = async () => {
    const user_id = localStorage.getItem("User_id");
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };
    try {
        const response = await axios.post(`${API_BASE_URL}/user-profile`, { user_id }, { headers });
        return response
    } catch (error) {
        return error
    }
};

// <----------------- Profile Update ---------------------->
export const ProfileUpdate = async (ProfileUpdateData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/user-update`, ProfileUpdateData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------------- Profile Update ---------------------->
export const MentorProfileUpdate = async (ProfileUpdateData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/update-mentor-profile`, ProfileUpdateData, { headers })
        return response
    } catch (error) {
        return error
    }
}


// <------- team Member Email Check ---------------->
export const Team_Email_Check = async (ProfileUpdateData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team-email-check`, ProfileUpdateData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <------------  team-send-otp  -------------->
export const Team_Send_Otp = async (ProfileUpdateData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team-send-otp`, ProfileUpdateData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <------------  team-send-otp  -------------->
export const Team_Confirm_Otp = async (ProfileUpdateData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team-confirm-otp`, ProfileUpdateData, { headers })
        return response
    } catch (error) {
        return error
    }
}

//  <----------- team-login    ------------->
export const Team_Login = async (ProfileUpdateData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team-login`, ProfileUpdateData, { headers })
        return response
    } catch (error) {
        return error
    }
}
//  <----------- team-login    ------------->
export const Team_Password_Update = async (ProfileUpdateData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team-password-update`, ProfileUpdateData, { headers })
        return response
    } catch (error) {
        return error
    }
}