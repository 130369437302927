import React, { useState, useEffect } from 'react'
import { GetRecuriterDetails, RecuriterDetails } from '../../../../api/Recuriter';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { countries } from '../../../common/countrylist/CountryList';
import Loader from '../../../../loader/Loader';

const RecruiterStep = () => {
  const navigate = useNavigate();
  const [is_Loading, setIs_Loading] = useState(false);
  const [Opportunity_Title, setOpportunity_Title] = useState("");
  const [Organisation, setOrganisation] = useState("");
  const [Website_URL, setWebsite_URL] = useState("");
  const [Categories, setCategories] = useState("");
  const [Skills, setSkills] = useState("");
  const [About_Opportunity, setAbout_Opportunity] = useState("");
  const [Application_End, setApplication_End] = useState("");
  const [Application_Start, setApplication_Start] = useState("");
  const [Salary_Start, setSalary_Start] = useState("");
  const [Salary_type, setSalary_type] = useState("");
  const [status, setStatus] = useState("")
  const [DOB, setDOB] = useState("");
  const [selectNationlity, setSelectNationlity] = useState("");
  const [ActiveTab, setActiveTab] = useState(0);
  const tabs = [0, 1, 2];

  const today = new Date();

  // Calculate the date 18 years ago
  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() - 18);

  // Convert the max date to ISO format for the input
  const maxDateString = maxDate.toISOString().split("T")[0];

  // <---- User Authentication token ------->
  const token = localStorage.getItem("User_Token");
  const headers = {
    "Authorization": `Bearer ${token}`
  };

  // <----------- Handle Previous Pages ------------------>
  const handlePrevious = () => {
    const currentIndex = tabs.indexOf(ActiveTab);
    const previousIndex = (currentIndex - 1 + tabs.length) % tabs.length;
    setActiveTab(tabs[previousIndex]);
  };
  // <----------- Handle Next Pages ------------------>
  const handleNext = () => {
    const currentIndex = tabs.indexOf(ActiveTab);
    const nextIndex = (currentIndex + 1) % tabs.length;
    setActiveTab(tabs[nextIndex]);
  };

  const validateURL = (url) => {
    const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\/?$/;
    return regex.test(url);
  };

  const handleNationlityChange = (e) => {
    setSelectNationlity(e.target.value);
  };

  // <----------- Handle Recruiter Salary Range -------------->
  const HandleRecruiterSalaryRange = async () => {
  setIs_Loading(true)
  const updaterecruiterInfoData = {
    job_title:Opportunity_Title,
    DOB:DOB,
    nationality:selectNationlity
  }
  try{
    const response = await RecuriterDetails(updaterecruiterInfoData, headers)
    if (response?.data?.statusCode == "200") {
      toast.success("Successfully Update the Recuriter Details !!!")
      setIs_Loading(false)
      navigate("/recruiter_pages/RecruiterDashboard")
    }
    console.log("HandleRecruiterSalaryRange", response)
  }catch(error){
      setIs_Loading(false)
      console.log("error",error)
    }
  
  }

  // <------ Handle the Active Tabs sections ---------->
  const HandleActiveTab = (index) => {
    setActiveTab(index)
  }

  // <---------- Fetch the User Data ------------>
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetRecuriterDetails({ headers });
      console.log("GetRecuriterDetails", response)
      setOpportunity_Title(response?.data?.data?.opp_title)
      setOrganisation(response?.data?.data?.website_url)
      setCategories(response?.data?.data?.categories)
      setSkills(response?.data?.data?.skills)
      setAbout_Opportunity(response?.data?.data?.about_opportunity)
      setApplication_End(response?.data?.data?.application_end_date)
      setApplication_Start(response?.data?.data?.application_start_date)
      setSalary_Start(response?.data?.data?.salary)
      setSalary_type(response?.data?.data?.salary_type)
      setStatus(response?.data?.data?.status)
    };
    fetchData();
  }, []);
  console.log("status", status)

  // <--------- Render the Recuriter DashBoard Page When Recuriter Step form Completed ------------>
  useEffect(() => {
    if (status === true) {
      navigate("/recruiter_pages/RecruiterDashboard")
    }
  }, [status, navigate]);

  return (
    <div>
      {
        is_Loading ? (
        <Loader/>
        ):(
          <div className="stepbg">
          <ToastContainer style={{ marginTop: "120px" }} />
          <div className="client_filter-job pt-1 pb-0">
            <div className="stepsForm">
              <form method="post" id="msform">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="sf-steps">
                        <h5 className="default-btn w-100 text-white m-0 py-3 px-4">Recruiter</h5>
                        <div className="left-slid-filter space-box-two shadow-none">
                          <div className="sf-steps-content">
                            <div
                              className={`joblisttitle ${ActiveTab === 0 ? "sf-active" : ""}`} onClick={() => HandleActiveTab(0)}>
                              <span>1</span>
                              <aside className="list-icons">
                                <h5>Basic Details</h5>
                              </aside>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 d-flex">
                      <div className="flexdr position-relative">
                        <div className="sf-steps-form sf-radius">
                          {
                            ActiveTab === 0 && (
                              <ul className="sf-content m-0">
                                <div className="find-com-box p-0">
                                  <div className="professional-sp">
                                    <h4>Basic Details</h4>
                                    <h6>Fill all form field to go to next step</h6>
                                  </div>
                                  <hr />
                                  <div className="professional-sp row">
                                    <div className="col-12 col-md-12">
                                      <div className="form-group">
                                        <label className="fieldlabels">Job Title</label>
                                        <input type="text" name="Opportunity_Title"
                                          value={Opportunity_Title}
                                          onChange={(e) => setOpportunity_Title(e.target.value)}
                                          placeholder="Title" />
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                      <div className="form-group">
                                        <label className="fieldlabels">DOB</label>
                                        <input
                                          type="date"
                                          name="DOB"
                                          value={
                                            DOB
                                              ? new Date(DOB).toISOString().split("T")[0]
                                              : ""
                                          }
                                          onChange={(e) => {
                                            const enteredDate = new Date(e.target.value);
                                            if (enteredDate > maxDate) {
                                              // Show validation error but don't update the DOB state
                                              toast.error("You must be at least 18 years old.");
                                            } else {
                                              setDOB(e.target.value); // Update state if the date is valid
                                            }
                                          }} g
                                          max={maxDateString}
                                          placeholder="DOB"
                                        />
                                        {/* Validation Message */}
                                        {DOB && new Date(DOB) > maxDate && (
                                          <p style={{ color: "red" }}>
                                            You must be at least 18 years old.
                                          </p>
                                        )}
                                      </div>
                                    </div>
  
                                    <div className="col-12 col-md-12">
                                      <div className="form-group">
                                        <label className="fieldlabels"> Nationality</label>
                                        <select
                                          id="country-select"
                                          value={selectNationlity}
                                          name='nationality'
                                          onChange={handleNationlityChange}
                                          className="form-control"
                                        >
                                          <option value="">
                                            --Select a Nationality--
                                          </option>
                                          {countries?.map((country, index) => (
                                            <option key={index} value={country}>
                                              {country}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="sf-steps-navigation d-flex justify-content-between">
                                      <span id="sf-msg" className="sf-msg-error"></span>
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleNext();
                                          HandleRecruiterSalaryRange()
                                        }}
                                        id="sf-next"
                                        type="submit"
                                        className="btn default-btn"
                                        disabled={!Opportunity_Title || !DOB || !selectNationlity}
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        )
      }
     
    </div>
  )
}

export default RecruiterStep