import React, { useState, useEffect } from 'react'
import 'react-tagsinput/react-tagsinput.css';
import { Link } from 'react-router-dom';
import CommonImageTransport from '../../../common/images/CommonImages';
import Loader from '../../../../loader/Loader';
import moment from 'moment';
import Typewriter from 'typewriter-effect';
import { GetNewJobList } from '../../../../api/Recuriter';
import ReactHtmlParser from "react-html-parser";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { FaMoneyBill1Wave } from "react-icons/fa6";
import Team_Member_Footer from '../team_member_footer/Team_Member_Footer';
import Team_Member_Header from '../team_member_header/Team_Member_Header';
import { Team_Recruiter_Job } from '../../../../api/Team_Member';

const Team_Member_Job_List = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [is_Loading, setIs_Loading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showSearchFields, setShowSearchFields] = useState(false);
    const [JobList, setJobList] = useState([]);
    const defaultTabIndex = 0;
    const [show, setShow] = useState(false);
    const [DeletePostshow, setDeletePostshow] = useState(false);
    const [status, setStatus] = useState(1);
    const recruiter_userid = localStorage.getItem("recruiter_id");
    // <-------- User Authentication token's ------------>
    const token = localStorage.getItem("team_token");
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
    };

    // <-------- Search the Jobs by title & location ------------->
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    // <------------ Render the Job List here ------------>
    useEffect(() => {
        GetJobList();
    }, [])

    // <------------ Get Job List ------------>
    const GetJobList = async () => {
        setIs_Loading(true)
        const data = {
            recruiter_id: recruiter_userid,
            status:1
        }
        try {
            const response = await Team_Recruiter_Job(data,headers);
            setJobList(response?.data?.data)
            setIs_Loading(false)
        } catch (error) {
            setIs_Loading(true)
        }

    }
    const [ActiveTabs, setActiveTabs] = useState(JobList?.id);

   

    // <-------------- Search the Job List ------------>
    const filteredList = JobList?.filter(item =>
        item?.title?.toLowerCase().includes(searchTerm) ||
        item?.organization?.toLowerCase().includes(searchTerm) ||
        item?.unique_id?.toLowerCase().includes(searchTerm) ||
        item?.location?.toLowerCase().includes(searchTerm)
    );

    // <--------- This UseEffect for Loader Pages-------->
    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 2200);

        return () => clearTimeout(timeout);
    }, []);

    // <------------ Handle the Active & UnActive Student List here -----------> 
    const HandleActiveTabs = (tabId) => {
        setActiveTabs(tabId);
    }


    // <----------------- Active the First Job's and when filter then automtics filter the active's tabs --------------->
    useEffect(() => {
        if (filteredList?.length > 0 && (!ActiveTabs || !filteredList?.some(job => job.id === ActiveTabs))) {
            const firstActiveTab = filteredList[0];
            if (firstActiveTab) {
                setActiveTabs(firstActiveTab?.id);
            }
        }
    }, [filteredList, ActiveTabs]);

    return (
        <div>
            {
                is_Loading ? (
                    <>
                        <Loader />
                    </>

                ) : (
                    <>
                        <div className="bg_nav-slid bg-white">
                            <Team_Member_Header />
                        </div>
                        <div className="header_space"></div>
                        {
                            JobList?.length > 0 ? (

                                <>
                                    <div className="recruiter_list pb-5">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-12">
                                                    <form>
                                                        <ToastContainer style={{ marginTop: "120px" }} />
                                                        <div className="searchlocation">
                                                            <div className="iconfield">
                                                                <div className="icon_search">
                                                                    <img src={CommonImageTransport?.searchicon} alt="" />
                                                                </div>
                                                                {!showSearchFields ? (
                                                                    <div
                                                                        onClick={() => setShowSearchFields(true)} >
                                                                        <Typewriter
                                                                            options={{
                                                                                loop: true,
                                                                            }}
                                                                            onInit={(typewriter) => {
                                                                                typewriter.typeString('Search by title')
                                                                                    .callFunction(() => {
                                                                                    })
                                                                                    .pauseFor(2500)
                                                                                    .deleteAll()
                                                                                    .callFunction(() => {
                                                                                    })
                                                                                    .typeString('Search by location')
                                                                                    .pauseFor(2500)
                                                                                    .start();
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <input type="text"
                                                                            placeholder=""
                                                                            value={searchTerm}
                                                                            onChange={handleSearchChange}
                                                                            style={{ border: "none", outline: "none" }} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="iconfield">
                                                                <div className="icon_search">
                                                                    <img src={CommonImageTransport?.locationicon} alt="" />
                                                                </div>
                                                                <div className="input-field">
                                                                    <input type="text" placeholder="USA, Florida" onChange={handleSearchChange} className="form-control" required="" />
                                                                </div>
                                                            </div>
                                                            <div className="input-field ad121">
                                                                <button type="button" className="submit_btn">Search</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="left_recruiterlist">
                                                        <div className="sticky-header">
                                                            <div className="nav flex-column recruiter_all_lists nav-pills" id="v-pills-tab" role="tablist"
                                                                aria-orientation="vertical">
                                                                {
                                                                    filteredList?.reverse()?.map((JobListResult, index) => {
                                                                        return (
                                                                            <>
                                                                                <div key={index} className={`nav-link ${ActiveTabs === JobListResult?.id ? "active" : ""}`}
                                                                                    onClick={() => HandleActiveTabs(JobListResult?.id)}
                                                                                    id="v-pills-home-tab" data-bs-toggle="pill"
                                                                                    data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
                                                                                    aria-selected="true">
                                                                                    <div className="card_rec position-relative">
                                                                                        <div className="mentersvies0121">
                                                                                            <div>
                                                                                                {
                                                                                                    JobListResult?.job_image != null ? (
                                                                                                        <>
                                                                                                            <img src={JobListResult?.job_image} style={{ borderRadius: "5px" }}
                                                                                                                className="" alt="Instructor" />
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <img src={CommonImageTransport?.companybuilding} style={{ borderRadius: "5px" }}
                                                                                                                className="" alt="Instructor" />
                                                                                                        </>

                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                            <div className="instructors-details-contents">
                                                                                                <div className="comnam">
                                                                                                    <h3>{JobListResult?.title}</h3>
                                                                                                    <h6 className="sub-title">{JobListResult?.organization}</h6>
                                                                                                    <p>Job Id:{JobListResult?.unique_id}</p>
                                                                                                </div>
                                                                                                <p className="perographsv">
                                                                                                    <i className="ri-map-pin-fill"></i>{JobListResult?.location}
                                                                                                </p>
                                                                                                {
                                                                                                    JobListResult?.status == "1" ? (
                                                                                                        <a href="#"
                                                                                                            className="text-success">Active</a>
                                                                                                    ) : (
                                                                                                        <a href="#"
                                                                                                            className="text-danger">UnActive</a>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }


                                                            </div>
                                                        </div>
                                                        {
                                                            filteredList?.find(job => job.id === ActiveTabs) && (
                                                                <div className="tab-content" id="v-pills-tabContent">
                                                                    <div className={`tab-pane fade ${ActiveTabs ? "show active" : ""
                                                                        }`}
                                                                        id={`v-pills-${ActiveTabs}`}
                                                                        role="tabpanel"
                                                                        aria-labelledby={`v-pills-${ActiveTabs}-tab`}>
                                                                        <div className="instructors-details-img m-0">
                                                                            <div className="card-instrutors shadow position-relative bg-white p-4">

                                                                                <div className="mentersvies0121 recruiter101s">
                                                                                    {
                                                                                        filteredList?.find((job) => job.id === ActiveTabs)?.job_image != null ? (
                                                                                            <>
                                                                                                <div>
                                                                                                    <img src={filteredList?.find((job) => job.id === ActiveTabs)?.job_image} className="" alt="Instructor" style={{ borderRadius: '5px' }} />
                                                                                                </div>
                                                                                            </>

                                                                                        ) : (
                                                                                            <>
                                                                                                <div>
                                                                                                    <img src={CommonImageTransport?.companybuilding}
                                                                                                        className="" alt="Instructor" style={{ borderRadius: '5px' }} />
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }


                                                                                    <div className="instructors-details-contents">
                                                                                        <h3>{filteredList?.find((job) => job.id === ActiveTabs)?.title}</h3>
                                                                                        <h6>Job Id:{filteredList?.find((job) => job.id === ActiveTabs)?.unique_id}</h6>
                                                                                        <p><Link to="#"><i className="ri-community-line"></i> {filteredList?.find((job) => job.id === ActiveTabs)?.organization}</Link></p>
                                                                                        <p><i className="ri-map-pin-fill"></i> {filteredList?.find((job) => job.id === ActiveTabs)?.location}</p>
                                                                                        <div className='d-flex justify-content-around align-items-center" '>
                                                                                            <p className="small m-0 fw-bold"><span className="fw-normal">
                                                                                                Application Start:</span>{moment(filteredList?.find((job) => job.id === ActiveTabs)?.startdate).format('MM/DD/YYYY')} </p>

                                                                                            <p className="small m-0 fw-bold"><span className="fw-normal">
                                                                                                Application End:</span>
                                                                                                {moment(filteredList?.find((job) => job.id === ActiveTabs)?.enddate).format('MM/DD/YYYY')}</p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="footerapplly border-top d-flex justify-content-between mt-3 pt-2">
                                                                                    <div className="d-flex gap-2 align-items-center">
                                                                                        {
                                                                                            ((filteredList?.find((job) => job.id === ActiveTabs)?.currencytype != null) && (filteredList?.find((job) => job.id === ActiveTabs)?.salarypackage != "0")) && (
                                                                                                <>
                                                                                                    <div className="bg-light p-2"><FaMoneyBill1Wave /></div>
                                                                                                    <div>
                                                                                                        <p className="small m-0 fw-bold">{filteredList?.find((job) => job.id === ActiveTabs)?.currencytype}  {filteredList?.find((job) => job.id === ActiveTabs)?.salarypackage} </p>

                                                                                                    </div>
                                                                                                </>

                                                                                            )
                                                                                        }

                                                                                    </div>
                                                                                    <div className="d-flex gap-2 align-items-center">
                                                                                        <div className="bg-light p-2"><i className="ri-eye-line"></i></div>
                                                                                        <div>
                                                                                            <p className="small m-0 fw-bold"><span className="fw-normal">Job Type:</span>
                                                                                                {filteredList?.find((job) => job.id === ActiveTabs)?.employmenttype} </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex gap-2 align-items-center">
                                                                                        <div className="bg-light p-2"><i className="ri-user-line"></i></div>
                                                                                        <div>
                                                                                            <p className="small m-0 fw-bold"><span className="fw-normal">No of Openings:</span>
                                                                                                {filteredList?.find((job) => job.id === ActiveTabs)?.postion_no} </p>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="align-items-center">
                                                                                        {filteredList?.find((job) => job.id === ActiveTabs)?.status == "1" ? (
                                                                                            <>
                                                                                                <a href="#" onClick={() => setDeletePostshow(true)}
                                                                                                    className="default-btn bg-success m-1 py-2 rounded">Active</a>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <a href="#"
                                                                                                    className="default-btn bg-danger m-1 py-2 rounded">UnActive</a>
                                                                                            </>
                                                                                        )
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="instructors-details-contents mt-3">
                                                                            <div className="card-instrutors shadow bg-white p-4">
                                                                                <h5>Skills</h5>
                                                                                <div>
                                                                                    {filteredList?.find((job) => job.id === ActiveTabs)?.skills
                                                                                        ?.replace(/[{}"\\]/g, '')
                                                                                        ?.split(/,\s*/)
                                                                                        ?.map((skill, index, array) => (
                                                                                            <span key={index}>
                                                                                                {skill.trim()}
                                                                                                {index < array.length - 1 && ', '}
                                                                                            </span>
                                                                                        ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="100"
                                                                            className="scrollspy-example" tabindex="0">
                                                                            <div id="scrollspyHeading1" className="instructors-details-contents mt-3">
                                                                                <div className="card-instrutors shadow bg-white p-4">
                                                                                    <h5>Details</h5>

                                                                                    {ReactHtmlParser(filteredList?.find((job) => job.id === ActiveTabs)?.aboutjob)}
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                            ) : (
                                <>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <div className='text-center mt-5' style={{ marginTop: "100px", marginBottom: "30px" }}>
                                        <h4>Not Any Job Avaiable Here....</h4>
                                    </div>
                                </>

                            )
                        }
                    </>
                )
            }

            <Team_Member_Footer />
        </div>
    )
}

export default Team_Member_Job_List