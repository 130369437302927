import axios from "axios";
import { API_BASE_URL } from "../base_url/Base_URL";

// <------------- Student Profile sections ----------------->
export const StudentProfile = async(StudentProfile,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/update-studentInfo`,StudentProfile,{headers})
      return response
    }catch(error){
        return error
    }
  }

//   <--------- Get Student Profile sections ------------------>
export const GetStudentProfile = async()=>{
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };
    try{
        const response = await axios.get(`${API_BASE_URL}/get-studentInfo`,{headers})
        return response
    }catch(error){
     return error
    }
}

// <------------ Student Give Mentor's Feedback -------------->
export const PostStudentMentorFeedback= async(feedbackData,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/mentor-feedback`,feedbackData,{headers})
        return response
    }catch(error){
     return error
    }
}

// <------------- get the Mentor Feedback ---------------------->
export const GetStudentMentorFeedback= async(feedbackData,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/get-mentor-feedbacks`,feedbackData,{headers})
        return response
    }catch(error){
     return error
    }
}


// <--------- Get Student details (Resume)------------------>
export const GetStudentResume= async(StudentResumeData,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/student-resume`,StudentResumeData,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Student get Job List ------------>
export const GetStudentJobsList = async(headers)=>{
    try{
        const response = await axios.get(`${API_BASE_URL}/new-job-list`,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Apply the Job  ------------>
export const StudentJobApply = async(StudentJobApplyData,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/job-apply`,StudentJobApplyData,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Student Applied Job List ------------>
export const StudentAppliedJob = async(headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/student-appliedjob-list`,{},{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Student Course List ------------>
export const StudentCourseList= async(data,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/student-course`,data,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Student Course List Traning Program Classess ------------>
export const StudentCourseListTraningProgramClassess= async(data,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/student-course`,data,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Student Course Continue ------------>
export const StudentCourseContinue = async(data,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/post-student-course`,data,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Get Student Class Data ------------>
export const StudentClassData = async(data,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/student-class-data`,data,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Student Post Step ------------>
export const StudentPostStep = async(data,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/post-student-step`,data,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Post Student Mcq for the classes ------------>
export const PostStudentMcq = async(data,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/post-student-mcq`,data,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- get-student-class-mcq ------------>
export const GetStudentClassMcq = async(data,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/get-student-class-mcq`,data,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Get Student Step of the classes ------------>
export const GetStudentStep = async(data,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/get-student-step`,data,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Create  Student Support ------------>
export const StudentSupport = async(data,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/post-support-data`,data,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Student Support List ------------>
export const StudentSupportList = async(headers)=>{
    try{
        const response = await axios.get(`${API_BASE_URL}/get-user-support-data`,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Student Dashboard ------------>
export const StudentDashboard = async(data,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/student-dashboard`,data,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Post Course Review ------------>
export const PostCourseReview = async(data,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/post-course-review`,data,{headers})
        return response
    }catch(error){
     return error
    }
}

// <-------------- Post Course Review ------------>
export const Student_Attempt_Update = async(data,headers)=>{
    try{
        const response = await axios.post(`${API_BASE_URL}/student-attempt-update`,data,{headers})
        return response
    }catch(error){
     return error
    }
}




