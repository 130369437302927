import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CommonImageTransport from '../../../common/images/CommonImages';
import Loader from '../../../../loader/Loader';
import Typewriter from 'typewriter-effect';
import { Accordion } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating';
import Recruiter_Header from '../recruiter_header/Recruiter_Header';
import { GetCompletedInterviewList } from "../../../../api/Recuriter";
import Recruiter_Footer from '../recruiter_footer/Recruiter_Footer';
import Recuriter_Student_Resume_Modal from '../recuriter_student_resume_modal/Recuriter_Student_Resume_Modal';

const RecruiterList = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    // <--------- This is UseState for Loader Pages-------->
    const [isLoading, setIsLoading] = useState(true);
    const [showMore, setShowMore] = useState(false);
    const [ShowStudentResumeModal, setShowStudentResumeModal] = useState(false);

    // <------------ Open the Technical Interview Feedback sections --------------->
    const [showMoreDecisionAccordion, setShowMoreDecisionAccordion] = useState(false);
    const [openDecisionAccordion, setOpenDecisionAccordion] = useState(false);

    const [showSearchFields, setShowSearchFields] = useState(false);
    const [CompleteInterviewList, setCompleteInterviewList] = useState([]);
    const [CompleteInterviewsFeedback, setCompleteTechnicalInterviewsFeedback] = useState([]);
    const [ActiveTab, setActiveTab] = useState(CompleteInterviewList?.id);

    const [openTechnicalAccordions, setOpenTechnicalAccordions] = useState({});
    const [showMoreTechnicalContent, setShowMoreTechnicalContent] = useState({});

    const [openBehavirolAccordions, setOpenBehavirolAccordions] = useState({});
    const [showMoreBehavirolContent, setShowMoreBehavirolContent] = useState({});

    const contentRef = useRef(null);
    // <---------- Toggle Functionlity on the technical interview Accordtion section's ------------->
    const technicaltoggleAccordion = (key) => {
        setOpenTechnicalAccordions((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    // <---------- Toggle For Show More & Less Functionlity on the technical interview section's ------------->
    const toggleTechShowMore = (key) => {
        setShowMoreTechnicalContent((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));

    };

    // <---------- Toggle  Functionlity on the Behavirol interview Accordtion section's ------------->
    const BehaviroltoggleAccordion = (key) => {
        setOpenBehavirolAccordions((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    // <------------- Toggle For Show More & Less Functionlity on the Behavirol interview Accordtion section's ------------->
    const BehaviroltoggleTechShowMore = (key) => {
        setShowMoreBehavirolContent((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));

    };

    // <------------ User Authentication token's here ------------->
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };
    // <---- Handle the Feedback show more & Less functionlity here ------------>
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    // <---------- Get the Complete Interview List --------------->
    const HandleGetCompleteInterviewList = async () => {
        setIsLoading(true)
        try {
            const CompletedInterviewListData = {
                status: 3
            }
            const response = await GetCompletedInterviewList(CompletedInterviewListData, headers)
            if (response?.data?.statusCode == 401) {
                localStorage.clear();
                navigate("/Signin")
                window.location.reload()
              }
            if (response?.data?.statusCode == "200") {
                setIsLoading(false)
                setCompleteInterviewList(response?.data?.data)
            }
        } catch (error) {
            setIsLoading(true)
        }

    }
    console.log("HandleGetCompleteInterviewList", CompleteInterviewList)

    // <--------- This UseEffect for Loader Pages-------->
    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         setIsLoading(false);
    //     }, 2200);

    //     return () => clearTimeout(timeout);
    // }, []);

    // <---------- Handle the Active & unActive Student's ---------->
    const HandleActiveTab = (tabs) => {
        setActiveTab(tabs)
    }

    const filteredList = CompleteInterviewList?.filter(item =>
        item?.student?.fname?.toLowerCase().includes(searchTerm) ||
        item?.student?.lname?.toLowerCase().includes(searchTerm) ||
        item?.job?.title?.toLowerCase().includes(searchTerm) ||
        item?.job?.email?.toLowerCase().includes(searchTerm) ||
        item?.job?.location?.toLowerCase().includes(searchTerm) ||
        item?.job?.unique_id?.toLowerCase().includes(searchTerm) ||
        item?.job?.organization?.toLowerCase().includes(searchTerm)
    );

    // <---------- Render the Get Complete Interview List here -------------->
    useEffect(() => {
        HandleGetCompleteInterviewList()
    }, [])

    // <----------- Bydefault First data active and Get the first Active Student Details here ---------->
    useEffect(() => {
        if (CompleteInterviewList.length > 0) {
            HandleActiveTab(CompleteInterviewList[0]?.id);
            GetCompletedInterviewListById(CompleteInterviewList[0]?.id);
        }
    }, [CompleteInterviewList]);

    // <--------------- get the Complete interview details by Id --------------->
    const GetCompletedInterviewListById = (id) => {
        const TechnicalInterviews = CompleteInterviewList?.filter(detail => detail?.id === id)
        setCompleteTechnicalInterviewsFeedback(TechnicalInterviews)
    }

    // Filter the interview rounds to only include Technical rounds
    const TechnicalInterviewsFeedback = CompleteInterviewsFeedback?.flatMap(interviewWithRoundRes =>
        interviewWithRoundRes?.interviewdetails?.filter(detail => detail?.roundtype === "Technical") || []
    );

    // Filter the interview rounds to only include Behavioral rounds
    const BehavioralInterviewsFeedback = CompleteInterviewsFeedback?.flatMap(interviewWithRoundRes =>
        interviewWithRoundRes?.interviewdetails?.filter(detail => detail?.roundtype === "Behavioral") || []
    );

    // Filter the interview rounds to only include Decision rounds
    const DecisionInterviewsFeedback = CompleteInterviewsFeedback?.flatMap(interviewWithRoundRes =>
        interviewWithRoundRes?.interviewdetails?.filter(detail => detail?.roundtype === "Decision") || []
    );
    console.log("DecisionInterviewsFeedback", DecisionInterviewsFeedback)

    // console.log("setCheckedRequestMoreProfileList", CheckedRequestMoreProfileList)
    const handleShowStudentResume = () => {
        setShowStudentResumeModal(true);
    };

    const handleCloseStudentResume = () => {
        setShowStudentResumeModal(false);
    }
    return (
        <div>
            {
                isLoading ? <Loader /> : (
                    <>
                        <div className="bg_nav-slid bg-white">
                            <Recruiter_Header />
                        </div>
                        <div className="recruiter_list pb-5 pt-4">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <ul className="nav tabs_signup justify-content-start gap-2 mb-3">
                                            <li className="nav-item">
                                                <Link className="nav-link" aria-current="page" to="/recruiter_pages/NewProfile">New Profile</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/recruiter_pages/RecruiterInterviewing">Interviewing</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link active" to="/recruiter_pages/RecruiterList">Hired</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/recruiter_pages/Recuriter_Rejected">Rejected</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/recruiter_pages/AllProfile">All</Link>
                                            </li>

                                        </ul>
                                        {
                                            CompleteInterviewList?.length > 0 ? (
                                                <>
                                                    <form>
                                                        <div className="searchlocation">
                                                            <div className="iconfield">
                                                                <div className="icon_search">
                                                                    <img src={CommonImageTransport?.searchicon} alt="" />
                                                                </div>
                                                                {!showSearchFields ? (
                                                                    <div
                                                                        onClick={() => setShowSearchFields(true)}>
                                                                        <Typewriter
                                                                            options={{
                                                                                loop: true,
                                                                            }}
                                                                            onInit={(typewriter) => {
                                                                                typewriter.typeString('Search by title')
                                                                                    .callFunction(() => {
                                                                                    })
                                                                                    .pauseFor(2500)
                                                                                    .deleteAll()
                                                                                    .callFunction(() => {
                                                                                    })
                                                                                    .typeString('Search by location')
                                                                                    .pauseFor(2500)
                                                                                    .start();
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <input type="text"
                                                                            placeholder=""
                                                                            onChange={handleSearchChange}
                                                                            style={{ border: "none", outline: "none" }} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="iconfield">
                                                                <div className="icon_search">
                                                                    <img src={CommonImageTransport?.locationicon} alt="" />
                                                                </div>
                                                                <div className="input-field">
                                                                    <input type="text" placeholder="USA, Florida" onChange={handleSearchChange}
                                                                        className="form-control"
                                                                        required="" />
                                                                </div>
                                                            </div>
                                                            <button type="button" onClick={handleSearchChange} className="submit_btn">Search</button>
                                                        </div>
                                                    </form>
                                                    <div className="left_recruiterlist">
                                                        <div className="sticky-header">
                                                            <div className="nav flex-column recruiter_all_lists nav-pills" id="v-pills-tab" role="tablist"
                                                                aria-orientation="vertical">
                                                                {
                                                                    filteredList?.map((CompleteInterviewListResult, index) => {
                                                                        return (
                                                                            <>
                                                                                <div key={index} className={`nav-link ${ActiveTab === CompleteInterviewListResult?.id ?
                                                                                    "active" : ""}`}
                                                                                    onClick={() => {
                                                                                        HandleActiveTab(CompleteInterviewListResult?.id)
                                                                                        GetCompletedInterviewListById(CompleteInterviewListResult?.id)
                                                                                    }}
                                                                                    id="v-pills-home-tab" data-bs-toggle="pill"
                                                                                    data-bs-target="v-pills-home" type="button"
                                                                                    role="tab" aria-controls="v-pills-home"
                                                                                    aria-selected="true">
                                                                                    <div className="card_rec position-relative">

                                                                                        <div className="mentersvies0121">
                                                                                            <div className="position-relative">
                                                                                                {
                                                                                                    CompleteInterviewListResult?.student?.profileimg != null ? (
                                                                                                        <>
                                                                                                            <img src={CompleteInterviewListResult?.student?.profileimg}
                                                                                                                className="img-fluid rounded" alt="Instructor" />
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <img src={CommonImageTransport?.userLogo}
                                                                                                                className="img-fluid rounded" alt="Instructor" />
                                                                                                        </>
                                                                                                    )
                                                                                                }

                                                                                                <span className="live_user">Hired</span>
                                                                                            </div>
                                                                                            <div className="instructors-details-contents">
                                                                                                <div className="d-flex justify-content-between gap-2">
                                                                                                    <div className="comnam">
                                                                                                        <h3>{CompleteInterviewListResult?.student?.fname} {CompleteInterviewListResult?.student?.lname}</h3>
                                                                                                        <h6 className="sub-title">{CompleteInterviewListResult?.job?.title}</h6>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <p className="perographsv">{CompleteInterviewListResult?.job?.organization}</p>
                                                                                                <p className="perographsv">{CompleteInterviewListResult?.job?.location}</p>
                                                                                                <p className="perographsv"><strong>Job Id:</strong>{CompleteInterviewListResult?.job?.unique_id}</p>
                                                                                                <div className='d-flex justify-content-between gap-2'>
                                                                                                <div className="skillstopic">
                                                                                                   <div>
                                                                                                   {CompleteInterviewListResult?.job?.skills
                                                                                                       ?.replace(/[{}"\\]/g, '')
                                                                                                       ?.split(/,\s*/)
                                                                                                       ?.map((skill, index, array) => (
                                                                                                           <span key={index}>
                                                                                                               {skill.trim()}
                                                                                                               {index < array.length - 1 && ', '}
                                                                                                           </span>
                                                                                                       ))}
                                                                                                   </div>
                                                                                                 
                                                                                              
                                                                                           </div>
                                                                                           <div>
                                                                                               {/* Trigger Button */}
                                                                                               <div
                                                                                                   className="btn btn-primary "
                                                                                                   onClick={handleShowStudentResume}
                                                                                               >
                                                                                                   View Resume
                                                                                               </div>

                                                                                               {/* Modal Component */}
                                                                                               {CompleteInterviewListResult?.student?.id && (
                                                                                                   <Recuriter_Student_Resume_Modal
                                                                                                       student_userid={CompleteInterviewListResult?.student?.id}
                                                                                                       show={ShowStudentResumeModal}
                                                                                                       onHide={handleCloseStudentResume}
                                                                                                   />
                                                                                               )}
                                                                                           </div>
                                                                                                </div>
                                                                                               
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="tab-content" id="v-pills-tabContent">
                                                            <div className={`tab-pane fade ${ActiveTab ? "show active" : ""}`}
                                                                id={`v-pills-${ActiveTab}`}
                                                                role="tabpanel"
                                                                aria-labelledby={`v-pills-${ActiveTab}-tab`}>
                                                                <div className="slider_desc">
                                                                    <h3>Hiring Process View</h3>
                                                                    <div className="allprocess">
                                                                        <ol className="progress hireprocessview" data-steps="3">
                                                                            <li className="active">
                                                                                <span className="step"><span>1</span></span>
                                                                                <div className="headinghire">
                                                                                    <h5>Technical</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li className="active">
                                                                                <span className="step"><span>2</span></span>
                                                                                <div className="headinghire">
                                                                                    <h5>Behavioral</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li className="active">
                                                                                <span className="step"><span>3</span></span>
                                                                                <div className="headinghire">
                                                                                    <h5>Decision</h5>
                                                                                </div>
                                                                            </li>
                                                                        </ol>

                                                                        <div className="hiring_process">
                                                                            <div>
                                                                                {
                                                                                    TechnicalInterviewsFeedback?.map((TechnicalInterviewsFeedbackResult, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div className="firsthire">
                                                                                                    <div className="card mt-3">
                                                                                                        <div className="card-body p-0">
                                                                                                            <div className="profileinterview p-3 d-flex gap-3">
                                                                                                                <img width="50" className="rounded-pill" height="50"
                                                                                                                    src={CommonImageTransport?.userLogo}
                                                                                                                    onError="src='../assets/images/img_avatar.png'"
                                                                                                                    alt="" />
                                                                                                                <div>Interviewer:-
                                                                                                                    {
                                                                                                                        TechnicalInterviewsFeedbackResult?.interviewers?.map((TechnicalInterviewsFeedbackInterviewers) => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    <h6 className="m-0">{TechnicalInterviewsFeedbackInterviewers?.fname} {TechnicalInterviewsFeedbackInterviewers?.lname}</h6>
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </div>

                                                                                                                <div className="form-check text-success">
                                                                                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                                                        {TechnicalInterviewsFeedbackResult?.is_hire_status}
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="header0101 p-3 py-2">
                                                                                                                <div className="d-flex justify-content-between gap-3">
                                                                                                                    <h6 className="m-0 fw-normal">Recommend</h6>
                                                                                                                    <div className="reviewsreat">
                                                                                                                        <Rating
                                                                                                                            readonly={true}
                                                                                                                            initialValue={TechnicalInterviewsFeedbackResult?.rating}
                                                                                                                            className="custom-star-rating" style={{ fontSize: '5px' }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <Accordion activeKey={openTechnicalAccordions[index] ? '0' : null}>
                                                                                                            <Accordion.Item className='pt-0 view1212' eventKey="0">
                                                                                                                <Accordion.Header onClick={() => technicaltoggleAccordion(index)} className='btn-sm customebtsns'>
                                                                                                                    {openTechnicalAccordions[index] ? 'View Less' : 'View More'}
                                                                                                                </Accordion.Header>
                                                                                                                <Accordion.Body>
                                                                                                                    <h6>Top 5 Skills:-</h6>
                                                                                                                    <ol>
                                                                                                                        {TechnicalInterviewsFeedbackResult?.skills
                                                                                                                            ?.replace(/[{}"\\]/g, '')
                                                                                                                            ?.split(/,\s*/)
                                                                                                                            ?.map((skill, skillIndex, array) => (
                                                                                                                                <li key={skillIndex}>
                                                                                                                                    {skill.trim()}
                                                                                                                                    {skillIndex < array.length - 1 && ', '}
                                                                                                                                </li>
                                                                                                                            ))}
                                                                                                                    </ol>
                                                                                                                    <h6 className="mb-0">Recorded Url</h6>
                                                                                                                    <p className="text-primary">{TechnicalInterviewsFeedbackResult?.recordedlink}</p>
                                                                                                                    <div className="showmorecontent">
                                                                                                                        <div className="block">
                                                                                                                            <div className="content" ref={contentRef}>
                                                                                                                                <h6>Feedback:-</h6>
                                                                                                                                <p className={showMoreTechnicalContent[index] ? '' : 'js-excerpt excerpt-hidden'}>{TechnicalInterviewsFeedbackResult?.feedback}</p>
                                                                                                                            </div>
                                                                                                                            <button role="button" href="#" onClick={() => toggleTechShowMore(index)}>
                                                                                                                                {showMoreTechnicalContent[index] ? 'Show Less' : 'Show More'}
                                                                                                                            </button>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </Accordion.Body>
                                                                                                            </Accordion.Item>
                                                                                                        </Accordion>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>
                                                                            <div>

                                                                                {
                                                                                    BehavioralInterviewsFeedback?.map((TechnicalInterviewsFeedbackResult, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div className="firsthire">
                                                                                                    <div className="card mt-3">
                                                                                                        <div className="card-body p-0">
                                                                                                            <div className="profileinterview p-3 d-flex gap-3">
                                                                                                                <img width="50" className="rounded-pill" height="50"
                                                                                                                    src={CommonImageTransport?.userLogo}
                                                                                                                    onError="src='../assets/images/img_avatar.png'"
                                                                                                                    alt="" />
                                                                                                                <div>Interviewer:-
                                                                                                                    {
                                                                                                                        TechnicalInterviewsFeedbackResult?.interviewers?.map((TechnicalInterviewsFeedbackInterviewers) => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    <h6 className="m-0">{TechnicalInterviewsFeedbackInterviewers?.fname} {TechnicalInterviewsFeedbackInterviewers?.lname}</h6>
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </div>

                                                                                                                <div className="form-check text-success">
                                                                                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                                                        {TechnicalInterviewsFeedbackResult?.is_hire_status}
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="header0101 p-3 py-2">
                                                                                                                <div className="d-flex justify-content-between gap-3">
                                                                                                                    <h6 className="m-0 fw-normal">Recommend </h6>
                                                                                                                    <div className="reviewsreat">
                                                                                                                        <Rating
                                                                                                                            readonly={true}
                                                                                                                            initialValue={TechnicalInterviewsFeedbackResult?.rating}
                                                                                                                            className="custom-star-rating" style={{ fontSize: '5px' }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <Accordion activeKey={openBehavirolAccordions[index + 1] ? '1' : null}>
                                                                                                            <Accordion.Item className='pt-0 view1212' eventKey="1">
                                                                                                                <Accordion.Header onClick={() => BehaviroltoggleAccordion(index + 1)} className='btn-sm customebtsns'>
                                                                                                                    {openBehavirolAccordions[index + 1] ? 'View Less' : 'View More'}
                                                                                                                </Accordion.Header>
                                                                                                                <Accordion.Body>
                                                                                                                    <h6>Top 5 Skills:-</h6>
                                                                                                                    <ol>
                                                                                                                        {TechnicalInterviewsFeedbackResult?.skills
                                                                                                                            ?.replace(/[{}"\\]/g, '')
                                                                                                                            ?.split(/,\s*/)
                                                                                                                            ?.map((skill, skillIndex, array) => (
                                                                                                                                <li key={skillIndex}>
                                                                                                                                    {skill.trim()}
                                                                                                                                    {skillIndex < array.length - 1 && ', '}
                                                                                                                                </li>
                                                                                                                            ))}
                                                                                                                    </ol>
                                                                                                                    <h6 className="mb-0">Recorded Url</h6>
                                                                                                                    <p className="text-primary">{TechnicalInterviewsFeedbackResult?.recordedlink}</p>

                                                                                                                    <div className="showmorecontent">
                                                                                                                        <div className="block">
                                                                                                                            <div className="content" ref={contentRef}>
                                                                                                                                <h6>Feedback:-</h6>
                                                                                                                                <p className={showMoreBehavirolContent[index + 1] ? '' : 'js-excerpt excerpt-hidden'}>{TechnicalInterviewsFeedbackResult?.feedback}</p>
                                                                                                                            </div>
                                                                                                                            <button role="button" href="#" onClick={() => BehaviroltoggleTechShowMore(index + 1)}>
                                                                                                                                {showMoreBehavirolContent[index + 1] ? 'Show Less' : 'Show More'}
                                                                                                                            </button>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </Accordion.Body>
                                                                                                            </Accordion.Item>
                                                                                                        </Accordion>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    DecisionInterviewsFeedback?.map((TechnicalInterviewsFeedbackResult, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div className="firsthire">
                                                                                                    <div className="card mt-3">
                                                                                                        <div className="card-body p-0">
                                                                                                            <div className="profileinterview p-3 d-flex gap-3">

                                                                                                                <img width="50" className="rounded-pill" height="50"
                                                                                                                    src={CommonImageTransport?.userLogo}
                                                                                                                    onError="src='../assets/images/img_avatar.png'"
                                                                                                                    alt="" />
                                                                                                                <div>Interviewer:-
                                                                                                                    {
                                                                                                                        TechnicalInterviewsFeedbackResult?.interviewers?.map((TechnicalInterviewsFeedbackInterviewers) => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    <h6 className="m-0">{TechnicalInterviewsFeedbackInterviewers?.fname} {TechnicalInterviewsFeedbackInterviewers?.lname}</h6>
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </div>

                                                                                                                <div className="form-check text-success">
                                                                                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                                                        {TechnicalInterviewsFeedbackResult?.is_hire_status}
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="header0101 p-3 py-2">
                                                                                                                <div className="d-flex justify-content-between gap-3">
                                                                                                                    <h6 className="m-0 fw-normal">Recommend </h6>
                                                                                                                    <div className="reviewsreat">
                                                                                                                        <Rating
                                                                                                                            readonly={true}
                                                                                                                            initialValue={TechnicalInterviewsFeedbackResult?.rating}
                                                                                                                            className="custom-star-rating" style={{ fontSize: '5px' }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <Accordion>
                                                                                                            <Accordion.Item className='pt-0 view1212' eventKey={String(index)}>
                                                                                                                <Accordion.Header eventKey="0" onClick={() => {
                                                                                                                    setOpenDecisionAccordion(!openDecisionAccordion);
                                                                                                                    setShowMoreDecisionAccordion(!showMoreDecisionAccordion);
                                                                                                                }} className='btn-sm customebtsns'>
                                                                                                                    {showMoreDecisionAccordion ? 'View More' : 'View Less'}
                                                                                                                </Accordion.Header>
                                                                                                                <Accordion.Body>
                                                                                                                    <h6>Top 5 Skills:-</h6>
                                                                                                                    <ol>
                                                                                                                        {TechnicalInterviewsFeedbackResult?.skills
                                                                                                                            ?.replace(/[{}"\\]/g, '')
                                                                                                                            ?.split(/,\s*/)
                                                                                                                            ?.map((skill, skillIndex, array) => (
                                                                                                                                <li key={skillIndex}>
                                                                                                                                    {skill.trim()}
                                                                                                                                    {skillIndex < array.length - 1 && ', '}
                                                                                                                                </li>
                                                                                                                            ))}
                                                                                                                    </ol>
                                                                                                                    <h6 className="mb-0">Recorded Url</h6>
                                                                                                                    <p className="text-primary">{TechnicalInterviewsFeedbackResult?.recordedlink}</p>

                                                                                                                    <div className="showmorecontent">
                                                                                                                        <div className="block">
                                                                                                                            <div className="content" ref={contentRef}>
                                                                                                                                <h6>Feedback:-</h6>
                                                                                                                                <p className={showMore ? '' : 'js-excerpt excerpt-hidden'}>{TechnicalInterviewsFeedbackResult?.feedback}</p>
                                                                                                                            </div>
                                                                                                                            <button role="button" className='btn-sm customebtsns' onClick={toggleShowMore}>
                                                                                                                                {showMore ? 'Show less' : 'Show more'}
                                                                                                                            </button>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </Accordion.Body>
                                                                                                            </Accordion.Item>
                                                                                                        </Accordion>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className='text-center mt-5'>
                                                    <h4>Not any hired profile available here</h4>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            <Recruiter_Footer />
        </div>
    )
}

export default RecruiterList