import React, { useEffect, useState } from "react";
import CommonImageTransport from "../../../common/images/CommonImages";
import Loader from "../../../../loader/Loader";
import Typewriter from "typewriter-effect";
import "react-tagsinput/react-tagsinput.css";
import "../../../../assets/css/style.css";
import "react-step-progress-bar/styles.css";
import Team_Member_Technical_Feedback from "./Team_Member_Technical_Feedback";
import Team_Member_Behavorial_Feedback from "./Team_Member_Behavorial_Feedback";
import Team_Member_Header from "../team_member_header/Team_Member_Header";
import { get_team_Intervie_wWith_Round, get_team_Interview_With_Round_Details } from "../../../../api/Team_Member";
import Team_Member_Footer from "../team_member_footer/Team_Member_Footer";
import Team_Member_Decision_Feedback from "./Team_Member_Decision_Feedback";

const Team_Member_Feedback = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showSearchFields, setShowSearchFields] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [get_team_Interview_With_Round_List, set_get_team_Interview_With_Round_List] = useState([]);
    const [active_tabs, set_active_tabs] = useState(get_team_Interview_With_Round_List[0]?.id)

    const team_role = localStorage.getItem("role_id");
    const token = localStorage.getItem("team_token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const HandleActiveTab = (tabs) => {
        set_active_tabs(tabs);
        localStorage.setItem("get_interview_details_id", tabs);
    };


    const handle_get_team_Interview_With_Round_List = async () => {
        setIsLoading(true)
        try {
            const response = await get_team_Intervie_wWith_Round(headers)
            if (response?.data?.statusCode == "200") {
                setIsLoading(false)
                set_get_team_Interview_With_Round_List(response?.data?.data)
                localStorage.setItem("get_interview_details_id", response?.data?.data[0]?.id);
            }
        }
        catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }
    useEffect(() => {
        handle_get_team_Interview_With_Round_List();
    }, [])

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredList = get_team_Interview_With_Round_List?.filter(
        (item) =>
            item?.studentdetails?.fname?.toLowerCase().includes(searchTerm) ||
            item?.studentdetails?.lname?.toLowerCase().includes(searchTerm) ||
            item?.jobdetails?.title?.toLowerCase().includes(searchTerm) ||
            item?.studentdetails?.email?.toLowerCase().includes(searchTerm) ||
            item?.studentdetails?.location?.toLowerCase().includes(searchTerm) ||
            item?.studentprofile?.p_title?.toLowerCase().includes(searchTerm) ||
            item?.jobdetails?.unique_id?.toLowerCase().includes(searchTerm) ||
            item?.jobdetails?.organization?.toLowerCase().includes(searchTerm)
    );

    // useEffect(() => {
    //     if (get_team_Interview_With_Round_List?.length > 0) {
    //         set_active_tabs(get_team_Interview_With_Round_List[0]?.id);
    //     }
    // }, [get_team_Interview_With_Round_List]);

    useEffect(() => {
        if (get_team_Interview_With_Round_List?.length > 0) {
            if (filteredList.length > 0) {
                set_active_tabs(get_team_Interview_With_Round_List[0]?.id);
            }
        }
    }, [get_team_Interview_With_Round_List]);

    return (
        <div>
            {/*  */}
            <Team_Member_Header />
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="bg_nav-slid bg-white">
                    </div>
                    <div className="recruiter_list pb-5 pt-4">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav tabs_signup justify-content-start gap-2 mb-3">
                                        {/* <li className="nav-item">
                                            <Link
                                                className="nav-link"
                                                aria-current="page"
                                                to="/recruiter_pages/NewProfile"
                                            >
                                                New Profile
                                            </Link>
                                        </li> */}
                                        {/* <li className="nav-item">
                                            <Link
                                                className="nav-link active"
                                                to="#"
                                            >
                                                Interviewing
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link
                                                className="nav-link"
                                                to="/recruiter_pages/RecruiterList"
                                            >
                                                Hired
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link
                                                className="nav-link"
                                                to="/recruiter_pages/Recuriter_Rejected"
                                            >
                                                Rejected
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link
                                                className="nav-link"
                                                to="/recruiter_pages/AllProfile"
                                            >
                                                All
                                            </Link>
                                        </li> */}
                                    </ul>
                                    <>
                                        {
                                            get_team_Interview_With_Round_List?.length > 0 ? (
                                                <>
                                                    <form>
                                                        <div className="searchlocation">
                                                            <div className="iconfield">
                                                                <div className="icon_search">
                                                                    <img src={CommonImageTransport?.searchicon} alt="" />
                                                                </div>

                                                                {!showSearchFields ? (
                                                                    <div onClick={() => setShowSearchFields(true)}>
                                                                        <Typewriter
                                                                            options={{ loop: true, }}
                                                                            onInit={(typewriter) => {
                                                                                typewriter
                                                                                    .typeString("Search by title")
                                                                                    .callFunction(() => { })
                                                                                    .pauseFor(2500)
                                                                                    .deleteAll()
                                                                                    .callFunction(() => { })
                                                                                    .typeString("Search by location")
                                                                                    .pauseFor(2500)
                                                                                    .start();
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <input type="text" placeholder="" value={searchTerm} onChange={handleSearchChange} style={{ border: "none", outline: "none" }} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="iconfield">
                                                                <div className="icon_search">
                                                                    <img src={CommonImageTransport?.locationicon} alt="" />
                                                                </div>
                                                                <div className="input-field">
                                                                    <input type="text" placeholder="USA, Florida" onChange={handleSearchChange} className="form-control" required="" />
                                                                </div>
                                                            </div>
                                                            <div className="input-field ad121">
                                                                <button type="button" onClick={handleSearchChange} className="submit_btn">Search</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="left_recruiterlist">
                                                        <div className="sticky-header">
                                                            <div
                                                                className="nav flex-column recruiter_all_lists nav-pills"
                                                                id="v-pills-tab"
                                                                role="tablist"
                                                                aria-orientation="vertical"
                                                            >
                                                                {filteredList?.map((get_team_Interview_With_Round_List_result) => (
                                                                    <div
                                                                        key={get_team_Interview_With_Round_List_result.id}
                                                                        onClick={() => HandleActiveTab(get_team_Interview_With_Round_List_result?.id)}
                                                                        className={`nav-link ${active_tabs === get_team_Interview_With_Round_List_result?.id ? 'active' : ''}`}
                                                                        id={`v-pills-${get_team_Interview_With_Round_List_result?.id}-tab`}
                                                                        data-bs-toggle="pill"
                                                                        data-bs-target={`#v-pills-${get_team_Interview_With_Round_List_result?.id}`}
                                                                        type="button"
                                                                        role="tab"
                                                                        aria-controls={`v-pills-${get_team_Interview_With_Round_List_result?.id}`}
                                                                        aria-selected={active_tabs === get_team_Interview_With_Round_List_result?.id}
                                                                    >
                                                                        <div className="card_rec position-relative">
                                                                            <div className="mentersvies0121">
                                                                                <div className="position-relative">
                                                                                    {
                                                                                        get_team_Interview_With_Round_List_result?.studentdetails?.profileimg != null ? (
                                                                                            <img
                                                                                                src={get_team_Interview_With_Round_List_result?.studentdetails?.profileimg}
                                                                                                className="img-fluid rounded"
                                                                                                alt="Instructor"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={CommonImageTransport?.userLogo}
                                                                                                className="img-fluid rounded"
                                                                                                alt="Instructor"
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    <span className="live_user bginter">Interviewing</span>
                                                                                </div>
                                                                                <div className="instructors-details-contents">
                                                                                    <div className="d-flex justify-content-between gap-2">
                                                                                        <div className="comnam">
                                                                                            <h3>{get_team_Interview_With_Round_List_result?.studentdetails?.fname} {" "} {get_team_Interview_With_Round_List_result?.studentdetails?.lname}</h3>
                                                                                            <h6 className="sub-title">{get_team_Interview_With_Round_List_result?.studentdetails?.email}</h6>
                                                                                            {/* <h6 className="sub-title">Frontend developer</h6> */}
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <p className="perographsv">
                                                                            Parasme Software & Technology, Jiapur
                                                                        </p> */}
                                                                                    {/* <h6 className="sub-title">React js developer</h6>
                                                                        <p className="perographsv">
                                                                            <strong>Job Id:</strong> 8597TF57&45
                                                                        </p> */}
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="d-flex justify-content-between">
                                                                    <div className="skillstopic">
                                                                        <div>HTML, CSS, JS</div>
                                                                    </div>
                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        <div className="tab-content" id="v-pills-tabContent">
                                                            <div
                                                                className="tab-pane fade show active"
                                                                role="tabpanel">
                                                                <div className="slider_descd">
                                                                    <h3>Hiring Process View</h3>
                                                                    <div className="allprocess">
                                                                        <ol
                                                                            className="progress hireprocessview"
                                                                            data-steps="2"
                                                                        >
                                                                            <li className="done">
                                                                                <span className="step1">
                                                                                    <span>1</span>
                                                                                </span>
                                                                                <div className="headinghire">
                                                                                    <h5>Technical</h5>
                                                                                </div>
                                                                            </li>
                                                                            <li className="done">
                                                                                <span className="step1">
                                                                                    <span>2</span>
                                                                                </span>
                                                                                <div className="headinghire">
                                                                                    <h5>Behavioural</h5>
                                                                                </div>
                                                                            </li>
                                                                            {
                                                                                team_role == "6" && (
                                                                                    <li className="done">
                                                                                        <span className="step1">
                                                                                            <span>3</span>
                                                                                        </span>
                                                                                        <div className="headinghire">
                                                                                            <h5>Decision</h5>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        </ol>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                team_role != "6" && (
                                                                    <div className="hiring_process new_hiring">
                                                                        {/*<----------  Technical Interview Sections ------------> */}
                                                                        <Team_Member_Technical_Feedback get_team_Interview_With_Round_Details={
                                                                            get_team_Interview_With_Round_Details
                                                                        } />
                                                                        {/*<----------  Technical Interview Sections ------------> */}
                                                                        <Team_Member_Behavorial_Feedback  get_team_Interview_With_Round_Details={
                                                                            get_team_Interview_With_Round_Details
                                                                        } />
                                                                    </div>

                                                                )
                                                            }
                                                            {
                                                                team_role == "6" && (
                                                                    <div className="hiring_process">
                                                                        {/*<----------  Technical Interview Sections ------------> */}
                                                                        <Team_Member_Technical_Feedback get_team_Interview_With_Round_Details={
                                                                            get_team_Interview_With_Round_Details
                                                                        } />
                                                                        {/*<----------  Technical Interview Sections ------------> */}
                                                                        <Team_Member_Behavorial_Feedback get_team_Interview_With_Round_Details={
                                                                            get_team_Interview_With_Round_Details
                                                                        } />
                                                                        <Team_Member_Decision_Feedback 
                                                                            get_team_Interview_With_Round_Details={
                                                                                get_team_Interview_With_Round_Details
                                                                            } />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="m-auto text-center">
                                                    <h3>Not any interview list here</h3>
                                                </div>
                                            )
                                        }
                                    </>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <Team_Member_Footer />
        </div>
    );
};

export default Team_Member_Feedback;
