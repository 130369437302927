import React, { useState } from 'react'
import Team_Member_Header from '../team_member_header/Team_Member_Header'
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Team_Password_Update } from '../../../../api/Auth';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import Team_Member_Common_Profile from '../team_member_common_profile/Team_Member_Common_Profile';

const Team_Member_Reset_Password = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confpassword, setConfPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
    const [old_password, setOld_Password] = useState('');

    // <----------- Check the password validation's ---------------->
    const [isValidPassword, setIsValidPassword] = useState(true);

    // <---------- User Authenication token's ------------->
    const token = localStorage.getItem("team_token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };

    // <--------- Apply The regex for the vaild Password section's ------------->
    const ValidatePassword = (password) => {
        const regex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{11,}$/;
        setIsValidPassword(regex.test(password));
    };

    // <---------- Onchange events on the password section's ---------------->
    const handlePasswordChange = (event) => {
        const { value } = event.target;
        setPassword(value);
        ValidatePassword(value);
    };


    // <----------- Change Password section's ---------------->
    const Change_Password = async () => {
        const data = {
            old_password,
            password: password
        };

        if (!password) {
            toast.error("please fill the password to processed !!!")
            return;
        }
        if (!confpassword) {
            toast.error("please fill the confirm password to processed !!!")
            return;
        }
        if (password !== confpassword) {
            toast.error("Password do not match !!!")
            return;
        }
        try {
            const response = await Team_Password_Update(data, headers);
            if (response?.data?.statusCode == "200") {
                toast.success("Password Change Successfully !!!")
                navigate("/team_member_feedback")
            }
            else if (response?.data?.statusCode == "400") {
                toast.error(response?.data?.msg)
                console.log("Change_Password", response)
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div>
            {/*  */}
            <Team_Member_Header />
            <ToastContainer style={{ marginTop: "100px" }} />
            {/*  */}

            <div className="client_filter-job py-5 pt-2">
                <div className="stepsForm">
                    <form method="post" id="msform">
                        <div className="container">
                            <div className="row">
                                <div className='col-md-4'>
                                    {/* <--------- Recruiter Common Profile ----------> */}
                                    <Team_Member_Common_Profile />
                                </div>
                                <div class="col-md-8 d-flex">
                                    <div class="flexdr position-relative p-3 p-md-4 shadow-lg">
                                        <div class="sf-steps-form personal_user_info">
                                            <h1 class="modal-title fs-5" id="exampleModalLabel">Change Password</h1>
                                            <div class="row">
                                                <div class="col-lg-12 mt-3">
                                                    <label>New Password</label>
                                                    <div class="form-floating form-group">
                                                        <input type={showConfirmPassword ? "text" : "password"} name='password' value={password}
                                                            onChange={handlePasswordChange}
                                                            class="form-control"
                                                            placeholder="New Password" />
                                                        <span onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                            class="password-toggle eye">
                                                            {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 mt-3">
                                                    <label>Confirm New Password</label>
                                                    <div class="form-floating form-group">
                                                        <input type={showConfirmNewPassword ? "text" : "password"} name='confpassword' value={confpassword}
                                                            onChange={(e) => setConfPassword(e.target.value)}
                                                            class="form-control"
                                                            placeholder="Confirm New Password" />
                                                        <span onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                                            class="password-toggle eye">
                                                            {showConfirmNewPassword ? <FaEye /> : <FaEyeSlash />}
                                                        </span>
                                                    </div>
                                                </div>
                                                {!isValidPassword && (
                                                    <p style={{ color: "red" }}>
                                                        Password must be at least 11 characters long,
                                                        contain at least one lowercase letter, one
                                                        uppercase letter, one digit, and one special
                                                        character (@$!%*?&).
                                                    </p>
                                                )}
                                                <div class="col-lg-12 col-md-12 mt-3">
                                                    <button type="button" onClick={() => Change_Password()}
                                                        class="default-btn rounded">
                                                        Change Password
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Team_Member_Reset_Password