
import '../../assets/css/iconplugins.css';
import '../../assets/css/style.css';
import '../../assets/css/responsive.css';
import '../../assets/images/favicon.png';
import React, { useState } from 'react';
import Header from '../../components/common/header/Header';
import { Link, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { OtpVerify, Register, SendOtp } from '../../api/Auth';
import CommonImageTransport from '../../components/common/images/CommonImages';
import { ToastContainer, toast } from 'react-toastify';

const StudentSignUpOtpVerify = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');

    const Student_Register_Email = localStorage.getItem("Student_Register_Email")
    const Student_Register_Data = JSON.parse(localStorage.getItem("Student_Register_Data"));
    // const Student_Register_send_Otp_Data = JSON.parse(localStorage.getItem("Mentor_Register_send_Otp_Data"));

    const [otpError, setOtpError] = useState(false)
    // <----------- This is Otp Verify Student SignUp----------------->
    const OtpVerifyStuSignUp = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        if (otp.length !== 6) {
            setIsLoading(false)
            toast.error("Please enter a valid 6-digit OTP.");
            return;
        }
        try {
            const SendOtpVerifyData = {
                type: "signup",
                email: Student_Register_Email,
                otp
            };

            const response = await OtpVerify(SendOtpVerifyData);

            if (response.data.data.isOtpMatch === true) {
                // setIsLoading(false)
                const ResponseStudent = await Register(Student_Register_Data);
                if (ResponseStudent.data.statusCode == "400") {
                    setIsLoading(false);
                    setTimeout(() => {
                        toast.error(ResponseStudent?.data?.msg);
                    }, 1000)
                    setIsLoading(false)
                    setTimeout(() => {
                        navigate("/Student_SignUp");
                    }, 2000);
                } else if (ResponseStudent.data.statusCode == "200") {
                    setIsLoading(false)
                    // localStorage.setItem("Is_LoggedIn", true);
                    localStorage.setItem("User_Token", ResponseStudent?.data?.token);
                    localStorage.setItem("User_id", ResponseStudent?.data?.record?.id);
                    // localStorage.setItem("role_id", ResponseStudent?.data?.record?.role_id);
                    toast.success("Otp Verify Successfully and Student Registration Successfully !!!");
                    setIsLoading(false)
                    navigate("/Student_step");
                    localStorage.removeItem("Student_Register_Email");
                }
            } else if (response.data.statusCode == '400') {
                setIsLoading(false)
                setTimeout(() => {
                    toast.error(response?.data?.msg);
                }, 1000)
                // alert("Invalid otp !!!")
            } else {
                toast.success("Otp Verify Successfully !!!");
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(true)
            console.error("Error:", error);
        }
    };

    // <----------- Resend Otp ------------>
    const ResendOTP = async () => {
        const ResndOtpData = {
            type: "signup",             //login,signup,resetpassword
            email: Student_Register_Email
        }
        try {
            const response = await SendOtp(ResndOtpData)
            console.log("ResendOTP", response)
            if (response?.data?.statusCode == "200") {
                toast.success("Otp send on your email, please check.")
            }
        } catch (error) {
            console.log("error", error)
        }
    }
    return (
        <div>
            <div className="login-signup-bg">
                <Header />
                <div className="user-area p-5">
                    {
                        isLoading ? (
                            <>
                                <div className="chat-window text-center">
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        bottom: 0,
                                        margin: "auto",
                                    }} className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            </>

                        ) : (
                            <>
                                <div className="container">
                                    <ToastContainer style={{ marginTop: "120px" }} />
                                    <div className="row">
                                        <div className="col-lg-6 p-0">
                                            <div className="user_login">
                                                <img src={CommonImageTransport.loginbg}
                                                    className="imgk-fluid w-100" alt="login-banner" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 d-flex p-0">
                                            <div className="user-all-form userlogin position-relative">
                                                <div className="login_bottom">
                                                    <img src={CommonImageTransport.loginBottom} alt="" />
                                                </div>
                                                <div className="contact-form">

                                                    <h3 className="user-title">Enter OTP</h3>
                                                    <p>Code Just Sent to <span className="text-info fw-bold">{Student_Register_Email}</span></p>
                                                    <form id="contactForms" className="mt-5" onSubmit={OtpVerifyStuSignUp}>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="form-floating form-group">
                                                                    <div className='otp-input-fields'>
                                                                        <OtpInput className="otp__digit otp__field__1"
                                                                            value={otp}
                                                                            onChange={(newOtp) => {
                                                                                setOtp(newOtp)
                                                                                if (newOtp.length === 6) {
                                                                                    setOtpError(false);
                                                                                }
                                                                            }}
                                                                            numInputs={6}

                                                                            renderInput={(props) =>
                                                                                <input   {...props} />}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/* <div class="form-floating form-group">
                                            <div class="otp-input-fields">
                                                <input type="number" class="otp__digit otp__field__1"/>
                                                <input type="number" class="otp__digit otp__field__2"/>
                                                <input type="number" class="otp__digit otp__field__3"/>
                                                <input type="number" class="otp__digit otp__field__4"/>
                                                <input type="number" class="otp__digit otp__field__5"/>
                                                <input type="number" class="otp__digit otp__field__6"/>
                                              </div>
                                        </div> */}
                                                            </div>

                                                            <div className="col-lg-12 col-md-12">
                                                                <button type="submit"
                                                                    className="default-btn w-100 rounded" >
                                                                    Verify
                                                                </button>

                                                                <h6 className="mt-4 text-center fw-normal text-muted">Didn't get the otp?
                                                                    <Link className="fw-bold" to="#" onClick={ResendOTP}>Resend</Link></h6>
                                                            </div>
                                                        </div>
                                                    </form>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default StudentSignUpOtpVerify