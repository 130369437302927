import React, { useState, useEffect } from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import { GetImgSlidersList } from '../../../../../api/Global';

const HomeTopSlider = () => {
      const [sliderList, setSliderList] = useState([])

      const HandleGetImgSlider = async () => {
            try {
                  const response = await GetImgSlidersList();
                  setSliderList(response?.data?.data)
            } catch (error) {
                  console.log("error", sliderList)
            }
      }
      console.log("sliderList", sliderList)
      useEffect(() => {
            HandleGetImgSlider()
      }, [])

      return (
            <div className="hero-slider-area py-5 position-relative">
                  <div className="univery_add">
                  </div>
                  <Carousel>
                        {sliderList?.map((sliderListResult, index) => (
                              <Carousel.Item key={index}>
                                    <div className="hero-item">
                                          <div className="container-fluid">
                                                <div className="row">
                                                      <div className="col-lg-12 col-xl-12 mx-auto text-center">
                                                            <div className="hero-content">
                                                                  <h2 className='fw-normal text-white'><span className='fw-bold'>Get Ahead of the Game:</span> Enroll in <span className='fw-bold'>FREE Job-Oriented Hands-On Training Programs</span> Now</h2>
                                                                  <h2 className='fw-normal text-white'>Stand Out by Graduation and <span className='fw-bold'>Land Your Dream Job</span> as Fresh Grad!</h2>
                                                                  {/* <h1>{sliderListResult?.title}</h1>
                                                                  <p>{sliderListResult?.desciption}</p> */}
                                                                  <div className="banner-btn">
                                                                        {/* Your buttons or additional content */}
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </Carousel.Item>

                        ))}
                  </Carousel>

            </div>
      )
}

export default HomeTopSlider