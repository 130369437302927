import React, { useState, useEffect } from 'react'
import CommonImageTransport from '../../../common/images/CommonImages';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Dropdown } from 'react-bootstrap';
import { ProfileSections } from '../../../../api/Auth';
import { GetSetings } from '../../../../api/Public';

const Recruiter_Header = () => {

  // <---- Show the Notification section's -------->
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // <----------- Get the User Profile section's -------->
  const [GetUserProfile, SetGetUserProfile] = useState([])
  const [SettingList, setSettingList] = useState({});


  const user_id = localStorage.getItem("User_id")
  const isLoggedIn = localStorage.getItem("Is_LoggedIn");

  // <-------- User Authetication Token's ------------->
  const token = localStorage.getItem("User_Token");
  const headers = {
    "Authorization": `Bearer ${token}`
  }

  // <---------- get the User Profile section's ----------->
  const ProfileSections1 = async () => {
    const response = await ProfileSections({ user_id, headers })
    if (response?.data?.statusCode == 401) {
      localStorage.clear();
      navigate("/Signin")
      window.location.reload()
    }
    SetGetUserProfile(response?.data?.data?.profile)
  }
  console.log("GetUserProfile", GetUserProfile)
  useEffect(() => {
    ProfileSections1();
  }, [])

  // <----------- User Logout Fucntionlity ----------->
  const LogoutUser = () => {
    const User_Token = localStorage.getItem("User_Token");
    if (User_Token) {
      const confirmLogout = window.confirm("Are you sure you want to log out?");
      if (confirmLogout) {
        localStorage.removeItem("User_Token");
        localStorage.removeItem("Is_LoggedIn");
        localStorage.removeItem("user_Login_Data");
        localStorage.removeItem("user_Email");
        localStorage.removeItem("User_id");
        localStorage.removeItem("rememberedUser");
        localStorage.removeItem("role_id");
      }

    }
    window.location.reload();
    navigate('/signin');
  }

  const HandleGetSettingList = async () => {
    const response = await GetSetings();
    setSettingList(response?.data?.data);
  };

  useEffect(() => {
    HandleGetSettingList();
  }, []);
  return (
    <div>

      <Navbar expand="lg" className="navbar-area ledu-area otherpages-menu">
        <div className='container-fluid'>
          <Navbar.Brand as={Link} to="/recruiter_pages/RecruiterDashboard">
            {
              SettingList?.headerlogo != null ? (
                <>
                  <img src={SettingList?.headerlogo} height={50} className="logo-one rounded-pill" alt="logo" />
                </>

              ) : (
                <>
                  <img src={CommonImageTransport?.logoFull} height={50} className="logo-one rounded-pill" alt="logo" />

                </>
              )
            }
          </Navbar.Brand>

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="w-100 ms-3">
              <ul className="navbar-nav w-100 ms-3">
                <li className="nav-item">
                  <Link to="/recruiter_pages/RecruiterDashboard"
                    className={location.pathname === '/recruiter_pages/RecruiterDashboard' ? 'active nav-link' : 'nav-link'}>
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/recruiter_pages/RecuriterMyTeam"
                    className={location.pathname === '/recruiter_pages/RecuriterMyTeam' ? 'nav-link active' : 'nav-link'}>
                    My Team
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/recruiter_pages/NewProfile"
                    className={location.pathname === '/recruiter_pages/NewProfile' ? 'active nav-link' : 'nav-link'}>
                    Candidate Profiles
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/recruiter_pages/RecruiterJobs"
                    className={location.pathname === '/recruiter_pages/RecruiterJobs' ? 'active nav-link' : 'nav-link'}>
                    My Jobs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/recruiter_pages/Interview_Guide"
                    className={location.pathname === '/recruiter_pages/Interview_Guide' ? 'active nav-link' : 'nav-link'}>
                    Interview Guide
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/recruiter_pages/Recuriter_Support"
                    className={location.pathname === '/recruiter_pages/Recuriter_Support' ? 'active nav-link' : 'nav-link'}>
                    Support
                  </Link>
                </li>
              </ul>
              <div className='"others-options gap-2 d-flex align-items-center"'>
                <div className="optional-item">
                  <Link to="/recruiter_pages/Recruiter_JobPosts"
                    className="default-btn two p-2 px-3 rounded-pill">Job Post</Link>
                </div>
                <div className="optional-item">
                  {/* <button className="btn btn-primary rounded-pill" onClick={()=>setShow(true)} type="button"
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"><i className="ri-notification-2-line">

                    </i></button> */}
                  {/* <---------- Notification sections here --------------> */}
                  {/* <Offcanvas className="offcanvas offcanvas-end"
                    tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" show={show} onHide={()=>setShow(false)} placement="end">
                    <div className="offcanvas-header">
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">Notification list</h5>
                      <button type="button" className="btn-close" onClick={() => setShow(false)} data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body p-0">
                      <div className="right boxshhd">
                        <div className="box shadow-sm rounded bg-white mb-3">
                          <div className="box-title border-bottom p-3">
                            <h6 className="m-0">Recent</h6>
                          </div>
                          <div className="box-body p-0">
                            <div className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle"
                                  src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" />
                              </div>
                              <div className="font-weight-bold mr-3">
                                <div className="text-truncate">DAILY RUNDOWN: WEDNESDAY</div>
                                <div className="small">Income tax sops on the cards, The bias in VC funding, and other top news for you</div>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button">
                                      <i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button">
                                      <BsThreeDotsVertical /> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">3d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center osahan-post-header">
                              <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="" />
                              </div>
                              <div className="font-weight-bold mr-3">
                                <div className="mb-2">We found a job at askbootstrap Ltd that you may be interested in Vivamus imperdiet venenatis est...</div>
                                <button type="button" className="btn btn-outline-success btn-sm">View Jobs</button>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">4d</div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="box shadow-sm rounded bg-white mb-3">
                          <div className="box-title border-bottom p-3">
                            <h6 className="m-0">Earlier</h6>
                          </div>
                          <div className="box-body p-0">
                            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3 d-flex align-items-center bg-danger justify-content-center rounded-circle text-white">DRM</div>
                              <div className="font-weight-bold mr-3">
                                <div className="text-truncate">DAILY RUNDOWN: MONDAY</div>
                                <div className="small">Nunc purus metus, aliquam vitae venenatis sit amet, porta non est.</div>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right" >
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">3d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3"><img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" /></div>
                              <div className="font-weight-bold mr-3">
                                <div className="text-truncate">DAILY RUNDOWN: SATURDAY</div>
                                <div className="small">Pellentesque semper ex diam, at tristique ipsum varius sed. Pellentesque non metus ullamcorper</div>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">3d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="" />
                              </div>
                              <div className="font-weight-bold mr-3">
                                <div className="mb-2"><span className="font-weight-normal">Congratulate Gurdeep Singh Osahan (iamgurdeeposahan)</span> for 5 years at Askbootsrap Pvt.</div>
                                <button type="button" className="btn btn-outline-success btn-sm">Say congrats</button>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">4d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar4.png" alt="" />
                              </div>
                              <div className="font-weight-bold mr-3">
                                <div>
                                  <span className="font-weight-normal">Congratulate Mnadeep singh (iamgurdeeposahan)</span> for 4 years at Askbootsrap Pvt.
                                  <div className="small text-success"><i className="fa fa-check-circle"></i> You sent Mandeep a message</div>
                                </div>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">4d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3 d-flex align-items-center bg-success justify-content-center rounded-circle text-white">M</div>
                              <div className="font-weight-bold mr-3">
                                <div className="text-truncate">DAILY RUNDOWN: MONDAY</div>
                                <div className="small">Nunc purus metus, aliquam vitae venenatis sit amet, porta non est.</div>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">3d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3"><img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" /></div>
                              <div className="font-weight-bold mr-3">
                                <div className="text-truncate">DAILY RUNDOWN: SATURDAY</div>
                                <div className="small">Pellentesque semper ex diam, at tristique ipsum varius sed. Pellentesque non metus ullamcorper</div>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">3d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="" />
                              </div>
                              <div className="font-weight-bold mr-3">
                                <div className="mb-2"><span className="font-weight-normal">Congratulate Gurdeep Singh Osahan (iamgurdeeposahan)</span> for 5 years at Askbootsrap Pvt.</div>
                                <button type="button" className="btn btn-outline-success btn-sm">Say congrats</button>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">4d</div>
                              </span>
                            </div>
                            <div className="p-3 d-flex align-items-center osahan-post-header">
                              <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="" />
                              </div>
                              <div className="font-weight-bold mr-3">
                                <div>
                                  <span className="font-weight-normal">Congratulate Mnadeep singh (iamgurdeeposahan)</span> for 4 years at Askbootsrap Pvt.
                                  <div className="small text-success"><i className="fa fa-check-circle"></i> You sent Mandeep a message</div>
                                </div>
                              </div>
                              <span className="ml-auto mb-auto">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <BsThreeDotsVertical />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                    <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                  </div>
                                </div>
                                <br />
                                <div className="text-right text-muted pt-1">4d</div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Offcanvas> */}


                </div>
              </div>


            </Nav>

          </Navbar.Collapse>
          <Nav>
            {isLoggedIn ? (
              <Dropdown className="others-options">
                <Dropdown.Toggle variant="text-white">
                  {
                    GetUserProfile?.profileimg != null ?
                      <>
                        <img src={GetUserProfile?.profileimg} height={50} width={50}
                          className="rounded-pill" alt="Profile" />
                      </> :
                      <>
                        <img src={CommonImageTransport?.userLogo} height={50} width={50}
                          className="rounded-pill" alt="Profile" />
                      </>
                  }

                  {`${GetUserProfile?.fname || ""}  ${GetUserProfile?.lname || ""}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Link to="/recruiter_pages/recruiter-Profile"
                    className='dropdown-item'><i className="flaticon-user" />Profile</Link>
                  <li className="list-group-item"
                    onClick={LogoutUser}><Link to="#" className="logoutbtn2">
                      <i className="ri-logout-box-fill" />
                      LOGOUT</Link></li>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <div className="others-options login__signup gap-2 d-flex align-items-center">
                <div className="optional-item">
                  <Link to="/Signin" className={location.pathname === '/Signin' ? 'active default-btn two rounded-pill' : 'rounded-pill'}>Sign In</Link>
                </div>
                <div className="optional-item">
                  <Link to="/Signup" className={location.pathname === '/Signup' ? 'active default-btn two rounded-pill' : 'rounded-pill'}>Sign up</Link>
                </div>
              </div>
            )}
          </Nav>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </div>
      </Navbar>
      <div className="header_space"></div>
    </div>
  )
}

export default Recruiter_Header

