import AllRoutes from './allroutes/AllRoutes';
import AutoLogout from './autologout/AutoLogout';

function App() {
  return (
    <div className="App">
          <AutoLogout timeout={120 * 60 * 1000}/>
    <AllRoutes />
    </div>
  );
}

export default App;




