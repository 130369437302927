import React, { useState, useEffect } from 'react'
import Student_Header from '../student_header/Student_Header'
import CommonImageTransport from '../../../common/images/CommonImages'
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import CanvasJSReact from '@canvasjs/react-charts';
import 'react-datepicker/dist/react-datepicker.css';
import { StudentDashboard } from '../../../../api/Student';
import Loader from '../../../../loader/Loader';
import Student_Footer from '../student_footer/Student_Footer';
import { useNavigate } from 'react-router-dom';
const { CanvasJSChart } = CanvasJSReact;

const Student_dashBoard = () => {
    const navigate = useNavigate()
    const currentDate = new Date();
    const [is_Loading, setIs_Loading] = useState(false)
    const [selectedYear, setSelectedYear] = useState(null);
    const [studentDashBoardList, setStudentDashBoardList] = useState([]);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    // <--------- User Authentication token's  ---------->
    const token = localStorage.getItem("User_Token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };

    // <--------- Select the Year ----------->
    const handleYearChange = (date) => {
        const year = date.getFullYear();
        setSelectedYear(year);
        GetStudentDashBoard(year)
    };

    const GetStudentDashBoard = async (year) => {
        setIs_Loading(true)
        const studentDashBoardData = {
            year
        }
        try {
            const response = await StudentDashboard(studentDashBoardData, headers)
            setStudentDashBoardList(response?.data?.data)
            setIs_Loading(false)
           if(response?.data?.statusCode == 401){
            localStorage.clear();
            navigate("/Signin")
            window.location.reload()
           }
        } catch (error) {
            console.log("error", error)
            setIs_Loading(false)
        }
    }
    // <-------- Complete Sessions -------------->

    const MentorBookingPoints = months?.map((month, index) => ({
        label: month,
        y: studentDashBoardList?.mentor_booking_graph && studentDashBoardList?.mentor_booking_graph[index] !== null ? studentDashBoardList?.mentor_booking_graph[index] : 0
    }));

    const JobsApplyPoints = months?.map((month, index) => ({
        label: month,
        y: studentDashBoardList?.job_apply_graph && studentDashBoardList?.job_apply_graph[index] !== null ? studentDashBoardList?.job_apply_graph[index] : 0
    }));
    // const ClassGraphData = months?.map((month, index) => ({
    //     label: month,
    //     y: studentDashBoardList?.class_graph_data && studentDashBoardList?.class_graph_data[index] !== null ? studentDashBoardList?.class_graph_data[index] : 0
    // }));
    const ActiveCourseClassesPoints = months?.map((month, index) => ({
        label: month,
        y: studentDashBoardList?.active_course_classes && studentDashBoardList?.active_course_classes[index] !== null ? studentDashBoardList?.active_course_classes[index] : 0
    }));

    // labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', "Dec"],
    const MentorBookingPointsData = {
        data: [
            {
                type: "column",
                dataPoints: MentorBookingPoints,
                showInLegend: true,
                color: "#0a405d",
                name: "Mentor Booking",
                barThickness: 200
            }
        ],
    };

    const JobsApplyPointsData = {
        data: [
            {
                type: "column",
                dataPoints: JobsApplyPoints,
                showInLegend: true,
                color: "#03b2cb",
                name: "Applied Jobs",
                barThickness: 200
            }
        ],
    };

    const TotalActivePointsData = {
        data: [{
            type: "pie",
            startAngle: 75,
            toolTipContent: "<b>{label}</b>: {y}%",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            indexLabel: "{label} - {y}%",
            dataPoints: studentDashBoardList?.class_graph_data
        }]
    };

    const ActiveCourseClassesPointsData = {
        data: [
            {
                type: "splineArea",
                dataPoints: ActiveCourseClassesPoints,
                showInLegend: true,
                color: "#047edf",
                barThickness: 200
            }
        ],
    }


    useEffect(() => {
        GetStudentDashBoard();
    }, [])

    return (
        <div>
            {
                is_Loading ? (
                    <>
                        <Loader />
                    </>

                ) : (
                    <>
                        <div className="bg_nav-slid bg-white">
                            <Student_Header />
                        </div>
                        <div className="header_space"></div>
                        <div className="enrolled-area-two py-5 pt-3 bg-light">
                            <div className="container-fluid">
                                <div>
                                    <DatePicker className='form-control'
                                        // selected={selectedYear}
                                        onChange={handleYearChange}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        placeholderText="Select Year"
                                        maxDate={currentDate}
                                    />
                                    {selectedYear && <p>Selected Year: {selectedYear}</p>}
                                </div>
                                <div className="row">
                                    <div className="Dashboard1 col-md-12">
                                        <h3>Dashboard</h3>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                        <div className="mb-5">
                                            <div className="card btn-gradient-121 widget-flat">
                                                <div className="bg-layerbg"><img src={CommonImageTransport.circle} alt="" /></div>
                                                <div className="card-body">
                                                    <div className="float-end">
                                                        <i className="mdi ri-profile-line widget-icon"></i>
                                                    </div>
                                                    <h3 className="text-muted fw-normal mt-0" title="Number of Customers">Mentor Booking </h3>
                                                    <h3 className="mt-2 mb-0">{studentDashBoardList?.mentorBookingCount}</h3>
                                                </div>
                                                <p className="px-3 py-2 mb-0 text-muted border-top">
                                                    <span className="text-success me-2"><i className="ri-upload-cloud-line"></i>{studentDashBoardList?.mentorBookingCount}</span>
                                                    <span className="text-nowrap">Since</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mb-5 mt-5">
                                            <div className="card widget-flat btn-gradient-success">
                                                <div className="bg-layerbg"><img src={CommonImageTransport.circle} alt="" /></div>
                                                <div className="card-body">
                                                    <div className="float-end">
                                                        <i className="mdi widget-icon bg-success-lighten ri-eye-line"></i>
                                                    </div>
                                                    <h3 className="text-muted fw-normal mt-0" title="Number of Orders">Applied Jobs</h3>
                                                    <h3 className="mt-2 mb-0">{studentDashBoardList?.appliedJobCount}</h3>
                                                </div>
                                                <p className="px-3 py-2 mb-0 text-muted border-top">
                                                    <span className="text-success me-2"><i className="ri-upload-cloud-line"></i> {studentDashBoardList?.appliedJobCount}</span>
                                                    <span className="text-nowrap">Since</span>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="mb-5 mt-5">
                                            <div className="card widget-flat btn-gradient-danger">
                                                <div className="bg-layerbg"><img src={CommonImageTransport.circle} alt="" /></div>
                                                <div className="card-body">
                                                    <div className="float-end">
                                                        <i className="mdi widget-icon bg-success-lighten ri-eye-line"></i>
                                                    </div>
                                                    <h3 className="text-muted fw-normal mt-0" title="Average Revenue">Active Training Programs</h3>
                                                    <h3 className="mt-2 mb-0">{studentDashBoardList?.activeCourseClassCount}</h3>
                                                </div>
                                                <p className="px-3 py-2 mb-0 text-muted border-top">
                                                    <span className="text-success me-2"><i className="ri-upload-cloud-line"></i> {studentDashBoardList?.activeCourseClassCount}</span>
                                                    <span className="text-nowrap">Since</span>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="mb-5 mt-5">
                                            <div className="card widget-flat btn-gradient-info">
                                                <div className="bg-layerbg"><img src={CommonImageTransport.circle} alt="" /></div>
                                                <div className="card-body">
                                                    <div className="float-end">
                                                        <i className="mdi widget-icon ri-user-line"></i>
                                                    </div>
                                                    <h3 className="text-muted fw-normal mt-0" title="Growth">Completed Training Programs
                                                    </h3>
                                                    <h3 className="mt-2 mb-0">{studentDashBoardList?.completeTrainingProgramCount}</h3>
                                                </div>
                                                <p className="px-3 py-2 mb-0 text-muted border-top">
                                                    <span className="text-success me-2"><i className="ri-upload-cloud-line"></i> {studentDashBoardList?.completeTrainingProgramCount}</span>
                                                    <span className="text-nowrap">Since</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-9 mb-5">
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <div className="card widget-flat">
                                                    <h4 className="graphtitles">Mentor Booking</h4>
                                                    <div className="charthhe" id="chartContainer"
                                                        style={{ width: '100%', height: '400px' }}>
                                                        <CanvasJSChart options={MentorBookingPointsData} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className="card widget-flat">
                                                    <h4 className="graphtitles">Applied Jobs</h4>
                                                    <div className="charthhe" id="chartContainer2"
                                                        style={{ width: '100%', height: '400px' }}>
                                                        <CanvasJSChart options={JobsApplyPointsData} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className="card widget-flat">
                                                    <h4 className="graphtitles">View Courses</h4>
                                                    <div className="charthhe" id="chartContainer3"
                                                        style={{ width: '100%', height: '400px' }}>
                                                        <CanvasJSChart options={TotalActivePointsData} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className="card widget-flat">
                                                    <h4 className="graphtitles">Active Training Program</h4>
                                                    <div className="charthhe" id="chartContainer4"
                                                        style={{ width: '100%', height: '400px' }}>
                                                        <CanvasJSChart options={ActiveCourseClassesPointsData} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            <Student_Footer />
        </div>
    )
}

export default Student_dashBoard