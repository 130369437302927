import React, { useState, useEffect } from 'react'
import CommonImageTransport from '../../../../components/common/images/CommonImages';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Team_Profile } from '../../../../api/Team_Member';

const Team_Member_Common_Profile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [GetUserProfile, SetGetUserProfile] = useState([]);
    const team_id = localStorage.getItem("team_id");

    const token = localStorage.getItem("team_token");
    const headers = {
        "Authorization": `Bearer ${token}`
    }
    // <------------- Get User Profile Sections ---------------->
    const GetuserProfileSection = async () => {
        const data = {
            id: team_id
        }
        const response = await Team_Profile(data, headers);
        SetGetUserProfile(response?.data?.data?.profile);
    }
    // <------------- Render The User Profile Sections ---------------->
    useEffect(() => {
        GetuserProfileSection();
    }, [])
    const LogoutUser = () => {
        const User_Token = localStorage.getItem("team_token");
        if (User_Token) {
            const confirmLogout = window.confirm("Are you sure you want to log out?");
            if (confirmLogout) {
                localStorage.clear();
            }

        }
        navigate('/Signin');
        window.location.reload();
    }
    return (
        <div>
            <div>
                <div>
                    <div className="sf-steps profileview border shadow-lg">
                        <div className="profile_avtar pt-3 pt-md-5">
                            {
                                GetUserProfile?.profile_img != null ?
                                    <>
                                        <img src={GetUserProfile?.profile_img} height={100} width={100}
                                            className="rounded-pill" alt="Profile" />
                                    </> :
                                    <>
                                        <img src={CommonImageTransport.userLogo} height={100} width={100}
                                            className="rounded-pill" alt="Profile" />
                                    </>
                            }
                            <span />
                        </div>
                        <h5 className="mt-3 mb-1">{GetUserProfile?.fname} {GetUserProfile?.lname}</h5>
                        <p>({GetUserProfile?.member_role})</p>
                        <ul className="list-group profilemenu">
                            <li className="list-group-item" >
                                <Link to="/team_member_profile" className={location.pathname === '/Profile' ? 'active' : ''}>
                                    <i className="ri-shield-user-fill" /> My Profile</Link></li>

                            <li className="list-group-item"><Link className={location.pathname === '/ProfileChangePassword' ? 'active' : ''}
                                to="/team_member_reset_password">
                                <i className="ri-lock-password-fill" />
                                Reset Password</Link>
                            </li>

                            <li className="list-group-item" onClick={LogoutUser}><Link to="#" className="logoutbtn2"> <i className="ri-logout-box-fill" />
                                LOGOUT</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team_Member_Common_Profile