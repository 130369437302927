import React, { useEffect, useState } from 'react'
import Header from '../../../common/header/Header'
import Footer from '../../../common/footer/Footer'
import { TermsCondtion } from '../../../../api/Global';
import ReactHtmlParser from "react-html-parser";
import Loader from '../../../../loader/Loader';

const TermsCondition1 = () => {
    // <------------ P & P ----------------->
    const [is_Loading, set_Is_Loading] = useState(false)
    const [PrivacyPolicyList, setPrivacyPolicyList] = useState({});
    //   const [TermsConditionList, setTermsConditionList] = useState({});

    // <---------- Get Privacy & ploicy ------------->
    const GetPrivacyPolicy = async () => {
        set_Is_Loading(true)
        try {
            const response = await TermsCondtion();
            setPrivacyPolicyList(response?.data?.data)
            set_Is_Loading(false)
        } catch (error) {
            console.log("error", error)
            set_Is_Loading(false)
        }
    }
    useEffect(() => {
        GetPrivacyPolicy();
    }, [])
    useEffect(() => {
        // Scroll to the top of the page when the component is rendered
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <div className="bg_nav-slid bg-white">
                <Header />
            </div>
            <div className="header_space"></div>

            {
                is_Loading ? (
                    <Loader />
                ) : (
                    <div className="terms-conditions-area pt-10 pb-70">
                        <div className="container">
                            <div className="section-title">
                                {/* <h3>Terms & Conditions</h3> */}
                            </div>
                            <div className="row pt-45">
                                <div className="col-lg-12">
                                    <div className="single-content">
                                        <h3>{PrivacyPolicyList?.title}</h3>
                                        <p>{ReactHtmlParser(PrivacyPolicyList?.desciption)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <Footer />
        </div>
    )
}

export default TermsCondition1