import axios from "axios";
import { API_BASE_URL } from "../base_url/Base_URL";

// <------------- Recuriter Job Post sections ----------------->
export const RecuriterJobPost = async (RecuriterJobPostData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/post-job`, RecuriterJobPostData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <------------- Get Recuriter Job Post sections ----------------->
export const GetNewJobList = async (headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/recruiter-job`, {}, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <------------- Recuriter Job Post sections ----------------->
export const EdiRecuriterJobPost = async (EditRecuriterJobPostData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/update-job`, EditRecuriterJobPostData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <------------- Recuriter Job Post sections ----------------->
export const DeleteRecuriterJobPost = async (EditRecuriterJobPostData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/update-job-status`, EditRecuriterJobPostData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-----------Recuriter seen all student's applied jobs ---------------->
export const GetRecuriterAllAppliedJobs = async (GetRecuriterAllAppliedJobsData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/student-applied-jobs`, GetRecuriterAllAppliedJobsData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-----------Recuriter seen all student's applied jobs ---------------->
export const AppliedJobsStatusUpdate = async (AppliedJobsStatusUpdateData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/appliedjob-status-update`,
            AppliedJobsStatusUpdateData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-----------Recuriter Details ---------------->
export const RecuriterDetails = async (updaterecruiterInfoData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/update-recruiterInfo`,
            updaterecruiterInfoData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-----------Recuriter Details ---------------->
export const GetRecuriterDetails = async (getrecruiterInfoData, headers) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get-recruiterInfo`,
            getrecruiterInfoData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-----------Recuriter Details ---------------->
export const AddRecuriterTeamMemeber = async (AddTeamMemeberData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/post-team`,
            AddTeamMemeberData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-----------Recuriter Details ---------------->
export const GetRecuriterTeamMemeberList = async (headers) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/team-info`,
            { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-----------Post Technical Interview ---------------->
export const PostTechnicalInterview = async (PostTechnicalInterviewData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/interview-post`,
            PostTechnicalInterviewData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------- Get the Technical Interview ----------------->
export const GetStudentInterviewList = async (headers) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get-interview`, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-----------Post Technical Interview ---------------->
export const PostInterviewRounds = async (PostInterviewRoundsData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/post-interview-round`,
            PostInterviewRoundsData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-----------Post Technical Interview ---------------->
export const GetInterviewWithRounds = async (PostInterviewRoundsData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/get-interviewWithRound`,
            PostInterviewRoundsData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-----------Post Technical Interview ---------------->
export const Postinterviewroundfeedback = async (PostInterviewroundfeedbackData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/interview-round-feedback`,
            PostInterviewroundfeedbackData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <-----------Complete Interview Round ---------------->
export const CompleteInterviewRound = async (CompleteInterviewRoundData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/complete-interview-round`,
            CompleteInterviewRoundData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------- Complete Reject InterView ---------------->
export const CompleteRejectInterview = async (CompleteInterviewRoundData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/complete-reject-interview`,
            CompleteInterviewRoundData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------- Complete Reject InterView ---------------->
export const GetCompletedInterviewList = async (CompletedInterviewListData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/completed-interview-list`, CompletedInterviewListData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------- Complete Reject InterView ---------------->
export const TeamUpcomingInterviews = async (TeamUpcomingInterviewsData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team-upcoming-interviews`, TeamUpcomingInterviewsData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------- Complete Reject InterView ---------------->
export const RecruiterDashboardData = async (RecuriterDashBoardData, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/recruiter-dashboard`, RecuriterDashBoardData, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------- Complete Reject InterView ---------------->
export const CreateRecuriterTemplate = async (CreateRecuriterTemplatedata, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/save-interview-guide`, CreateRecuriterTemplatedata, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------- Complete Reject InterView ---------------->
export const GetRecuriterTemplateList = async (GetRecuriterTemplateListdata, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/interview-guide-list`, GetRecuriterTemplateListdata, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------- Complete Reject InterView ---------------->
export const DeleteRecuriterTemplate = async (DeleteRecuriterTemplatedata, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/interview-guide-delete`, DeleteRecuriterTemplatedata, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------- Complete Reject InterView ---------------->
export const GetRecuriterjobAmount = async (headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/get-job-amount`, {}, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------- Complete Reject InterView ---------------->
export const SubmitProfileRequest = async (data, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/submit-profile-request`, data, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------- Complete Reject InterView ---------------->
export const CheckJobRequest = async (data, headers) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/check-job-request`, data, { headers })
        return response
    } catch (error) {
        return error
    }
}

// <----------- Complete Reject InterView ---------------->
export const WebGetTemplateList = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/web-interview-guide`)
        return response
    } catch (error) {
        return error
    }
}

