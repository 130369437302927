import React, { useEffect, useState } from 'react'
import CommonImageTransport from '../../../common/images/CommonImages'
import CanvasJSReact from '@canvasjs/react-charts';
import { RecruiterDashboardData } from "../../../../api/Recuriter"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../../../loader/Loader';
import Team_Member_Header from '../team_member_header/Team_Member_Header';
import Team_Member_Footer from '../team_member_footer/Team_Member_Footer';
const { CanvasJSChart } = CanvasJSReact;

const Team_Member_Manger_Dashbaord = () => {
    const currentDate = new Date();
    const [selectedYear, setSelectedYear] = useState(null);
    const [is_Loading, setIs_Loading] = useState(false);
    const [RecuriterDashBoardList, setRecuriterDashBoardList] = useState([]);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // <--------- User Authentication token's  ---------->
    const token = localStorage.getItem("team_token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };

    // <--------- Select the Year ----------->
    const handleYearChange = (date) => {
        const year = date.getFullYear();
        setSelectedYear(year);
        GetRecuriterDashBoardList(year)
    };

    // <------------- Get Mentor dashBoard List -------------->
    const GetRecuriterDashBoardList = async (year) => {
        setIs_Loading(true)
        const RecuriterDashBoardData = {
            year
        }
        try {
            const response = await RecruiterDashboardData(RecuriterDashBoardData, headers)
            setIs_Loading(false)
            if(response?.data?.statusCode == "200"){
                setIs_Loading(false)
                setRecuriterDashBoardList(response?.data?.data)
            }
            
        } catch (error) {
            setIs_Loading(false)
        }
    }
    //<-------- Calculate the sum of monthlyCounts ------------->
    const calculateSum = (counts) => {
        return counts.reduce((acc, count) => acc + count, 0);
    };

    const dataPoints = months?.map((month, index) => ({
        label: month,
        y: RecuriterDashBoardList.completeInterview && RecuriterDashBoardList.completeInterview[index] !== null ? RecuriterDashBoardList.completeInterview[index] : 0
    }));

    const RejectedInterview = months?.map((month, index) => ({
        label: month,
        y: RecuriterDashBoardList.rejectedInterview && RecuriterDashBoardList.rejectedInterview[index] !== null ? RecuriterDashBoardList.rejectedInterview[index] : 0
    }));

    const NewProfileInterview = months?.map((month, index) => ({
        label: month,
        y: RecuriterDashBoardList.pendingInterview && RecuriterDashBoardList.pendingInterview[index] !== null ? RecuriterDashBoardList.pendingInterview[index] : 0
    }));

    const TotalInterviewConduct = months?.map((month, index) => ({
        label: month,
        y: RecuriterDashBoardList.totalInterview && RecuriterDashBoardList.totalInterview[index] !== null ? RecuriterDashBoardList.totalInterview[index] : 0
    }));


    const columnOptions = {

        data: [
            {
                type: "column",
                dataPoints: dataPoints,
                name: "Hird Profile",
                showInLegend: true,
                color: "green",
                barThickness: 200
            },
            {
                type: "column",
                showInLegend: true,
                color: "#f15252",
                dataPoints: RejectedInterview,
                name: "Rejected Profile",
            },
            {
                type: "column",
                showInLegend: true,
                color: "#0a405d",
                dataPoints: NewProfileInterview,
                name: "New Profile",
            },

        ]
    };

    const lineOptions = {

        data: [
            {
                type: "column",
                dataPoints: NewProfileInterview,
                name: "Pending Interview",
                showInLegend: true,
                color: "#0a405d",
            }
        ]
    };

    const courseData = RecuriterDashBoardList?.class_graph_data || [];
    const totalCourses = courseData.length;
    const percentagePerCourse = totalCourses > 0 ? 100 / totalCourses : 0;

    // Step 2: Update each course with the calculated 'y' value
    const updatedCourseData = courseData?.map(course => ({
        ...course,
        y: percentagePerCourse
    }));

    const pieOptions = {
        animationEnabled: true,
        exportEnabled: true,
        theme: "light2",
        data: [{
            type: "pie",
            startAngle: 75,
            toolTipContent: "<b>{label}</b>: {y}%",
            showInLegend: true,
            legendText: "{label}",
            indexLabelFontSize: 16,
            indexLabel: "{label}",
            dataPoints: updatedCourseData
        }]
    };
    const areaOptions = {

        data: [
            {
                type: "area",
                dataPoints: TotalInterviewConduct,
                showInLegend: true,
                color: "#0a405d",
                name: "Profile View",
            }
        ]
    };

    // <--------- Render the Recuriter Dashboard Data -------> 
    useEffect(() => {
        GetRecuriterDashBoardList()
    }, [])

    // <------------ Total Interview ---------------->
    const TotalInterview = parseInt(RecuriterDashBoardList?.interviewData?.rejected_count || 0) +
        parseInt(RecuriterDashBoardList?.interviewData?.completed_count || 0);

    return (
        <div>
            {
                is_Loading ? (
                    <>
                        <Loader />
                    </>

                ) : (
                    <>
                        <div className="bg_nav-slid bg-white">
                            <Team_Member_Header />
                        </div>

                        <div className="header_space"></div>

                        <div className="enrolled-area-two py-5 pt-3 bg-light">
                            <div className="container-fluid">
                                
                                <div className="row">
                                    <div className="Dashboard1 col-md-6 pb-3">
                                        <h3>Dashboard</h3>
                                    </div>
                                    <div className='col-md-6 text-end pb-3'>
                                    <DatePicker className='form-control'
                                        // selected={selectedYear}
                                        onChange={handleYearChange}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        placeholderText="Select Year"
                                        maxDate={currentDate}
                                    />
                                    {selectedYear && <p>Selected Year: {selectedYear}</p>}
                                </div>
                                   
                                        <div className="col-sm-6 col-md-4 col-lg-3">
                                        <div className="mb-3 ">
                                                <div className="card widget-flat btn-gradient-warning">
                                                    <div className="bg-layerbg"><img src={CommonImageTransport.circle} alt="" /></div>
                                                    <div className="card-body" style={{ height: "150px" }}>
                                                        <div className="float-end">
                                                            <i className="mdi widget-icon bg-success-lighten ri-eye-line"></i>
                                                        </div>
                                                        <h5 className="text-muted fw-normal mt-0" title="Average Revenue">Total number of interviews</h5>
                                                        <h4 className="mt-2 text-white mb-0">{TotalInterview}</h4>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="mb-3 ">
                                                <div className="card widget-flat btn-gradient-success">
                                                    <div className="bg-layerbg"><img src={CommonImageTransport.circle} alt="" /></div>
                                                    <div className="card-body" style={{ height: "150px" }}>
                                                        <div className="float-end">
                                                            <i className="mdi widget-icon ri-command-line"></i>
                                                        </div>
                                                        <h5 className="text-muted fw-normal mt-0" title="Growth">Total number of hired candidates</h5>
                                                        <h4 className="mt-2 text-white mb-0">{RecuriterDashBoardList?.interviewData?.completed_count}</h4>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="mb-3 ">
                                                <div className="card widget-flat btn-gradient-success">
                                                    <div className="bg-layerbg"><img src={CommonImageTransport.circle} alt="" /></div>
                                                    <div className="card-body" style={{ height: "150px" }}>
                                                        <div className="float-end">
                                                            <i className="mdi widget-icon ri-command-line"></i>
                                                        </div>
                                                        <h5 className="text-muted fw-normal mt-0" title="Growth">Total number of profiles being interviewing</h5>
                                                        <h4 className="mt-2 text-white mb-0">{RecuriterDashBoardList?.pendingInterview ? calculateSum(RecuriterDashBoardList?.pendingInterview) : 'Loading...'}</h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3 ">
                                                <div className="card widget-flat btn-gradient-success">
                                                    <div className="bg-layerbg"><img src={CommonImageTransport.circle} alt="" /></div>
                                                    <div className="card-body" style={{ height: "150px" }}>
                                                        <div className="float-end">
                                                            <i className="mdi widget-icon ri-command-line"></i>
                                                        </div>
                                                        <h5 className="text-muted fw-normal mt-0" title="Growth">Total number of profiles reviewd (Hired + Rejected + interviewing ) </h5>
                                                        <h4 className="mt-2 text-white mb-0">{RecuriterDashBoardList?.totalInterview ? calculateSum(RecuriterDashBoardList?.totalInterview) : 'Loading...'}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 ">
                                                <div className="card widget-flat btn-gradient-danger">
                                                    <div className="bg-layerbg"><img src={CommonImageTransport.circle} alt="" /></div>
                                                    <div className="card-body" style={{ height: "150px" }}>
                                                        <div className="float-end">
                                                            <i className="mdi widget-icon ri-command-line"></i>
                                                        </div>
                                                        <h5 className="text-muted fw-normal mt-0" title="Growth">Total number of profiles were rejected</h5>
                                                        <h4 className="mt-2 text-white mb-0">{RecuriterDashBoardList?.interviewData?.rejected_count}</h4>
                                                    </div>

                                                </div>
                                            </div>
                                           
                                            <div className="mb-3">
                                                <div className="card widget-flat btn-gradient-warning">
                                                    <div className="bg-layerbg"><img src={CommonImageTransport.circle} alt="" /></div>
                                                    <div className="card-body" style={{ height: "150px" }}>
                                                        <div className="float-end">
                                                            <i className="mdi widget-icon bg-success-lighten ri-eye-line"></i>
                                                        </div>
                                                        <h5 className="text-muted fw-normal mt-0" title="Average Revenue">Total number of profiles requested</h5>
                                                        <h4 className="mt-2 text-white mb-0">{RecuriterDashBoardList?.interviewData?.new_count}</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-lg-9">
                                            <div class="row">
                                                <div className="col-md-6 mb-3">
                                                    <div className="card widget-flat">
                                                        <div className="card-body">
                                                        <h4 className="graphtitles">Interview Profile</h4>
                                                        <div className="chart-container" style={{ width: '100%', height: '400px' }}>
                                                            <CanvasJSChart options={columnOptions} />
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <div className="card widget-flat">
                                                    <div className="card-body">
                                                        <h4 className="graphtitles">Profile Requested</h4>
                                                        <div className="chart-container">
                                                            <CanvasJSChart options={lineOptions} />
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <div className="card widget-flat">
                                                    <div className="card-body">
                                                        <h4 className="graphtitles">Training Program</h4>
                                                        <div className="charthhe" id="chartContainer2">
                                                            <CanvasJSChart options={pieOptions} />
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <div className="card widget-flat">
                                                    <div className="card-body">
                                                        <h4 className="graphtitles">Profile Views</h4>
                                                        <div className="charthhe" id="chartContainer2">
                                                            <CanvasJSChart options={areaOptions} />
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        <Team_Member_Footer/>
        </div>
    )
}

export default Team_Member_Manger_Dashbaord