import React, { useState, useEffect } from 'react'
import CommonImageTransport from '../../../common/images/CommonImages';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Dropdown } from 'react-bootstrap';
import { ProfileSections } from '../../../../api/Auth';
import { GetSetings } from '../../../../api/Public';
import { Team_Profile } from '../../../../api/Team_Member';

const Team_Member_Header = () => {

    // <---- Show the Notification section's -------->
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // <----------- Get the User Profile section's -------->
    const [GetUserProfile, SetTeamMemberProfile] = useState({})
    const [SettingList, setSettingList] = useState({});


    const team_id = localStorage.getItem("team_id")
    const team_role = localStorage.getItem("role_id")
    const isLoggedIn = localStorage.getItem("Is_LoggedIn");

    // <-------- User Authetication Token's ------------->
    const token = localStorage.getItem("team_token");
    const headers = {
        "Authorization": `Bearer ${token}`
    }

    // <---------- get the User Profile section's ----------->
    const ProfileSections1 = async () => {
        const data = {
            // id: team_id
        }
        const response = await Team_Profile(data, headers)
        SetTeamMemberProfile(response?.data?.data?.profile)
        localStorage.setItem("recruiter_id", response?.data?.data?.profile?.recruiter_id)
        localStorage.setItem("team_id", response?.data?.data?.profile?.id)
    }
    useEffect(() => {
        ProfileSections1();
    }, [])
    console.log("GetUserProfile", GetUserProfile)

    // <----------- User Logout Fucntionlity ----------->
    const Logout_Team_Member = () => {
        const team_token = localStorage.getItem("team_token");
        if (team_token) {
            const confirmLogout = window.confirm("Are you sure you want to log out?");
            if (confirmLogout) {
                localStorage.clear()
            }

        }
        // window.location.reload();
        navigate('/Signin');
    }

    const HandleGetSettingList = async () => {
        const response = await GetSetings();
        setSettingList(response?.data?.data);
    };

    useEffect(() => {
        HandleGetSettingList();
    }, []);
    return (
        <div>

            <Navbar expand="lg" className="navbar-area ledu-area otherpages-menu">
                <div className='container'>
                    <Navbar.Brand as={Link} to="/team_member_dashboard">
                        {
                            SettingList?.headerlogo != null ? (
                                <>
                                    <img src={SettingList?.headerlogo} height={50} className="logo-one rounded-pill" alt="logo" />
                                </>

                            ) : (
                                <>
                                    <img src={CommonImageTransport?.logoFull} height={50} className="logo-one rounded-pill" alt="logo" />

                                </>
                            )
                        }
                    </Navbar.Brand>

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="w-100 ms-3">
                            <ul className="navbar-nav w-100 ms-3">
                                {
                                    team_role == "5" && (
                                        <li className="nav-item">
                                        <Link to="/team_member_dashboard"
                                            className={location.pathname === '/team_member_dashboard' ? 'active nav-link' : 'nav-link'}>
                                            Dashboard
                                        </Link>
                                    </li>
                                    )
                                }
                                {
                                    team_role == "6" && (
                                        <li className="nav-item">
                                        <Link to="/team_member_manger_dashbaord"
                                            className={location.pathname === '/team_member_manger_dashbaord' ? 'active nav-link' : 'nav-link'}>
                                            Dashboard
                                        </Link>
                                    </li>
                                    )
                                }
                              
                                {
                                    team_role == "6" && (
                                        <li className="nav-item">
                                            <Link to="/team_member_list"
                                                className={location.pathname === '/team_member_list' ? 'active nav-link' : 'nav-link'}>
                                                My Team
                                            </Link>
                                        </li>
                                    )
                                }

                                <li className="nav-item">
                                    <Link to="/team_member_feedback"
                                        className={location.pathname === '/team_member_feedback' ? 'active nav-link' : 'nav-link'}>
                                        Candidate Profiles
                                    </Link>
                                </li>
                                {
                                    team_role == "6" && (
                                        <li className="nav-item">
                                            <Link to="/team_member_job_list"
                                                className={location.pathname === '/team_member_job_list' ? 'active nav-link' : 'nav-link'}>
                                                My Jobs
                                            </Link>
                                        </li>
                                    )
                                }


                                {
                                    team_role == "6" && (
                                        <li className="nav-item">
                                            <Link to="/team_member_interview_guide"
                                                className={location.pathname === '/team_member_interview_guide' ? 'active nav-link' : 'nav-link'}>
                                                Interview Guide
                                            </Link>
                                        </li>
                                    )
                                }
                                {
                                    team_role == "6" && (
                                        <li className="nav-item">
                                            <Link to="/team_member_support"
                                                className={location.pathname === '/team_member_support' ? 'active nav-link' : 'nav-link'}>
                                                Support
                                            </Link>
                                        </li>
                                    )
                                }
                            </ul>
                        </Nav>
                    </Navbar.Collapse>
                    <Nav>
                        {/* <Dropdown className="others-options">
                            <Dropdown.Toggle variant="text-white">
                                {GetUserProfile?.profileimg != null ?
                                    <>
                                        <img src={GetUserProfile?.profileimg} height={50} width={50}
                                            className="rounded-pill" alt="Profile" />
                                    </> :
                                    <>
                                        <img src={CommonImageTransport?.userLogo} height={50} width={50}
                                            className="rounded-pill" alt="Profile" />
                                    </>
                                }
                                {`${GetUserProfile?.fname}  ${GetUserProfile?.lname}`}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Link to="/team_member_profile"
                                    className='dropdown-item'><i className="flaticon-user" />Profile</Link>
                                <li className="list-group-item"
                                    onClick={Logout_Team_Member}><Link to="#" className="logoutbtn2">
                                        <i className="ri-logout-box-fill" />
                                        LOGOUT</Link></li>
                            </Dropdown.Menu>
                        </Dropdown> */}

                        {/*<------ direct logout option's here --------->  */}
                        <Dropdown className="others-options">
                            <Dropdown.Toggle variant="text-white">
                                {GetUserProfile?.profile_img != null ?
                                    <>
                                        <img src={GetUserProfile?.profile_img} height={50} width={50}
                                            className="rounded-pill" alt="Profile" />
                                    </> :
                                    <>
                                        <img src={CommonImageTransport?.userLogo} height={50} width={50}
                                            className="rounded-pill" alt="Profile" />
                                    </>
                                }
                                {`${GetUserProfile?.fname || ""}  ${GetUserProfile?.lname || ""}`}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {/* <Link to="/team_member_reset_password"
                                    className='dropdown-item'><i className="flaticon-user" />Password</Link> */}
                                <Link to="/team_member_profile"
                                    className='dropdown-item'><i className="flaticon-user" />Profile</Link>
                                <li className="list-group-item"
                                    onClick={Logout_Team_Member}><Link to="#" className="logoutbtn2">
                                        <i className="ri-logout-box-fill" />
                                        LOGOUT</Link></li>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                </div>
            </Navbar>
            <div className="header_space"></div>
        </div>
    )
}

export default Team_Member_Header

