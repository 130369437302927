import React, { useState, useEffect } from 'react'
import { CompleteRejectInterview } from '../../../../api/Recuriter';
import Modal from 'react-bootstrap/Modal';
import TagsInput from 'react-tagsinput';
import { useNavigate } from 'react-router-dom';

const Recuriter_Decision_Interviewing = ({ GetInterviewWithRounds }) => {
  const navigate = useNavigate()
  const [DecisionShow, setDecisionShow] = useState(false);
  const [InterviewWithRoundsList, setInterviewWithRoundsList] = useState([]);
  const [InterviewWithRounds, setInterviewWithRounds] = useState([]);
  const [DecisionInterviewStatus, setDecisionInterviewStatus] = useState("")
  const [DecisionInterviewSkills, setDecisionInterviewSkills] = useState([])
  const [DecisionFeedbackrecordedLink, setDecisionFeedbackrecordedLink] = useState("")
  const [DecisionInterviewFeedback, setDecisionInterviewFeedback] = useState("");
  const [DecisionInterviewrRating, setDecisionInterviewrRating] = useState(0);
  const GetInterviewWithRounds_id = localStorage.getItem("GetInterviewWithRounds_id");

  // <--------- User Authentication token's here ----------------->
  const token = localStorage.getItem("User_Token");
  const headers = {
    "Authorization": `Bearer ${token}`
  };

  const handleDecisionInterviewStarClick = (value) => {
    setDecisionInterviewrRating(value);
  };

  const HnadleGetInterviewWithRounds = async (GetInterviewWithRounds_id) => {
    const GetInterviewWithRoundsData = {
      id: GetInterviewWithRounds_id
    }
    try {
      const response = await GetInterviewWithRounds(GetInterviewWithRoundsData, headers)
      setInterviewWithRoundsList(response?.data?.data[0])
      setInterviewWithRounds(response?.data?.data)
    } catch (error) {
      console.log(error)
    }
  }
console.log("HnadleGetInterviewWithRounds_decsion",InterviewWithRoundsList)
  const handleDecsionInterviewComplete = async (e) => {
    e.preventDefault();

    // Check for required fields
    if (!DecisionInterviewrRating || !DecisionInterviewStatus || !DecisionInterviewSkills.length || !DecisionInterviewFeedback) {
      alert("Please fill in all required fields before submitting.");
      return;
    }

    const PostinterviewroundfeedbackData = {
      interviewId: GetInterviewWithRounds_id,
      roundType: "Decision",
      roundNumber: 1,
      is_feedback: 1,
      rating: DecisionInterviewrRating,
      skills: DecisionInterviewSkills,
      feedback: DecisionInterviewFeedback,
      is_hire_status: DecisionInterviewStatus,
    };

    const response = await CompleteRejectInterview(PostinterviewroundfeedbackData, headers);
    if (response?.data?.statusCode === "200") {
      setDecisionShow(false);
      HnadleGetInterviewWithRounds(GetInterviewWithRounds_id);
      navigate("/recruiter_pages/AllProfile")
      // window.location.reload();
    }

    // Reset state after submission
    setDecisionInterviewSkills([]);
    setDecisionInterviewStatus("");
    setDecisionInterviewrRating(0);
    setDecisionInterviewFeedback("");
  };


  useEffect(() => {
    const GetInterviewWithRounds_id = localStorage.getItem("GetInterviewWithRounds_id");
    if (GetInterviewWithRounds_id) {
      HnadleGetInterviewWithRounds(GetInterviewWithRounds_id);
    }
  }, [GetInterviewWithRounds_id]);

  const behavioralInterviews = InterviewWithRounds?.flatMap(interviewWithRoundRes =>
    interviewWithRoundRes?.interviewdetails.filter(detail => detail?.roundtype === "Decision") || []
  );
  const decisionHireStatus = behavioralInterviews?.[0]?.is_hire_status;


  return (
    <div>
      <div>
        <div className="firsthire">
          <div className="card">
            {behavioralInterviews?.[0]?.is_hire_status === "Hire" && (
              <a href="#" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Hired ✓
              </a>
            )
            }
            {behavioralInterviews?.[0]?.is_hire_status === "Reject" && (
              <a href="#" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Rejected ❌
              </a>
            )}

            {
              InterviewWithRoundsList?.decision_status != "2" && (
                <>
                  {
                    InterviewWithRoundsList?.technical_status == "2" && InterviewWithRoundsList?.behavioral_status == "2" ? (
                      <a href="#" className="default-btn rounded" data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => setDecisionShow(true)}>Interview Feedback</a>
                    ) : (
                      <button disabled data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => setDecisionShow(true)}>Interview Feedback</button>
                    )
                  }
                </>
              )
            }
          </div>
        </div>
        {/* <--------- Behaviral Feedbacks sections --------------> */}
        <Modal
          size="lg"
          show={DecisionShow}
          onHide={() => {
            setDecisionShow(false)
            setDecisionInterviewSkills([]);
            setDecisionInterviewStatus();
            setDecisionFeedbackrecordedLink();
            setDecisionInterviewFeedback();
            setDecisionInterviewrRating();
          }}
          aria-labelledby="example-modal-sizes-title-lg"
          className="modal fade" id="exampleModal" tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Interview Feedback</h5>
              <button type="button" className="btn-close"
                onClick={() => {
                  setDecisionShow(false)
                  setDecisionInterviewSkills([]);
                  setDecisionInterviewStatus();
                  setDecisionFeedbackrecordedLink();
                  setDecisionInterviewFeedback();
                  setDecisionInterviewrRating();
                }}
                data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form method="post">
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="form-group">
                      <div className="d-flex gap-4">
                        <label className="m-0 fw-normal">Recommend </label>
                        <div className="form-check text-success">
                          <input className="form-check-input text-success" type="radio"
                            name="DecisionInterviewStatus"
                            value="Hire"
                            onChange={(e) => setDecisionInterviewStatus(e.target.value)}
                            id="flexRadioDefault1" />
                          <label className="form-check-label text-success"
                            htmlFor="flexRadioDefault1">
                            Hire
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input text-danger" type="radio"
                            name="DecisionInterviewStatus"
                            value="Reject"
                            onChange={(e) => setDecisionInterviewStatus(e.target.value)}
                            id="flexRadioDefault2" />
                          <label className="form-check-label text-danger"
                            htmlFor="flexRadioDefault2">
                            Reject
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="form-group d-flex align-items-center gap-4">
                      <label>Star Rating</label>
                      <div>
                        <div className="rate">
                          {[...Array(5)].map((_, index) => {
                            const starValue = 5 - index;
                            return (
                              <label key={index}
                                title={`${starValue} stars`}
                                style={{ fontSize: "1.5rem" }}
                                className={starValue <= DecisionInterviewrRating ? 'star filled' : 'star'}
                                onClick={() => handleDecisionInterviewStarClick(starValue)}>
                                ★
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <div className="form-group mb-2">
                      <label>Top 5 Skills</label>
                      <TagsInput className='form-control' value={DecisionInterviewSkills} name="DecisionInterviewSkills"
                        onChange={(skill) => setDecisionInterviewSkills(skill)}
                        inputProps={{ placeholder: 'Enter Skills' }}
                      />
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-12">
                    <div className="form-group mb-2 myteamform">
                      <label>Recorded Link</label>
                      <input type="text" name="DecisionFeedbackrecordedLink"
                        value={DecisionFeedbackrecordedLink}
                        onChange={(e) => setDecisionFeedbackrecordedLink(e.target.value)}
                        placeholder="https://meet.google.com/odp-nvra-ycf"
                        className="form-control"
                        required />
                    </div>
                  </div> */}
                  <div className="col-12 col-md-12">
                    <div className="form-group mb-2 myteamform">
                      <label>Feedback</label>
                      <textarea placeholder="Message"
                        name='DecisionInterviewFeedback' value={DecisionInterviewFeedback}
                        onChange={(e) => setDecisionInterviewFeedback(e.target.value)}
                        className="form-control"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dd-footer d-flex gap-3 p-3 border-top">
                <button type="button" className="btn btn-secondary w-100"
                  data-bs-dismiss="modal">Close</button>
                <button
                  type="button"
                  onClick={handleDecsionInterviewComplete}
                  disabled={
                    !DecisionInterviewrRating || // Star rating is required
                    !DecisionInterviewStatus || // Status (Hire/Reject) is required
                    !DecisionInterviewSkills.length || // Skills are required
                    !DecisionInterviewFeedback // Feedback is required
                  }
                  className="btn default-btn w-100"
                >
                  Submit
                </button>

              </div>
            </form>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default Recuriter_Decision_Interviewing