import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../../../loader/Loader';
import Team_Member_Header from '../team_member_header/Team_Member_Header';
import CommonImageTransport from '../../../common/images/CommonImages';
import Team_Member_Footer from '../team_member_footer/Team_Member_Footer';
import { Team_Dashboard } from '../../../../api/Team_Member';

const Team_Member_Dashboard = () => {
    const currentDate = new Date();
    const [selectedYear, setSelectedYear] = useState(null);
    const [is_Loading, setIs_Loading] = useState(false);
    const [RecuriterDashBoardList, setRecuriterDashBoardList] = useState({});

    // <--------- User Authentication token's  ---------->
    const token = localStorage.getItem("team_token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };

    // <--------- Select the Year ----------->
    const handleYearChange = (date) => {
        const year = date.getFullYear();
        setSelectedYear(year);
        GetRecuriterDashBoardList(year)
    };

    // <------------- Get Mentor dashBoard List -------------->
    const GetRecuriterDashBoardList = async (year) => {
        setIs_Loading(true)
        const RecuriterDashBoardData = {
            year
        }
        try {
            const response = await Team_Dashboard(RecuriterDashBoardData, headers)
            setIs_Loading(false)
            if (response?.data?.statusCode == "200") {
                setIs_Loading(false)
                setRecuriterDashBoardList(response?.data?.data)
            }

        } catch (error) {
            setIs_Loading(false)
        }
    }
    // <--------- Render the Recuriter Dashboard Data -------> 
    useEffect(() => {
        GetRecuriterDashBoardList()
    }, [])
console.log("RecuriterDashBoardList",RecuriterDashBoardList)


    return (
        <div>
            {
                is_Loading ? (
                    <>
                        <Loader />
                    </>

                ) : (
                    <>
                        <div className="bg_nav-slid bg-white">
                            <Team_Member_Header />
                        </div>

                        <div className="header_space"></div>

                        <div className="enrolled-area-two py-5 pt-3 bg-light">
                            <div className="container">

                                <div className="row">
                                    <div className="Dashboard1 col-md-6 pb-3">
                                        <h3>Dashboard</h3>
                                    </div>
                                    <div className='col-md-6 text-end pb-3'>
                                        <DatePicker className='form-control'
                                            // selected={selectedYear}
                                            onChange={handleYearChange}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            placeholderText="Select Year"
                                            maxDate={currentDate}
                                        />
                                        {selectedYear && <p>Selected Year: {selectedYear}</p>}
                                    </div>
                         
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                        <div className="mb-3 ">
                                            <div className="card widget-flat btn-gradient-warning">
                                                <div className="bg-layerbg"><img src={CommonImageTransport.circle} alt="" /></div>
                                                <div className="card-body" style={{ height: "150px" }}>
                                                    <div className="float-end">
                                                        <i className="mdi widget-icon bg-success-lighten ri-eye-line"></i>
                                                    </div>
                                                    <h5 className="text-muted fw-normal mt-0" title="Average Revenue">Total number of Technical</h5>
                                                    <h4 className="mt-2 text-white mb-0">{RecuriterDashBoardList?.teamTechnicalInterview}</h4>
                                                </div>

                                            </div>
                                        </div>
                                        

                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                        <div className="mb-3 ">
                                            <div className="card widget-flat btn-gradient-success">
                                                <div className="bg-layerbg"><img src={CommonImageTransport.circle} alt="" /></div>
                                                <div className="card-body" style={{ height: "150px" }}>
                                                    <div className="float-end">
                                                        <i className="mdi widget-icon ri-command-line"></i>
                                                    </div>
                                                    <h5 className="text-muted fw-normal mt-0" title="Growth">Total number of behaviroal</h5>
                                                    <h4 className="mt-2 text-white mb-0">{RecuriterDashBoardList?.teamInterview}</h4>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <Team_Member_Footer/>
                    </>
                )
            }
            {/* <Recruiter_Footer/> */}
        </div>
    )
}

export default Team_Member_Dashboard