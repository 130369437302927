import React, { useState, useEffect } from 'react'
// import '../../assets/css/iconplugins.css'
// import '../../assets/css/style.css'
// import '../../assets/css/responsive.css'
// import '../../assets/images/favicon.png';
import { Link, useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import { SendOtp, Team_Confirm_Otp, Team_Login, Team_Send_Otp } from '../../../../api/Auth';
import CommonImageTransport from '../../../common/images/CommonImages';

const Team_Member_Login_Otp_Verify = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState(false)
    const user_email = localStorage.getItem("user_Email")
    const Team_Login_Data = JSON.parse(localStorage.getItem("Team_Login_Data"));
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const data = location.state;
    }, [location])


    // <----------- This is Otp Verify Student SignUp----------------->
    const OtpVerifySign1 = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        if (otp.length !== 6) {
            setIsLoading(false)
            toast.error("Please enter a valid 6-digit OTP.");
            return;
        }
        try {
            const SendOtpVerifyData = {
                type: "login",
                email: user_email,
                otp
            };
            const response = await Team_Confirm_Otp(SendOtpVerifyData)
            console.log("OtpVerify_OtpVerify", response)
            if (response?.data?.statusCode == "200") {
                const Team_Login_response = await Team_Login(Team_Login_Data);
                console.log("Team_Login_response", Team_Login_response)
                if (Team_Login_response?.data?.statusCode == "200") {
                    setIsLoading(false)
                    toast.success(response?.data?.msg)
                    localStorage.setItem("team_token", Team_Login_response?.data?.token)
                    // localStorage.removeItem("user_Email")
                    localStorage.setItem("team_id", Team_Login_response?.data?.data?.user?.id)
                    localStorage.setItem("team_role", Team_Login_response?.data?.data?.user?.member_role)
                    navigate("/team_member_feedback")
                }
                else if (Team_Login_response?.data?.statusCode == "400") {
                    setIsLoading(false)
                    toast.error(Team_Login_response?.data?.msg)
                    alert(Team_Login_response?.data?.msg)
                }
            }

            if (response.data.statusCode == "400") {
                setIsLoading(false)
                toast.error(response.data.msg)
            }
        } catch (error) {
            console.log("Error:", error);
            setIsLoading(true)
        }
    };

    // <----------- Resend Otp ------------>
    const ResendOTP = async () => {
        setIsLoading(true)
        const ResndOtpData = {
            type: "login",             //login,signup,resetpassword
            email: Team_Login_Data.email,
            password: Team_Login_Data.password
        }
        try {
            const response = await Team_Send_Otp(ResndOtpData)
            console.log("ResendOTP", response)
            if (response?.data?.statusCode == "200") {
                setIsLoading(false)
                setTimeout(() => {
                    toast.success("Otp send on your email, please check.")
                    // localStorage.removeItem("ActiveTabs")

                }, 1000)
            }

            else if (response?.data?.statusCode == "400") {
                setIsLoading(false)
                setTimeout(() => {
                    toast.error(response?.data?.msg)
                }, 1000)
            }

        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }
    return (
        <div className="login-signup-bg">
            {
                isLoading ? (
                    <>
                        <div className="chat-window text-center">
                            <div style={{
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                margin: "auto",
                            }} className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </>

                ) : (
                    <>
                        <div className="user-area p-2 p-md-4 p-lg-5 pt-lg-3">
                            <div className="container">
                                <div className="row">
                                    <ToastContainer style={{ marginTop: "120px" }} />
                                    <div className="col-lg-6 p-0">
                                        <div className="user_login">
                                            <img src={CommonImageTransport.loginbg}
                                                className="imgk-fluid w-100" alt="login-banner" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 d-flex p-0">
                                        <div className="user-all-form userlogin position-relative">
                                            <div className="login_bottom">
                                                <img src={CommonImageTransport.loginBottom} alt="" />
                                            </div>
                                            <div className="contact-form">

                                                <h3 className="user-title">Enter OTP</h3>
                                                <p>Code Just Sent to <span className="text-info fw-bold">{user_email}</span></p>
                                                <form id="contactForms" className="mt-5" onSubmit={OtpVerifySign1}>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-floating form-group">
                                                                <div className='otp-input-fields'>
                                                                    <OtpInput className="otp__digit otp__field__1"
                                                                        value={otp}
                                                                        onChange={(newOtp) => {
                                                                            setOtp(newOtp)
                                                                            if (newOtp.length === 6) {
                                                                                setOtpError(false);
                                                                            }
                                                                        }}
                                                                        numInputs={6}
                                                                        renderInput={(props) =>
                                                                            <input   {...props} />}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <div class="form-floating form-group">
                                    <div class="otp-input-fields">
                                        <input type="number" class="otp__digit otp__field__1"/>
                                        <input type="number" class="otp__digit otp__field__2"/>
                                        <input type="number" class="otp__digit otp__field__3"/>
                                        <input type="number" class="otp__digit otp__field__4"/>
                                        <input type="number" class="otp__digit otp__field__5"/>
                                        <input type="number" class="otp__digit otp__field__6"/>
                                      </div>
                                </div> */}
                                                        </div>

                                                        <div className="col-lg-12 col-md-12">
                                                            <button type="submit"
                                                                className="default-btn w-100 rounded" >
                                                                Verify
                                                            </button>

                                                            <h6 className="mt-4 text-center fw-normal text-muted"
                                                            >Didn't get the otp?
                                                                <Link className="fw-bold" to="#"
                                                                    onClick={ResendOTP}>Resend</Link></h6>
                                                        </div>
                                                    </div>
                                                </form>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

        </div>
    )
}

export default Team_Member_Login_Otp_Verify