import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { GetRecuriterTemplateList, WebGetTemplateList } from '../../../../api/Recuriter'
import Team_Member_Header from '../team_member_header/Team_Member_Header'
import Team_Member_Footer from '../team_member_footer/Team_Member_Footer'


const Team_Member_Interview_Guide_Details = () => {

    const { Interview_Guide_id } = useParams();
    const [is_loading,setIs_loading] = useState(false)
    console.log("Interview_guide_details_id", Interview_Guide_id)
    const [Activetabs, setActiveTabs] = useState(0)
    const [TemplateList, setTemplateList] = useState([]);
    const [WebTemplateList, setWebTemplateList] = useState([]);
    const [TemplateDetails, setTemplateDetails] = useState(null);

    const team_id = localStorage.getItem("team_id");
    const Interview_Guilde_Title = localStorage.getItem("Interview_Guilde_Title");


    // <------- User Authentication token's --------------->
    const token = localStorage.getItem("team_token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };


    // const GetTemplateList = async () => {
    //     const GetRecuriterTemplateListdata = {
    //         recruiter_id
    //     }
    //     try{
    //         const response = await GetRecuriterTemplateList(GetRecuriterTemplateListdata, headers);
    //         setTemplateList(response?.data?.data)

    //     }catch(error){
    //     console.log("error",error)
    //     }
    // }
    const HandleGetWebTemplateList = async () => {
        try{
            const response = await WebGetTemplateList(headers);
            setWebTemplateList(response?.data?.data)

        }catch(error){
        console.log("error",error)
        }
    }
    console.log("GetTemplateList", TemplateList?.id)

    // <--------- Fetch the Teamplate List  ----------->
    useEffect(() => {
        // GetTemplateList();
        HandleGetWebTemplateList();
    }, [])

    // <---------- fetch the Data by Teamplate Id ----------->
    useEffect(() => {
        if (TemplateList.length > 0) {
            const FindTemplateDetails = TemplateList.find(template => template.id === parseInt(Interview_Guide_id));
            console.log("FindTemplateDetails", FindTemplateDetails);
            setTemplateDetails(FindTemplateDetails);
        }
       
    }, [TemplateList, Interview_Guide_id]);
    useEffect(() => {
         if(WebTemplateList?.length > 0){
            const FindTemplateDetails = WebTemplateList?.find(template => template.id === parseInt(Interview_Guide_id));
            console.log("FindTemplateDetails", FindTemplateDetails);
            setTemplateDetails(FindTemplateDetails);
        }
       
    }, [WebTemplateList, Interview_Guide_id]);
    console.log("TemplateDetails",TemplateDetails?.guide_pdf)

    const HandleActiveTabs = (tabs) => {
        setActiveTabs(tabs)
    }
    return (
        <div>
            <div className="bg_nav-slid bg-white">
                <Team_Member_Header/>
            </div>
            <div className="header_space"></div>
            <div className="blog-area pt-1 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center product-topper">
                                <h2>{Interview_Guilde_Title}</h2>
                            </div>

                            <ul className="nav tabs_signup justify-content-center gap-2 pb-4" id="pills-tab" role="tablist">
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                           
                                <div className={`tab-pane fade ${Activetabs === 0 ? "show active" : ""}`} id="pills-contact" role="tabpanel"
                                    aria-labelledby="pills-contact-tab">  <embed
                                    src={TemplateDetails?.guide_pdf}
                                    type="application/pdf"
                                    width="100%"
                                    height="2100px"
                                />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Team_Member_Footer/>
        </div>
    )
}

export default Team_Member_Interview_Guide_Details