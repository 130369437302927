import React, { useEffect, useState } from 'react';
import Header from '../../../common/header/Header';
import HomeTopSlider from './home_components/Home_Top_Slider';
import TrainingPrograms from './home_components/Training_Programs';
import AboutFreshGrad from './home_components/About_Fresh_Grad';
import TrainerBlock from './home_components/Mentor_Block';
import MentorSupportAndStatics from './home_components/Mentor_Support_And_Statics';
import Testimonial from './home_components/Testimonial';
import TopMentors from './home_components/Top_Mentors';
import Footer from '../../../common/footer/Footer';
import home from '../../../../assets/images/video-home.mp4';
import '../../../../assets/css/style.css';
import { GetImgSlidersList } from '../../../../api/Global';
import Loader from '../../../../loader/Loader';

const Home = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [sliderList, setSliderList] = useState([])

    const HandleGetImgSlider = async () => {
        setIsLoading(true)
        try {
            const response = await GetImgSlidersList();
            setSliderList(response?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("error", sliderList)
        }
    }
    console.log("sliderList", sliderList)
    useEffect(() => {
        HandleGetImgSlider()
    }, [])

    useEffect(() => {
        // Scroll to the top of the page when the component is rendered
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {
                isLoading ? (
                    <>
                        <Loader />
                    </>
                ) : (
                    <>
                        <div className="bg_nav-slid" >
                            <Header />
                            <HomeTopSlider />
                        </div>
                        <div className="video_home" >
                            {
                                sliderList?.map((sliderListResult) => {
                                    return (
                                        <>
                                            {
                                                <video style={{width: "100%", objectFit: "contain", display: "block", margin: "0 auto"}} autoPlay controls  className="photo-item__video NewsDetailsVideos"  loop={true} muted preload="auto">
                                                    <source src={sliderListResult?.slider_file}
                                                        type="video/mp4" />
                                                </video>
                                            }

                                        </>
                                    )
                                })
                            }

                        </div>
                        <TrainingPrograms />
                        <AboutFreshGrad />
                        <TrainerBlock />
                        <MentorSupportAndStatics />
                        <TopMentors />
                        <Testimonial />
                        <Footer />
                    </>
                )

            }

        </>
    );
};

export default Home;
