import React, { useState, useEffect } from 'react'
import '../../assets/css/iconplugins.css'
import '../../assets/css/style.css'
import '../../assets/css/responsive.css'
import '../../assets/images/favicon.png';
import Header from '../../components/common/header/Header';
import CommonImageTransport from '../../components/common/images/CommonImages';
import { Link, useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input';
import { SendOtp, UserLogin } from "../../api/Auth"
import { useLocation } from 'react-router-dom';
import { OtpVerify } from '../../api/Auth'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';

const Otp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState(false)
    const user_email = localStorage.getItem("user_Email")
    const user_Login_Data = JSON.parse(localStorage.getItem("user_Login_Data"));
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const data = location.state;
    }, [location])


    // <----------- This is Otp Verify Student SignUp----------------->
    const OtpVerifySign1 = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        if (otp.length !== 6) {
            setIsLoading(false)
            toast.error("Please enter a valid 6-digit OTP.");
            return;
        }
        try {
            const SendOtpVerifyData = {
                type: "login",
                email: user_email,
                otp
            };
            const response = await OtpVerify(SendOtpVerifyData)
            console.log("OtpVerify_OtpVerify", response)
            if (response.data.data.isOtpMatch == true) {
                const Login_response = await UserLogin(user_Login_Data);
                if (Login_response?.data?.data?.user?.role_id === 2) {
                    const User_Token = Login_response?.data?.token;
                    const User_id = Login_response?.data?.data?.user?.id;
                    const role_id = Login_response?.data?.data?.user?.role_id;
                    // localStorage.setItem("Is_LoggedIn", true);
                    localStorage.setItem("User_Token", User_Token);
                    localStorage.setItem("User_id", User_id);
                    // localStorage.setItem("role_id", role_id);
                    // localStorage.removeItem("user_Email");
                    // localStorage.removeItem("user_Login_Data");
                    toast.success("Otp verified Successful & student login Successful !!!")
                    localStorage.removeItem("ActiveTabs")
                    setTimeout(() => {
                        navigate("/Student_step");
                        setIsLoading(false)
                    })

                }

                if (Login_response?.data?.data?.user?.role_id === 4) {
                    const User_Token = Login_response?.data?.token;
                    const User_id = Login_response?.data?.data?.user?.id;
                    const role_id = Login_response?.data?.data?.user?.role_id;
                    localStorage.setItem("Is_LoggedIn", true);
                    localStorage.setItem("User_Token", User_Token);
                    localStorage.setItem("User_id", User_id);
                    localStorage.setItem("role_id", role_id);
                    // localStorage.removeItem("user_Email");
                    // localStorage.removeItem("user_Login_Data");
                    toast.success("Otp verified Successful & Recruiter login Successful !!!")
                    localStorage.removeItem("ActiveTabs")
                    setTimeout(() => {
                        navigate("/recruiter_pages/RecruiterStep");
                        setIsLoading(false)
                    })

                }

                if (Login_response?.data?.data?.user?.role_id === 3) {
                    const User_Token = Login_response.data.token;
                    const User_id = Login_response?.data?.data?.user?.id;
                    const role_id = Login_response?.data?.data?.user?.role_id;
                    localStorage.setItem("Is_LoggedIn", true);
                    localStorage.setItem("User_Token", User_Token);
                    localStorage.setItem("User_id", User_id);
                    localStorage.setItem("role_id", role_id);
                    // localStorage.removeItem("user_Email");
                    // localStorage.removeItem("user_Login_Data");
                    setIsLoading(false)
                    toast.success("Otp verified Successful & mentor login Successful !!!")
                    localStorage.removeItem("ActiveTabs")
                    setTimeout(() => {
                        navigate("/mentor_pages/Mentor-Step");
                        setIsLoading(false)
                    })
                }
                if (Login_response?.data?.data?.user?.role_id === 6) {
                    const User_Token = Login_response.data.token;
                    const User_id = Login_response?.data?.data?.user?.id;
                    const role_id = Login_response?.data?.data?.user?.role_id;
                    // localStorage.setItem("Is_LoggedIn", true);
                    localStorage.setItem("team_token", User_Token);
                    localStorage.setItem("team_id", User_id);
                    localStorage.setItem("role_id", role_id);
                    localStorage.setItem("team_role", Login_response?.data?.data?.user?.member_role)
                    // localStorage.removeItem("user_Email");
                    // localStorage.removeItem("Team_Login_Data");
                    setIsLoading(false)
                    toast.success("Otp verified Successful & team member login Successful !!!")
                    localStorage.removeItem("ActiveTabs")
                    setTimeout(() => {
                        navigate("/team_member_feedback");
                        setIsLoading(false)
                    })
                }
                if (Login_response?.data?.data?.user?.role_id === 5) {
                    const User_Token = Login_response.data.token;
                    const User_id = Login_response?.data?.data?.user?.id;
                    const role_id = Login_response?.data?.data?.user?.role_id;
                    // localStorage.setItem("Is_LoggedIn", true);
                    localStorage.setItem("team_token", User_Token);
                    localStorage.setItem("team_id", User_id);
                    localStorage.setItem("role_id", role_id);
                    localStorage.setItem("team_role", Login_response?.data?.data?.user?.member_role)
                    // localStorage.removeItem("user_Email");
                    // localStorage.removeItem("Team_Login_Data");
                    setIsLoading(false)
                    toast.success("Otp verified Successful & team member login Successful !!!")
                    localStorage.removeItem("ActiveTabs")
                    setTimeout(() => {
                        navigate("/team_member_feedback");
                        setIsLoading(false)
                    })
                }
                if (Login_response?.response?.data?.statusCode == "400") {
                    alert(Login_response?.response?.data?.msg)
                    navigate("/Signin")
                    setIsLoading(false)

                }

                if (Login_response?.data?.statusCode == "400") {
                    setIsLoading(false)
                    setTimeout(() => {
                        alert("Sorry User deatils don't meet the our requirements")
                        // toast.error("Sorry User deatils don't meet the our requirements")
                    }, 2000)
                }
                console.log("Login_response", Login_response)

            }
            if (response.data.statusCode == "400") {
                setIsLoading(false)
                setTimeout(() => {
                    toast.error(response.data.msg)
                }, 1000)
            }
        } catch (error) {
            console.log("Error:", error);
            setIsLoading(true)
        }
    };

    // <----------- Resend Otp ------------>
    const ResendOTP = async () => {
        setIsLoading(true)
        const ResndOtpData = {
            type: "login",             //login,signup,resetpassword
            email: user_Login_Data.email,
            password: user_Login_Data.password,
            time_zone: user_Login_Data.time_zone
        }
        try {
            const response = await SendOtp(ResndOtpData)
            console.log("ResendOTP", response)
            if (response?.data?.statusCode == "200") {
                setIsLoading(false)
                setTimeout(() => {
                    toast.success("Otp send on your email, please check.")
                    localStorage.removeItem("ActiveTabs")

                }, 1000)
            }

            else if (response?.data?.statusCode == "400") {
                setIsLoading(false)
                setTimeout(() => {
                    toast.error(response?.data?.msg)
                }, 1000)
            }

        } catch (error) {
            setIsLoading(false)
            console.log("error", error)
        }
    }
    return (
        <div className="login-signup-bg">
            <Header />
            {
                isLoading ? (
                    <>
                        <div className="chat-window text-center">
                            <div style={{
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                margin: "auto",
                            }} className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </>

                ) : (
                    <>
                        <div className="user-area p-2 p-md-4 p-lg-5 pt-lg-3">
                            <div className="container">
                                <div className="row">
                                    <ToastContainer style={{ marginTop: "120px" }} />
                                    <div className="col-lg-6 p-0">
                                        <div className="user_login">
                                            <img src={CommonImageTransport.loginbg}
                                                className="imgk-fluid w-100" alt="login-banner" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 d-flex p-0">
                                        <div className="user-all-form userlogin position-relative">
                                            <div className="login_bottom">
                                                <img src={CommonImageTransport.loginBottom} alt="" />
                                            </div>
                                            <div className="contact-form">

                                                <h3 className="user-title">Enter OTP</h3>
                                                <p>Code Just Sent to <span className="text-info fw-bold">{user_email}</span></p>
                                                <form id="contactForms" className="mt-5" onSubmit={OtpVerifySign1}>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-floating form-group">
                                                                <div className='otp-input-fields'>
                                                                    <OtpInput className="otp__digit otp__field__1"
                                                                        value={otp}
                                                                        onChange={(newOtp) => {
                                                                            setOtp(newOtp)
                                                                            if (newOtp.length === 6) {
                                                                                setOtpError(false);
                                                                            }
                                                                        }}
                                                                        numInputs={6}
                                                                        renderInput={(props) =>
                                                                            <input   {...props} />}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <div class="form-floating form-group">
                                    <div class="otp-input-fields">
                                        <input type="number" class="otp__digit otp__field__1"/>
                                        <input type="number" class="otp__digit otp__field__2"/>
                                        <input type="number" class="otp__digit otp__field__3"/>
                                        <input type="number" class="otp__digit otp__field__4"/>
                                        <input type="number" class="otp__digit otp__field__5"/>
                                        <input type="number" class="otp__digit otp__field__6"/>
                                      </div>
                                </div> */}
                                                        </div>

                                                        <div className="col-lg-12 col-md-12">
                                                            <button type="submit"
                                                                className="default-btn w-100 rounded" >
                                                                Verify
                                                            </button>

                                                            <h6 className="mt-4 text-center fw-normal text-muted"
                                                            >Didn't get the otp?
                                                                <Link className="fw-bold" to="#"
                                                                    onClick={ResendOTP}>Resend</Link></h6>
                                                        </div>
                                                    </div>
                                                </form>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

        </div>
    )
}

export default Otp