import React, { useEffect, useState } from 'react'
import Team_Member_Header from '../team_member_header/Team_Member_Header'
import { Team_Profile } from '../../../../api/Team_Member';
import { GetSetings } from '../../../../api/Public';
import Team_Member_Common_Profile from '../team_member_common_profile/Team_Member_Common_Profile';
import Loader from '../../../../loader/Loader';

const Team_Member_Profile = () => {
  // <----------- Get the User Profile section's -------->
  const [is_loading, set_Is_Loading] = useState(false)
  const [GetUserProfile, SetTeamMemberProfile] = useState({})
  const [SettingList, setSettingList] = useState({});


  const team_id = localStorage.getItem("team_id")

  // <-------- User Authetication Token's ------------->
  const token = localStorage.getItem("team_token");
  const headers = {
    "Authorization": `Bearer ${token}`
  }

  // <---------- get the User Profile section's ----------->
  const ProfileSections1 = async () => {
    set_Is_Loading(true)
    const data = {
      id: team_id
    }
    try {
      const response = await Team_Profile(data, headers)
      set_Is_Loading(false)
      SetTeamMemberProfile(response?.data?.data?.profile)
    } catch (error) {
      set_Is_Loading(false)
    }
  }
  useEffect(() => {
    ProfileSections1();
  }, [])

  const HandleGetSettingList = async () => {
    const response = await GetSetings();
    setSettingList(response?.data?.data);
  };

  useEffect(() => {
    HandleGetSettingList();
  }, []);
  return (
    <div>
      {/*  */}
      {
        is_loading ? (
          <Loader />
        ) : (
          <>
            <Team_Member_Header />
            <div className="client_filter-job py-5 pt-2">
              <div className="stepsForm">
                <form method="post" id="msform">
                  <div className="container">
                    <div className="row">
                      <div className='col-md-4'>
                        {/* <--------- Recruiter Common Profile ----------> */}
                        <Team_Member_Common_Profile />
                      </div>
                      <div className="col-md-8 d-flex">
                        <div className="flexdr position-relative p-3 p-md-4 shadow-lg">
                          <div className="personal_user_info border p-4 rounded">
                            <div className="d-flex justify-content-between">
                              <h4>Personal Information</h4>
                              {/* <Link to="/recruiter_pages/Recuriter_Edit_Profile"
                          className="default-btn rounded p-2">Edit <i className="ri-edit-2-fill" /></Link> */}
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group info__profile">
                                  <p>First Name</p>
                                  <h6>{GetUserProfile?.fname}</h6>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group info__profile">
                                  <p>Last Name</p>
                                  <h6>{GetUserProfile?.lname}</h6>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group info__profile">
                                  <p>Email ID</p>
                                  <h6>{GetUserProfile?.email}</h6>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group info__profile">
                                  <p>Role </p>
                                  <h6>{GetUserProfile?.member_role}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )
      }

    </div>
  )
}

export default Team_Member_Profile