import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CommonImageTransport from '../../../../common/images/CommonImages'
import { GetCourseList } from '../../../../../api/Global';

const TrainingPrograms = () => {
      const navigate = useNavigate();
      const [CourseList, setCourseList] = useState([]);
      const [Course_id, setCourse_id] = useState("");

      const HandleCourseList = async () => {
            const CourseData = {
                  Course_id
            }
            try {
                  const response = await GetCourseList(CourseData);
                  setCourseList(response?.data?.data)
            } catch (error) {
                  console.log("error", error)
            }
      }

      const NavigateCourseDeatils = (Course_id) => {
            setCourse_id(Course_id)
            navigate(`/CoursesDetails/${Course_id}`)
      }

      console.log("CourseList", CourseList)
      useEffect(() => {
            HandleCourseList()
      }, [])



      return (
            <div className="courses-area py-5 bg-light">
                  <div className="container">
                        <div className="section-title text-center mb-45">
                              <h2>Enroll in FREE Job-Oriented Hands-On Training Programs </h2>
                              {/* <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam.
                              </p> */}
                        </div>
                        <div className="row" style={{ cursor: "pointer" }}>
                              {
                                    CourseList?.slice(0, 6)?.map((CourseListResult) => {
                                          return (
                                                <>

                                                      <div className="col-lg-4 col-md-6 col-sm-6"
                                                            onClick={() => NavigateCourseDeatils(CourseListResult?.id)}>
                                                            <div className="courses-item">

                                                                  <img src={CourseListResult?.course_img} alt="Courses" style={{ width: "100%", height: "200px" }} />

                                                                  <div className="content">
                                                                        <Link to="#" className="tag-btn">{CourseListResult?.category}</Link>
                                                                        <div className="price-text">Free</div>
                                                                        <h3> <Link to="#">{CourseListResult?.title}</Link></h3>
                                                                        <ul className="course-list">

                                                                              <li><i className="ri-vidicon-fill"></i> {CourseListResult?.total_classes} Days</li>
                                                                        </ul>
                                                                        <div className="bottom-content">
                                                                              <Link to="#" className="user-area">
                                                                                    <img src={CourseListResult?.instructor_img} style={{ width: "50px", height: "50px", borderRadius: "50%" }} alt="Instructors" />
                                                                                    <h3>{CourseListResult?.instructor_name}</h3>
                                                                              </Link>

                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>

                                                </>
                                          )
                                    })
                              }



                        </div>
                        {CourseList?.length > 6 && (
                              <div class="col-lg-12 col-md-12 text-center">
                                    <Link
                                          to="/Course_List"
                                          class="default-btn rounded"
                                    >
                                          View All
                                    </Link>
                              </div>
                        )}
                  </div>
            </div>
      )
}

export default TrainingPrograms