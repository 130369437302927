import React, { useState, useEffect } from 'react';
import CommonImageTransport from '../../../common/images/CommonImages';
import "../../../../assets/css/style.css";
import Loader from '../../../../loader/Loader';
import 'react-datetime/css/react-datetime.css';
import 'react-tagsinput/react-tagsinput.css';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Team_Member_Header from '../team_member_header/Team_Member_Header';
import Team_Member_Footer from '../team_member_footer/Team_Member_Footer';
import { Recruiter_Team_Info } from '../../../../api/Team_Member';

const Team_Member_List = () => {
    // <--------- This is UseState for Loader Pages-------->

    const [isLoading, setIsLoading] = useState(true);
    const [TeamMemeberList, setTeamMemeberList] = useState([]);
    const [ActiveTabs, setActiveTabs] = useState(TeamMemeberList?.id);
    const [TeamUpcomingInterviewsList, setTeamUpcomingInterviewsList] = useState([]);
    console.log("TeamUpcomingInterviewsList", TeamUpcomingInterviewsList)
  
    const recruiter_userid = localStorage.getItem("recruiter_id");
    const token = localStorage.getItem("team_token");
    const headers = {
        "Authorization": `Bearer ${token}`
    };

    //   <------ Handle Active Tabs Sections ------------->
    const HandleActiveTabs = (index) => {
        setActiveTabs(index)
    }

    // <-------- get the Team Member -------------->
    const GetTeamMemeberList = async () => {
        setIsLoading(true)
        const data = {
            recruiter_user_id:recruiter_userid
        }
        try {
            const response = await Recruiter_Team_Info(data,headers)
            if (response?.data?.statusCode == "200") {
                setIsLoading(false)
            }
            setTeamMemeberList(response?.data?.data)
        } catch (error) {
            setIsLoading(true)
            console.log("error", error)
        }
    }
    console.log("TeamMemeberList", TeamMemeberList)

    // <------------ get the Upcoming Interview List here ------------>
    // const GetUpcomingInterviewList = async (id) => {
    //     const TeamUpcomingInterviewsData = {
    //         team_member_id: id
    //     }
    //     console.log("TeamUpcomingInterviewsData", TeamUpcomingInterviewsData)
    //     const response = await TeamUpcomingInterviews(TeamUpcomingInterviewsData, headers)
    //     console.log("", response)
    //     setTeamUpcomingInterviewsList(response?.data?.data)
    // }
    // console.log("GetUpcomingInterviewList", TeamUpcomingInterviewsList)

    // <--------- This UseEffect for Loader Pages-------->
    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 2200);
        return () => clearTimeout(timeout);
    }, []);
    // <------ Get Team Member List ----------->
    useEffect(() => {
        GetTeamMemeberList();
        // GetUpcomingInterviewList();
    }, [])


    // <---------- Show the bydefault First Jobs  --------->
    useEffect(() => {
        if (TeamMemeberList?.length > 0) {
            HandleActiveTabs(TeamMemeberList[0]?.id);
            // GetUpcomingInterviewList(TeamMemeberList[0]?.id);
        }
    }, [TeamMemeberList]);

    const parseEmailString = (emailString) => {

        return emailString?.replace(/[{}]/g, ' ').split(',');
    };
    return (
        <div>
            {
                isLoading ? <Loader /> : <>
                    <div className="bg_nav-slid bg-white">
                        <Team_Member_Header />
                    </div>
                    <ToastContainer style={{ marginTop: "100px" }} />

                    <div className="header_space"></div>
                                <div className="recruiter_list pb-5 pt-4">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-flex mb-3 justify-content-between gap-2">
                                                    {
                                                        TeamMemeberList?.length > 0 && (
                                                            <div>
                                                                <h5>Team List</h5>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            {
                                                TeamMemeberList?.length > 0 ? (
                                                    <>
                                                        <div className="left_recruiterlist">
                                                            <div className="sticky-header">
                                                                <div className="nav flex-column recruiter_all_lists nav-pills" id="v-pills-tab" role="tablist"
                                                                    aria-orientation="vertical">
                                                                    {
                                                                        TeamMemeberList?.map((TeamMemeberListResult, index) => {
                                                                            return (
                                                                                <>
                                                                                    <div key={index} className={`nav-link ${ActiveTabs === TeamMemeberListResult?.id ?
                                                                                        "active" : ""}`}
                                                                                        onClick={() => {
                                                                                            HandleActiveTabs(TeamMemeberListResult?.id)
                                                                                            // GetUpcomingInterviewList(TeamMemeberListResult?.id)
                                                                                        }}
                                                                                        id="v-pills-home-tab" data-bs-toggle="pill"
                                                                                        data-bs-target="v-pills-home" type="button"
                                                                                        role="tab" aria-controls="v-pills-home"
                                                                                        aria-selected="true">
                                                                                        <div className="card_rec position-relative">

                                                                                            <div className="mentersvies0121">
                                                                                                <div className="position-relative">
                                                                                                    {
                                                                                                        TeamMemeberListResult?.profile_img != null ? (
                                                                                                            <>
                                                                                                                <img src={TeamMemeberListResult?.profile_img} style={{ width: "100px", height: "100px", borderRadius: "50%" }}
                                                                                                                    className="img-fluid rounded" alt="Instructor" />
                                                                                                            </>

                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <img src={CommonImageTransport?.userLogo} style={{ width: "100px", height: "100px", borderRadius: "50%" }}
                                                                                                                    className="img-fluid rounded" alt="Instructor" />
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                    {/* <span className="live_user newuser">Available</span> */}
                                                                                                </div>
                                                                                                <div className="instructors-details-contents">
                                                                                                    <div className="d-flex justify-content-between gap-2">
                                                                                                        <div className="comnam">
                                                                                                            <h3>{TeamMemeberListResult?.fname} {TeamMemeberListResult?.lname}</h3>
                                                                                                            <h6 className="sub-title">({TeamMemeberListResult?.member_role})</h6>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    <p className="perographsv">{TeamMemeberListResult?.grouplink}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="skillstopic">
                                                                                                <div>{parseEmailString(TeamMemeberListResult?.email)}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>


                                                            {/* <div className="tab-content" id="v-pills-tabContent">
                                                                <div
                                                                    className={`tab-pane fade ${ActiveTabs ? "show active" : ""}`}
                                                                    id={`v-pills-${ActiveTabs}`}
                                                                    role="tabpanel"
                                                                    aria-labelledby={`v-pills-${ActiveTabs}-tab`}>
                                                                    <div className="instructors-details-img m-0">
                                                                        <div className="instructors-details-contents mt-3">
                                                                            <div className="card-instrutors upinterviews shadow bg-white p-4">
                                                                                <h5>Upcoming Interview</h5>
                                                                                <div className="row">
                                                                                    {
                                                                                        TeamUpcomingInterviewsList?.length > 0 ? (
                                                                                            <>
                                                                                                {
                                                                                                    TeamUpcomingInterviewsList?.map((TeamUpcomingInterviewsListResult) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <div className="col-md-6 col-lg-6">
                                                                                                                    <div className="card-body inuplis border rounded">
                                                                                                                        <div className="d-flex gap-3">
                                                                                                                            <div>
                                                                                                                                {
                                                                                                                                    TeamUpcomingInterviewsListResult?.profileimg != null ? (
                                                                                                                                        <>
                                                                                                                                            <img src={TeamUpcomingInterviewsListResult?.profileimg} width="50"
                                                                                                                                                height="50" className="rounded" alt="" />
                                                                                                                                        </>

                                                                                                                                    ) : (
                                                                                                                                        <>
                                                                                                                                            <img src={CommonImageTransport?.userLogo} width="50"
                                                                                                                                                height="50" className="rounded" alt="" />
                                                                                                                                        </>
                                                                                                                                    )

                                                                                                                                }

                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <h5>{TeamUpcomingInterviewsListResult?.job?.title}</h5>
                                                                                                                                <ul className="list-group list-group-flush nav">
                                                                                                                                    <li><b>Location:</b>{TeamUpcomingInterviewsListResult?.job?.location}</li>
                                                                                                                                    <li><b>Student:</b>{TeamUpcomingInterviewsListResult?.student?.fname} {TeamUpcomingInterviewsListResult?.student?.lname}</li>
                                                                                                                                    <li><b>Interview:</b>{TeamUpcomingInterviewsListResult?.roundtype}</li>
                                                                                                                                    <li><b>Date:</b>{moment(TeamUpcomingInterviewsListResult?.scheduledat).format("DD-MM-YYYY")}</li>
                                                                                                                                    <li><b>Time:</b> {moment.utc(TeamUpcomingInterviewsListResult?.scheduledat).format("hh:mm A")} - {moment.utc(TeamUpcomingInterviewsListResult?.scheduledatto).format("hh:mm A")}</li>


                                                                                                                                </ul>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                Not Any Upcoming Interview
                                                                                            </>

                                                                                        )
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h1>Not Any Team List Here</h1>
                                                    </>
                                                )
                                            }


                                        </div>
                                    </div>
                                </div>
                </>
            }
           
            <Team_Member_Footer />
        </div>
    )
}

export default Team_Member_List