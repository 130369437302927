import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/common/header/Header";
import { SendOtp } from "../../api/Auth";
import CommonImageTransport from "../../components/common/images/CommonImages";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const SendOtpForgotPass = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const GetActiveTabs = localStorage.getItem("ActiveTabs");
  const [email, setEmail] = useState("");

//   <-------- validation parameters for all ---->
const [emailError, setEmailError] = useState("");

// const handleChange = (event) => {
//     const { value } = event.target;
//     setOrganizationEmail(value);
//     setEmailError("Please enter a valid email id");
//     validateEmail(value);
// };

  // <------------ Send Otp for the Forgot Password -------->
  const Send_Otp_Forgot_Password = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      type: "resetpassword",
      email: email,
    };
   if(!email){
    setIsLoading(false)
    toast.error("Please enter the email address")
    return;
   }
    
    try{
        const response = await SendOtp(data);
        console.log("Send_Otp_Forgot_Password", response);
        if (response.data.statusCode == "200") {
          setIsLoading(false);
              toast.success("Otp sent on your email, please check");
            navigate("/ForgotOtp");
         
          localStorage.setItem(
            "Reset_password_User_Email",
            response.data.data.email
          );
        }
        if (response.data.statusCode == "400") {
          setIsLoading(false);
          setTimeout(()=>{
              toast.error(response?.data?.msg);
          },1000)
        }
    }catch(error){
setIsLoading(false)
    }
  };
  return (
    <div className="login-signup-bg">
      <Header />
      {isLoading ? (
        <>
          <div className="chat-window text-center">
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                margin: "auto",
              }}
              className="spinner-border"
              role="status"
            >
              <span className="sr-only"></span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="user-area p-2 p-md-4 p-lg-5 pt-lg-3">
            <div className="container">
              <div className="row">
                <ToastContainer style={{ marginTop: "120px" }} />
                <div className="col-lg-6 p-0">
                  <div className="user_login">
                    <img
                      src={CommonImageTransport.loginbg}
                      className="imgk-fluid w-100"
                      alt="login-banner"
                    />
                  </div>
                </div>
                <div className="col-lg-6 d-flex p-0">
                  <div className="user-all-form userlogin position-relative">
                    <div className="login_bottom">
                      <img
                        src={CommonImageTransport.loginBottom}
                        alt="loginBottom"
                      />
                    </div>
                    <div className="contact-form">
                      <h3 className="user-title">Forgot Password</h3>
                      <p>
                        Enter your email and we'll send you a link to reset you
                        password
                      </p>
                      <form id="contactForms" className="mt-5" onSubmit={Send_Otp_Forgot_Password}>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-floating form-group">
                              <input
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="form-control"
                                id="floatingInput"
                                placeholder=""
                                // required
                              />
                              <label htmlFor="floatingInput">
                                Enter Email ID
                              </label>
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12">
                            <button
                              type="submit"
                              className="default-btn w-100 rounded"
                              
                            >
                              Get OTP
                            </button>
                            {GetActiveTabs == "student" && (
                              <h6 className="mt-4 text-center fw-normal text-muted">
                                Don’t have an account?
                                <Link className="fw-bold" to="/Student_SignUp">
                                  Create an account
                                </Link>
                              </h6>
                            )}
                            {GetActiveTabs == "Recruiter" && (
                              <h6 className="mt-4 text-center fw-normal text-muted">
                                Don’t have an account?
                                <Link
                                  className="fw-bold"
                                  to="/Recruiter_SignUp"
                                >
                                  Create an account
                                </Link>
                              </h6>
                            )}
                            {GetActiveTabs == "Mentor" && (
                              <h6 className="mt-4 text-center fw-normal text-muted">
                                Don’t have an account?
                                <Link className="fw-bold" to="/Mentor_SignUp">
                                  Create an account
                                </Link>
                              </h6>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SendOtpForgotPass;
