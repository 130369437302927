import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CommonImageTransport from "../../../common/images/CommonImages";
import Loader from "../../../../loader/Loader";
import Typewriter from "typewriter-effect";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Recruiter_Header from "../recruiter_header/Recruiter_Header";
import {
  AppliedJobsStatusUpdate,
  CheckJobRequest,
  GetRecuriterAllAppliedJobs,
  GetRecuriterTeamMemeberList,
  GetRecuriterjobAmount,
  PostTechnicalInterview,
  SubmitProfileRequest,
} from "../../../../api/Recuriter";
import ReactHtmlParser from "react-html-parser";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import moment from "moment-timezone";
import RazorpayPaymentButton from "../../../../api/payment/Razorpay_Payment_Button";
import Recruiter_Footer from "../recruiter_footer/Recruiter_Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { GetStudentResume } from "../../../../api/Student";
import { SpatialTrackingRounded } from "@mui/icons-material";
import Recuriter_Student_Resume_Modal from "../recuriter_student_resume_modal/Recuriter_Student_Resume_Modal";

const NewProfile = () => {
  const navigate = useNavigate();
  const [showRequestMoreProfile, setShowRequestMoreProfile] = useState(false);
  const [ShowStudentResumeModal, setShowStudentResumeModal] = useState(false);
  const [selectRequestMoreProfile, setSelectRequestMoreProfile] = useState("");
  const [CheckedRequestMoreProfileList, setCheckedRequestMoreProfileList] =
    useState([]);
  const [StudnetId, setStudentId] = useState("");
  const [StudentProfile, setStudentProfile] = useState([]);
  const [amount, setAmount] = useState("");
  // <------- Student Profile Show Section's -------------->
  const [StudentProfileShow, setStudentProfileShow] = useState(false);

  // <--------- Open & close the Modal section's ------------>
  const [show, setShow] = useState(false);
  const [InterviewShow, setInterviewShow] = useState(false);
  const [showSearchFields, setShowSearchFields] = useState(false);
  const [Acceptshow, setAcceptInterviewShow] = useState(false);

  // <--------- get the All Applied Profile --------------->
  const [AppliedJobList, setAppliedJobList] = useState([]);
  console.log("AppliedJobList", AppliedJobList);

  // <--------- Applied Job Id  --------------->
  const [applied_id, setApplied_id] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [ActiveTab, setActiveTab] = useState(AppliedJobList?.applied_id);
  console.log("setActiveTab_First_section's", ActiveTab);
  // <--------- Filter the Applied Student Profile ------------>
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  // <--------------- Technical Interview section's ------------------>
  const [TechScheduledFromAt, setTechScheduledFromAt] = useState("");
  const [TechScheduledToAt, setTechScheduledToAt] = useState("");
  const [TechInterviewFromDate, setTechInterviewFromDate] = useState([]);
  const [TechInterviewToDate, setTechInterviewToDate] = useState([]);
  const [TechInterviewLink, setTechInterviewLink] = useState("");
  const [TechGetInterviewer, setTechGetInterviewer] = useState([]);
  const [techInterviewSelectedOptions, setTechInterviewSelectedOptions] =
    useState([]);
  console.log("setTechGetInterviewer", TechGetInterviewer);
  console.log("setTechGetInterviewer123", techInterviewSelectedOptions);

  // <-------------- Behavirol Interview section's here ----------->
  const [BehavioralselectedOptions, setBehavioralselectedOptions] = useState(
    []
  );
  const [BehavioralInterviewFromDate, setBehavioralInterviewFromDate] =
    useState([]);
  const [BehavioralInterviewToDate, setBehavioralInterviewToDate] = useState(
    []
  );
  const [BehavioralInterviewLink, setBehavioralInterviewLink] = useState([]);
  const [BehavioralScheduledFromAt, setBehavioralScheduleFromdAt] =
    useState("");
  const [BehavioralScheduledToAt, setBehavioralScheduledToAt] = useState("");
  const [GetBehaviralInterviewer, setGetBehaviralInterviewer] = useState([]);
  console.log("GetBehaviralInterviewer", GetBehaviralInterviewer);

  const [ActiveTabData, setActiveTabData] = useState();
  const profilesPerPage = 5;
  const currentItems = filteredItems?.slice(0, currentPage + profilesPerPage);
  console.log("currentItems", currentItems);

  const TotalAmount = Math.round(amount * 100);

  // <----------- Get the Amounts from the Admin ---------------->
  const GetTheAmount = async () => {
    try {
      const response = await GetRecuriterjobAmount(headers);
      setAmount(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log("GetTheAmount", amount);

  useEffect(() => {
    GetTheAmount();
  }, []);

  // <--------- Handle the payements section's ---------------->
  // const handlePaymentSuccess = async ({
  //   paymentId,
  //   payment_status,
  //   amount,
  // }) => {
  //   console.log("Payment ID:", { paymentId, payment_status, amount });
  //   const data = {
  //     paymentId,
  //     payment_status,
  //     amount,
  //   };
  //   try {
  //     const response = await SubmitProfileRequest(data, headers);
  //     if (response?.data?.statusCode == "200") {
  //       GetStudentAppliedJobs({ paymentId, payment_status, amount });
  //       fetchAllJobs();
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  const handlePaymentSuccessWithOutPayment = async ({
    paymentId,
    payment_status,
    amount,
  }) => {
    console.log("Payment ID:", { paymentId, payment_status, amount });
    const data = {
      paymentId: "pay_PDWDTOI82z2IjS",
      payment_status: "success",
      amount: "0",
    };
    try {
      const response = await SubmitProfileRequest(data, headers);
      if (response?.data?.statusCode == "200") {
        GetStudentAppliedJobs({ paymentId, payment_status, amount });
        fetchAllJobs();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // Update filtered items when searchQuery or AppliedJobList changes
  useEffect(() => {
    const filtered = AppliedJobList?.filter(
      (item) =>
        item?.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.location?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.student_firstname
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item?.student_lastname
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item?.student_email
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item?.mobile?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.unique_id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.organization?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredItems(filtered);
  }, [AppliedJobList, searchQuery]);

  // Handle search input change
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // <---------- Open & Close Modals sections --------->
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // <--------- Authorization sections ----------->
  const token = localStorage.getItem("User_Token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // <---------- Fetch the Team Member List here ------------->
  const GetTeamMemeberList = async () => {
    try {
      const response = await GetRecuriterTeamMemeberList(headers);
      if (response?.data?.statusCode == 401) {
        localStorage.clear();
        navigate("/Signin")
        window.location.reload()
      }
      setTechGetInterviewer(response?.data?.data);
      setGetBehaviralInterviewer(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log("setTechGetInterviewer", TechGetInterviewer);

  // <--------- get the interviewr name by the id ----------------> 
  const getIdByName = (objects, ids) => {
    return ids.map((id) => {
      const obj = objects.find((obj) => obj.id === id);
      return obj ? obj.id : null; // Returns the ID if found, else null
    }).filter((id) => id !== null); // Filter out null values
  };


  // <--------- Handle the Interview scheduled ------->
  const HandleInterviewScheduled = async (e) => {
    const ids = getIdByName(TechGetInterviewer, techInterviewSelectedOptions);
    console.log("HandleInterviewScheduled", ids);
    const Behavioralids = getIdByName(
      GetBehaviralInterviewer,
      BehavioralselectedOptions
    );
    e.preventDefault();
    // Validation Checks
    if (techInterviewSelectedOptions?.length > 0) {
      // Regex to validate a typical meeting link
      const urlPattern = /^https?:\/\/([\w-]+\.)+[\w-]+(\/[\w-]*)*$/;

      if (
        !TechScheduledFromAt ||
        !TechScheduledToAt ||
        !TechInterviewLink ||
        techInterviewSelectedOptions?.length == 0
      ) {
        toast.error("Please fill all fields for the Technical interview!");
        return;
      }

      // Validate the TechInterviewLink
      if (!urlPattern.test(TechInterviewLink)) {
        toast.error("Please enter a valid interview link!");
        return;
      }
    }

    if (BehavioralselectedOptions?.length > 0) {
      // Regex to validate a typical meeting link
      const BehavioralurlPattern = /^https?:\/\/([\w-]+\.)+[\w-]+(\/[\w-]*)*$/;

      // Behavioral Interview is selected, validate all fields for behavioral interview
      if (!BehavioralScheduledFromAt || !BehavioralScheduledToAt || !BehavioralInterviewLink || BehavioralselectedOptions?.length === 0
      ) {
        toast.error("Please fill all fields for the Behavioral interview!");
        return;
      }
      // Validate the TechInterviewLink
      if (!BehavioralurlPattern.test(BehavioralInterviewLink)) {
        toast.error("Please enter a valid interview link!");
        return;
      }
    }

    // If neither section is selected, show an error
    if (techInterviewSelectedOptions?.length === 0 && BehavioralselectedOptions?.length === 0) {
      toast.error("Please select at least one interview type!");
      return;
    }

    const technicalInterview =
      techInterviewSelectedOptions?.length > 0
        ? [
          {
            interviewerID: ids,
            roundType: "Technical",
            roundNumber: 1,
            scheduledAt: TechScheduledFromAt,
            scheduledAtTo: TechScheduledToAt,
            techInterviewLink: TechInterviewLink,
          },
        ]
        : [];
    const behavioralInterview =
      BehavioralselectedOptions?.length > 0
        ? [
          {
            interviewerID: Behavioralids,
            roundType: "Behavioral",
            roundNumber: 1,
            scheduledAt: BehavioralScheduledFromAt,
            scheduledAtTo: BehavioralScheduledToAt,
            techInterviewLink: BehavioralInterviewLink,
          },
        ]
        : [];
    const PostTechnicalInterviewData = {
      job_id: ActiveTabData?.job_id,
      applied_id: ActiveTabData?.applied_id,
      student_userid: ActiveTabData?.student_userid,
      recruiter_userid: ActiveTabData?.recruiter_userid,
      technicalInterview: technicalInterview,
      behavioralInterview: behavioralInterview,
    };
    const response = await PostTechnicalInterview(
      PostTechnicalInterviewData,
      headers
    );
    if (response?.data?.statusCode === "200") {
      setInterviewShow(false);
      fetchAllJobs();
      setTimeout(() => {
        navigate("/recruiter_pages/RecruiterInterviewing");
      }, 1000);
    }
  };

  // <----------- Select the multiple Technical Interviewer ---------->
  const handleTechnicalInterviewSelectedOptions = (event) => {
    const {
      target: { value },
    } = event;
    setTechInterviewSelectedOptions(
      typeof value === "string" ? value.split(",") : value
    );
  };
  console.log("setTechInterviewSelectedOptions", techInterviewSelectedOptions)

  const handleBehavioralInterviewSelectedOptions = (event) => {
    const {
      target: { value },
    } = event;
    setBehavioralselectedOptions(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const TechnicalformatDateTimeWithFromTimeZone = (e) => {
    const selectedDateTime = e.target.value; // Value from datetime-local input
    const dateObj = moment(selectedDateTime).local(); // Treat as local date-time

    // Format for display (adjusting to the guessed timezone)
    const formattedDisplay = dateObj
      .tz(moment.tz.guess())
      .format("ddd MMM DD YYYY HH:mm:ss");
    setTechScheduledFromAt(formattedDisplay);

    // Format for input storage
    const formattedForInput = dateObj.format("YYYY-MM-DDTHH:mm:ss");
    setTechInterviewFromDate(formattedForInput);
  };

  const TechnicalformatDateTimeWithToTimeZone = (e) => {
    const selectedDateTime = e.target.value; // Value from datetime-local input
    const dateObj = moment(selectedDateTime).local(); // Treat as local date-time

    // Format for display (adjusting to the guessed timezone)
    const formattedDisplay = dateObj
      .tz(moment.tz.guess())
      .format("ddd MMM DD YYYY HH:mm:ss");
    setTechScheduledToAt(formattedDisplay);

    // Format for input storage
    const formattedForInput = dateObj.format("YYYY-MM-DDTHH:mm:ss");
    setTechInterviewToDate(formattedForInput);
  };

  const BehavioralformatDateTimeWithFromTimeZone = (e) => {
    const selectedDateTime = e.target.value;
    const dateObj = moment(selectedDateTime).local();
    const formattedDisplay = dateObj
      .tz(moment.tz.guess())
      .format("ddd MMM DD YYYY HH:mm:ss");
    setBehavioralScheduleFromdAt(formattedDisplay);
    const formattedForInput = dateObj.format("YYYY-MM-DDTHH:mm:ss");
    setBehavioralInterviewFromDate(formattedForInput);
  };

  const BehavioralformatDateTimeWithToTimeZone = (e) => {
    const selectedDateTime = e.target.value;
    const dateObj = moment(selectedDateTime).local();
    const formattedDisplay = dateObj
      .tz(moment.tz.guess())
      .format("ddd MMM DD YYYY HH:mm:ss");
    setBehavioralScheduledToAt(formattedDisplay);
    const formattedForInput = dateObj.format("YYYY-MM-DDTHH:mm:ss");
    setBehavioralInterviewToDate(formattedForInput);
  };

  // <------------- Active & UnActive Functionlity here ------------>
  const HandleActiveTab = (tabs) => {
    setActiveTab(tabs?.applied_id);
    setActiveTabData(tabs);
    console.log("setActiveTab", tabs);
  };

  // <-------- Handle the Applied id ------------>
  const HandleAppliedId = (id) => {
    setApplied_id(id);
  };

  // <-------------- Get the All Applied Job List here ---------------->
  const GetStudentAppliedJobs = async () => {
    try {
      const GetRecuriterAllAppliedJobsData = {
        status: 1,
      };
      const response = await GetRecuriterAllAppliedJobs(
        GetRecuriterAllAppliedJobsData,
        headers
      );
      return response?.data?.data || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  // <----------- Fetch the Accepted Student List here  ---------------->
  const GetAcceptedJobsProfile = async () => {
    try {
      const GetRecuriterAllAppliedJobsData = {
        status: 2,
      };
      const response = await GetRecuriterAllAppliedJobs(
        GetRecuriterAllAppliedJobsData,
        headers
      );
      return response?.data?.data || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  // <----------- Fetch the All Applied Jobs Student ---------------->
  const fetchAllJobs = async () => {
    setIsLoading(true);
    const studentAppliedJobs = await GetStudentAppliedJobs();
    const acceptedJobsProfile = await GetAcceptedJobsProfile();
    setAppliedJobList([...studentAppliedJobs, ...acceptedJobsProfile]);
    setIsLoading(false);
  };
  console.log("fetchAllJobs", AppliedJobList);

  // <----------- Fetch the All Applied Jobs Student ---------------->
  useEffect(() => {
    fetchAllJobs();
  }, []);

  // <-------- Accept the Applied jobs here ------------->
  const AcceptStudentAppliedJobs = async (e) => {
    const AcceptStudentAppliedJobsData = {
      status: 2,
      applied_id: applied_id,
    };
    try {
      const response = await AppliedJobsStatusUpdate(
        AcceptStudentAppliedJobsData,
        headers
      );
      if (response?.data?.statusCode == "200") {
        // HandleInterviewScheduled(e)
        setAcceptInterviewShow(false);
        fetchAllJobs();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // <-------- Accept the Applied jobs here ------------->
  const RejectStudentAppliedJobs = async () => {
    const AcceptStudentAppliedJobsData = {
      status: "0",
      applied_id: applied_id,
    };
    const response = await AppliedJobsStatusUpdate(
      AcceptStudentAppliedJobsData,
      headers
    );
    if (response?.data?.statusCode == "200") {
      fetchAllJobs();
      handleClose();
    }
  };

  // <--------- Get the Student Applied Jobs sections ----------->
  useEffect(() => {
    GetTeamMemeberList();
  }, []);

  // <--------- This UseEffect for Loader Pages-------->
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2200);
    return () => clearTimeout(timeout);
  }, []);

  // <---------- Show the bydefault First Jobs  --------->
  useEffect(() => {
    if (AppliedJobList?.length > 0) {
      const firstActiveTab = AppliedJobList?.find((job) => job.status == 1);
      if (firstActiveTab) {
        setActiveTab(firstActiveTab?.applied_id);
        setActiveTabData(firstActiveTab);
      }
    }
  }, [AppliedJobList]);

  const GetStudentDetailsById = (student_userid) => {
    setStudentId(student_userid);
    GetStudentProfile(student_userid);
    setShowStudentResumeModal(true);
  };

  //    <---------- Get the Student Profile here ------------>
  const GetStudentProfile = async (StudnetId) => {
    const StudentResumeData = {
      id: StudnetId,
    };
    console.log("StudentResumeData", StudentResumeData);
    const response = await GetStudentResume(StudentResumeData, headers);
    setStudentProfile(response?.data?.data);
  };
  console.log("hellsald", StudentProfile);
  const [triggerPayment, setTriggerPayment] = useState(false);
  // <------------ check-job-request ---------------->
  const HandleCheckJobRequest = async () => {
    const data = {
      status: 1,
      limit: selectRequestMoreProfile,
    };

    try {
      const response = await CheckJobRequest(data, headers);
      if (response?.data?.statusCode == "200") {
        const fetchedProfiles = response?.data?.data; // Use response directly
        setCheckedRequestMoreProfileList(fetchedProfiles); // Update state for other use cases

        if (fetchedProfiles?.length >= selectRequestMoreProfile) {
          console.log("Fetched Profiles List", fetchedProfiles); // Correct length here
          if (amount == "0") {
            handlePaymentSuccessWithOutPayment({
              paymentId: "pay_PDWDTOI82z2IjS",
              payment_status: "success",
              amount: "0",
            });
          } else {
            setTriggerPayment(true);
            setShowRequestMoreProfile(false);
          }
        } else {
          alert(
            `${`Only ${fetchedProfiles?.length} applied profile(s) are currently available.`}`
          );
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // console.log("setCheckedRequestMoreProfileList", CheckedRequestMoreProfileList)
  const handleShowStudentResume = () => {
    setShowStudentResumeModal(true);
  };

  const handleCloseStudentResume = () => {
    setShowStudentResumeModal(false);
  };
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="bg_nav-slid bg-white">
            <Recruiter_Header />
          </div>

          <div className="recruiter_list pb-5 pt-4">
            <div className="container-fluid">
              <ToastContainer style={{ marginTop: "120px" }} />
              <div className="row">
                <div className="col-12">
                  <ul className="nav tabs_signup justify-content-start gap-2 mb-3">
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        aria-current="page"
                        to="/recruiter_pages/NewProfile"
                      >
                        New Profiles
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/recruiter_pages/RecruiterInterviewing"
                      >
                        Interviewing
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/recruiter_pages/RecruiterList"
                      >
                        Hired
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/recruiter_pages/Recuriter_Rejected"
                      >
                        Rejected
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/recruiter_pages/AllProfile"
                      >
                        All
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-md-8 col-lg-9">
                  <form>
                    <div className="searchlocation">
                      <div className="iconfield">
                        <div className="icon_search">
                          <img src={CommonImageTransport?.searchicon} alt="" />
                        </div>
                        {!showSearchFields ? (
                          <div onClick={() => setShowSearchFields(true)}>
                            <Typewriter
                              options={{
                                loop: true,
                              }}
                              onInit={(typewriter) => {
                                typewriter
                                  .typeString("Search by title")
                                  .callFunction(() => { })
                                  .pauseFor(2500)
                                  .deleteAll()
                                  .callFunction(() => { })
                                  .typeString("Search by location")
                                  .pauseFor(2500)
                                  .start();
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <input
                              type="text"
                              onChange={handleSearchInputChange}
                              placeholder=""
                              style={{ border: "none", outline: "none" }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="iconfield">
                        <div className="icon_search">
                          <img
                            src={CommonImageTransport?.locationicon}
                            alt=""
                          />
                        </div>
                        <div className="input-field">
                          <input
                            type="text"
                            onChange={handleSearchInputChange}
                            placeholder="USA, Florida"
                            className="form-control"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="input-field ad121">
                        <button
                          type="submit"
                          onClick={handleSearchInputChange}
                          className="submit_btn"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-4 col-lg-3 payment_models my-3 pt-1 text-end">
                  {/*                  
                        {
                          amount == "0" ? (
                            <button
                              className='btn default-btn col-md-12'
                              onClick={handlePaymentSuccessWithOutPayment}
                            >
                              Request More Profiles
                            </button>
                          ) : (
                            <RazorpayPaymentButton
                              Price={TotalAmount}
                              onPaymentSuccess={handlePaymentSuccess}
                              buttonContent="Request More Profiles"
                            />
                          )
                        } */}
                  {/* {
                    triggerPayment && (
                      <RazorpayPaymentButton
                        Price={TotalAmount}
                        onPaymentSuccess={handlePaymentSuccess}
                        triggerPayment={true}  // This will trigger the payment flow
                      />
                    )
                  } */}

                  {/* <---------- New Request More Profile -----------> */}
                  {/* {
                    !triggerPayment && (
                      <button
                        className='btn default-btn col-md-12'
                        onClick={() => setShowRequestMoreProfile(true)}
                      >
                        Request More Profiles
                      </button>
                    )
                  } */}
                </div>

                <div className="col-12">
                  <div className="left_recruiterlist">
                    <div className="sticky-header">
                      <div
                        className="nav flex-column recruiter_all_lists nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        {currentItems?.length > 0 ? (
                          <>
                            {currentItems?.map(
                              (AppliedJobListResult, index) => {
                                if (AppliedJobListResult?.status == "0") {
                                  return null;
                                }
                                return (
                                  <>
                                    <div
                                      key={index}
                                      className={`nav-link ${ActiveTab ==
                                          AppliedJobListResult?.applied_id
                                          ? "active"
                                          : ""
                                        }`}
                                      onClick={() => {
                                        HandleActiveTab(AppliedJobListResult);
                                        HandleAppliedId(
                                          AppliedJobListResult?.applied_id
                                        );
                                      }}
                                      id="v-pills-home-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="v-pills-home"
                                      type="button"
                                      role="tab"
                                      aria-controls="v-pills-home"
                                      aria-selected="true"
                                    >
                                      <div className="card_rec position-relative">
                                        <div className="mentersvies0121">
                                          <div className="position-relative">
                                            {AppliedJobListResult?.student_profileimg !=
                                              null ? (
                                              <>
                                                <img
                                                  src={
                                                    AppliedJobListResult?.student_profileimg
                                                  }
                                                  className="img-fluid rounded"
                                                  alt="Instructor"
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src={
                                                    CommonImageTransport.userLogo
                                                  }
                                                  className="img-fluid rounded"
                                                  alt="Instructor"
                                                />
                                              </>
                                            )}

                                            {/* <span className="live_user">Available</span> */}
                                          </div>
                                          <div className="instructors-details-contents">
                                            <div className="d-flex justify-content-between gap-2">
                                              <div className="comnam">
                                                <h3>
                                                  {
                                                    AppliedJobListResult?.student_firstname
                                                  }{" "}
                                                  {
                                                    AppliedJobListResult?.student_lastname
                                                  }
                                                </h3>

                                                <h6 className="sub-title">
                                                  {
                                                    AppliedJobListResult?.student_email
                                                  }
                                                </h6>
                                                <h6 className="sub-title">
                                                  {
                                                    AppliedJobListResult?.student_title
                                                  }
                                                </h6>
                                              </div>
                                            </div>
                                            <p className="perographsv">
                                              {
                                                AppliedJobListResult?.organization
                                              }
                                            </p>
                                            <p className="perographsv">
                                              <strong>Job Title:</strong>
                                              {AppliedJobListResult?.title}
                                            </p>
                                            <p className="perographsv">
                                              <strong>Job Id:</strong>
                                              {AppliedJobListResult?.unique_id}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between">

                                          <div>
                                            {/* Trigger Button */}
                                            <div
                                              className="btn btn-primary"
                                              onClick={() =>
                                                GetStudentDetailsById(
                                                  AppliedJobListResult?.student_userid
                                                )
                                              }
                                            >
                                              View Resume
                                            </div>

                                            {/* {AppliedJobListResult?.student_userid && (
                                              <Recuriter_Student_Resume_Modal
                                                student_userid={AppliedJobListResult?.student_userid}
                                                show={ShowStudentResumeModal}
                                                onHide={handleCloseStudentResume}
                                              />
                                            )} */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <div className="mt-5 text-center">
                            <h3>No more profiles are available at this time</h3>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <------------- */}
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className={`tab-pane fade ${ActiveTab ? "show active" : ""
                          }`}
                        id={`v-pills-${ActiveTab}`}
                        role="tabpanel"
                        aria-labelledby={`v-pills-${ActiveTab}-tab`}
                      >
                        <div className="instructors-details-img m-0">
                          <div className="card-instrutors shadow position-relative bg-white p-4">
                            {AppliedJobList?.find(
                              (job) => job.applied_id === ActiveTab
                            )?.applied_status === 1 && (
                                <>
                                  <p
                                    className="rejectbtn"
                                    onClick={() => {
                                      handleShow();
                                      HandleAppliedId(
                                        AppliedJobList?.find(
                                          (job) => job.applied_id === ActiveTab
                                        )?.applied_id
                                      );
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal3"
                                  >
                                    Reject
                                  </p>
                                  <p
                                    className="btn btn-primary rejectbtn1"
                                    onClick={() => {
                                      HandleAppliedId(
                                        AppliedJobList?.find(
                                          (job) => job.applied_id === ActiveTab
                                        )?.applied_id
                                      );
                                      setAcceptInterviewShow(true);
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal3"
                                  >
                                    Accept
                                  </p>
                                </>
                              )}

                            {AppliedJobList?.find(
                              (job) => job.applied_id === ActiveTab
                            )?.applied_status === 2 && (
                                <>
                                  <p
                                    className="rejectbtn btn btn-primary"
                                    onClick={() => setInterviewShow(true)}
                                  >
                                    Arrange Interview
                                  </p>
                                  <p
                                    className="btn btn-success rejectbtn2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal3"
                                  >
                                    Accepted
                                  </p>
                                </>
                              )}
                            <div className="mentersvies0121">
                              <div className="position-relative menter_pro">
                                {AppliedJobList?.find(
                                  (job) => job.applied_id === ActiveTab
                                )?.student_profileimg ? (
                                  <>
                                    <img
                                      src={
                                        AppliedJobList?.find(
                                          (job) => job.applied_id === ActiveTab
                                        )?.student_profileimg
                                      }
                                      className="rounded_01top"
                                      alt="Instructor"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={CommonImageTransport.userLogo}
                                      className="rounded_01top"
                                      alt="Instructor"
                                    />
                                  </>
                                )}

                                {/* <span className="live_user newuser">Available</span> */}
                              </div>

                              <div className="instructors-details-contents mt-3">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <h3>
                                      {
                                        AppliedJobList?.find(
                                          (job) => job.applied_id === ActiveTab
                                        )?.student_firstname
                                      }{" "}
                                      {
                                        AppliedJobList?.find(
                                          (job) => job.applied_id === ActiveTab
                                        )?.student_lastname
                                      }
                                    </h3>
                                    <h6 className="sub-title mb-2">
                                      {
                                        AppliedJobList?.find(
                                          (job) => job.applied_id === ActiveTab
                                        )?.student_title
                                      }
                                    </h6>
                                  </div>
                                </div>
                                <p>
                                  {
                                    AppliedJobList?.find(
                                      (job) => job?.applied_id === ActiveTab
                                    )?.organization
                                  }
                                </p>
                                <h6 className="sub-title mb-2">
                                  <strong>Job Title:</strong>
                                  {
                                    AppliedJobList?.find(
                                      (job) => job.applied_id === ActiveTab
                                    )?.title
                                  }
                                </h6>
                                <h6 className="sub-title mb-2">
                                  <strong>Job Id:</strong>
                                  {
                                    AppliedJobList?.find(
                                      (job) => job.applied_id === ActiveTab
                                    )?.unique_id
                                  }
                                </h6>
                                  <div>
                                    {AppliedJobList?.find(
                                      (job) => job.applied_id === ActiveTab
                                    )?.skills
                                      ?.replace(/[{}"\\]/g, "")
                                      ?.split(/,\s*/)
                                      ?.map((skill, index, array) => (
                                        <span key={index}>
                                          {skill.trim()}
                                          {index < array.length - 1 &&
                                            ", "}
                                        </span>
                                      ))}
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <------------ Request More Profiles -------------> */}
          <Modal
            show={showRequestMoreProfile}
            onHide={() => setShowRequestMoreProfile(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Request more profiles</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="col-12 col-md-12">
                  <div className="form-group mb-2 myteamform">
                    <h6>No of request more profile</h6>
                    <div className="datetime-timezone-input form-group">
                      <select
                        id="cars"
                        className="form-control datetime-input"
                        name="limit"
                        onChange={(e) =>
                          setSelectRequestMoreProfile(e.target.value)
                        }
                      >
                        <option value="">
                          Select the no of request more profile
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 mt-3">
                  <div className="form-group mb-2 myteamform">
                    <h6>Training program</h6>
                    <div className="datetime-timezone-input form-group">
                      <input
                        type="text"
                        name="TechScheduledFromAt"
                        placeholder="Training program"
                        className="form-control datetime-input"
                        required
                      />
                    </div>
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn default-btn"
                onClick={() => setShowRequestMoreProfile(false)}
              >
                Close
              </Button>
              <Button
                className="btn default-btn"
                onClick={HandleCheckJobRequest}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* <---------- Arrange Interview Modal ------------> */}
          <Modal
            size="lg"
            show={InterviewShow}
            onHide={() => setInterviewShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Interview
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setInterviewShow(false)}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form method="post">
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="form-group mb-2 myteamform">
                      <h6>Select Technical Interviewer</h6>
                      <FormControl sx={{ m: 1, width: 750 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          Select Interviewer
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={techInterviewSelectedOptions}
                          onChange={handleTechnicalInterviewSelectedOptions}
                          input={<OutlinedInput label="Select Interviewer" />}
                          renderValue={(selected) =>
                            selected
                              .map((id) => {
                                const interviewer = TechGetInterviewer.find(
                                  (i) => i.id === id
                                );
                                return `${interviewer.fname} ${interviewer.lname}`;
                              })
                              .join(", ")
                          }
                        >
                          {TechGetInterviewer?.map((interviewer) => (
                            <MenuItem
                              key={interviewer.id}
                              value={interviewer.id} // Use ID as the value
                            >
                              <Checkbox
                                checked={
                                  techInterviewSelectedOptions.indexOf(
                                    interviewer.id
                                  ) > -1
                                }
                              />
                              <ListItemText
                                primary={`${interviewer.fname} ${interviewer.lname}`}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="col-6 col-md-6">
                    <div className="form-group mb-2 myteamform">
                      <h6>Interview From</h6>
                      <div className="datetime-timezone-input form-group">
                        <input
                          type="datetime-local"
                          name="TechScheduledFromAt"
                          placeholder="date"
                          className="form-control datetime-input"
                          required
                          value={TechInterviewFromDate}
                          onChange={TechnicalformatDateTimeWithFromTimeZone}
                          min={moment().format("YYYY-MM-DDTHH:mm")} // Current date and time in local format
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-md-6">
                    <div className="form-group mb-2 myteamform">
                      <h6>Interview To</h6>
                      <div className="datetime-timezone-input form-group">
                        <input
                          type="datetime-local"
                          name="TechScheduledToAt"
                          placeholder="date"
                          className="form-control datetime-input"
                          required
                          value={TechInterviewToDate}
                          onChange={TechnicalformatDateTimeWithToTimeZone}
                          min={moment().format("YYYY-MM-DDTHH:mm")} // Current date and time in local format
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-12">
                    <div className="form-group mb-2 myteamform">
                      <h6>Interview Link</h6>
                      <input
                        type="text"
                        name="TechInterviewLink"
                        value={TechInterviewLink}
                        onChange={(e) => setTechInterviewLink(e.target.value)}
                        placeholder="https://meet.google.com/odp-nvra-ycf"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="form-group mb-2 myteamform">
                      <h6>
                        {" "}
                        <h6>Select Behaviourl Interviewer</h6>
                      </h6>
                      <FormControl sx={{ m: 1, width: 750 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          Select Interviewer
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={BehavioralselectedOptions}
                          onChange={handleBehavioralInterviewSelectedOptions}
                          input={<OutlinedInput label="Select Interviewer" />}
                          renderValue={(selected) =>
                            selected
                              .map((id) => {
                                const interviewer =
                                  GetBehaviralInterviewer.find(
                                    (i) => i.id === id
                                  );
                                return `${interviewer.fname} ${interviewer.lname}`;
                              })
                              .join(", ")
                          }
                        >
                          {GetBehaviralInterviewer?.map((interviewer) => (
                            <MenuItem
                              key={interviewer.id}
                              value={interviewer.id} // Use ID as the value
                            >
                              <Checkbox
                                checked={
                                  BehavioralselectedOptions.indexOf(
                                    interviewer.id
                                  ) > -1
                                }
                              />
                              <ListItemText
                                primary={`${interviewer.fname} ${interviewer.lname}`}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="col-6 col-md-6">
                    <div className="form-group mb-2 myteamform">
                      <h6>Interview From</h6>
                      <div className="datetime-timezone-input form-group">
                        <input
                          type="datetime-local"
                          name="BehavioralScheduledAt"
                          placeholder="date"
                          className="form-control datetime-input"
                          required
                          value={BehavioralInterviewFromDate}
                          onChange={BehavioralformatDateTimeWithFromTimeZone}
                          min={moment().format("YYYY-MM-DDTHH:mm")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-md-6">
                    <div className="form-group mb-2 myteamform">
                      <h6>Interview To</h6>
                      <div className="datetime-timezone-input form-group">
                        <input
                          type="datetime-local"
                          name="BehavioralScheduledAt"
                          placeholder="date"
                          className="form-control datetime-input"
                          required
                          value={BehavioralInterviewToDate}
                          onChange={BehavioralformatDateTimeWithToTimeZone}
                          min={moment().format("YYYY-MM-DDTHH:mm")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-12">
                    <div className="form-group mb-2 myteamform">
                      <h6>Interview Link</h6>
                      <input
                        type="text"
                        name="BehavioralInterviewLink"
                        value={BehavioralInterviewLink}
                        onChange={(e) =>
                          setBehavioralInterviewLink(e.target.value)
                        }
                        placeholder="https://meet.google.com/odp-nvra-ycf"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="dd-footer d-flex gap-3 p-3 border-top">
                <button
                  type="button"
                  className="btn btn-secondary w-100"
                  onClick={() => setInterviewShow(false)}
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    HandleInterviewScheduled(e);
                  }}
                  className="btn default-btn w-100"
                >
                  Submit
                </button>
              </div>
            </form>
          </Modal>

          {/* <------- reject Modal here ---------> */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Post Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are You Sure !!!</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  RejectStudentAppliedJobs();
                }}
              >
                Reject
              </Button>
            </Modal.Footer>
          </Modal>

          {/* <------- Accept Modal here ---------> */}
          <Modal show={Acceptshow} onHide={() => setAcceptInterviewShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Post Accept</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are You Sure !!!</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setAcceptInterviewShow(false)}
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => AcceptStudentAppliedJobs()}
              >
                Accept
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {/* <------ Student Profile section's -------------> */}
      <Modal
        size="xl"
        show={StudentProfileShow}
        onHide={() => setStudentProfileShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Student Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="resumefile">
            <page size="A4">
              <div class="d-flex">
                <div class="leftPanel">
                  {StudentProfile?.student_info?.profileimg != null ? (
                    <>
                      <img src={StudentProfile?.student_info?.profileimg} />
                    </>
                  ) : (
                    <>
                      <img src={CommonImageTransport?.userLogo} />
                    </>
                  )}
                  <div class="details">
                    <div class="item bottomLineSeparator">
                      <div class="smallText">
                        <p>
                          <i
                            class="fa fa-phone contactIcon"
                            aria-hidden="true"
                          ></i>
                          <p>
                            {StudentProfile?.student_info?.fname}{" "}
                            {StudentProfile?.student_info?.lname}
                          </p>
                        </p>
                      </div>

                      <div class="smallText">
                        <p>
                          <i
                            class="fa fa-phone contactIcon"
                            aria-hidden="true"
                          ></i>{" "}
                          {StudentProfile?.student_info?.mobile}
                        </p>
                        <p>
                          <i
                            class="fa fa-envelope contactIcon"
                            aria-hidden="true"
                          ></i>
                          <a href="mailto:lorem@ipsum.com@gmail.com">
                            {StudentProfile?.student_info?.email}
                          </a>
                        </p>
                        <p>
                          <i
                            class="fa fa-map-marker contactIcon"
                            aria-hidden="true"
                          ></i>
                          {StudentProfile?.student_info?.city},{" "}
                          {StudentProfile?.student_info?.state},{" "}
                          {StudentProfile?.student_info?.country}
                        </p>
                      </div>
                    </div>
                    <div class="item bottomLineSeparator">
                      <h2>SKILLS</h2>
                      <div class="smallText">
                        {StudentProfile?.student_skills?.map(
                          (StudentSkillsResult) => {
                            return (
                              <>
                                <div class="skill">
                                  <div>
                                    <span>{StudentSkillsResult?.skill}</span>
                                  </div>
                                  <div class="yearsOfExperience">
                                    <span class="alignright">
                                      {StudentSkillsResult?.expertise}
                                    </span>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div class="item">
                      <h2>EDUCATION</h2>
                      <div class="smallText">
                        {StudentProfile?.student_educations?.map(
                          (StudentEdutionResult) => {
                            return (
                              <>
                                <div class="smallText">
                                  <p class="bolded white">
                                    {StudentEdutionResult?.degreetype}
                                  </p>
                                  <p class="bolded white">
                                    {StudentEdutionResult?.degreename}
                                  </p>
                                  <p>{StudentEdutionResult?.university}</p>
                                  <p>
                                    {moment
                                      .utc(StudentEdutionResult?.startdate)
                                      .format("MM/YYYY")}{" "}
                                    -{" "}
                                    {moment
                                      .utc(StudentEdutionResult?.enddate)
                                      .format("MM/YYYY")}
                                  </p>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rightPanel pt-0">
                  <div class="workExperience">
                    <h4>Work Experience</h4>
                    <ul>
                      {StudentProfile?.st_experience?.map(
                        (StudentProfessnalExp, index) => {
                          return (
                            <>
                              <li>
                                <div class="jobPosition">
                                  <span class="bolded">
                                    <h3>
                                      {StudentProfessnalExp?.p_title}{" "}
                                      <span>
                                        ({StudentProfessnalExp?.emp_type})
                                      </span>
                                    </h3>
                                    <h6>{StudentProfessnalExp?.company} </h6>
                                  </span>

                                  {StudentProfessnalExp?.start_date && (
                                    <small className="fw-normal small">
                                      {moment
                                        .utc(StudentProfessnalExp?.start_date)
                                        .format("MM/DD/YYYY")}{" "}
                                      -{" "}
                                      <>
                                        {StudentProfessnalExp?.end_date !=
                                          null && (
                                            <>
                                              {moment
                                                .utc(
                                                  StudentProfessnalExp?.end_date
                                                )
                                                .format("MM/DD/YYYY")}
                                            </>
                                          )}
                                      </>
                                    </small>
                                  )}
                                </div>
                                <p>{StudentProfessnalExp?.job_desc} </p>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <div className="agree-label d-flex gap-2">
                                      {StudentProfessnalExp?.currently_working ==
                                        1 && (
                                          <>
                                            <input
                                              type="checkbox"
                                              name="currently_working"
                                              checked={
                                                StudentProfessnalExp?.currently_working ===
                                                1
                                              }
                                              id={`chb${index}`}
                                              className="termcheck"
                                              readOnly
                                            />
                                            <label
                                              htmlFor={`chb${index}`}
                                              className="small text-muted"
                                            >
                                              I am currently working in this role
                                            </label>
                                          </>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </>
                          );
                        }
                      )}
                    </ul>
                  </div>
                  <div class="workExperience">
                    <h4>Projects</h4>
                    <ul>
                      {StudentProfile?.university_projects?.map(
                        (StudentWorkExpResult) => {
                          return (
                            <>
                              <li>
                                <div class="jobPosition">
                                  <span class="bolded">
                                    {StudentWorkExpResult?.title}
                                  </span>
                                </div>
                                <div>
                                  {StudentWorkExpResult?.websitelink && (
                                    <a
                                      href={StudentWorkExpResult.websitelink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {StudentWorkExpResult.websitelink}
                                    </a>
                                  )}
                                </div>

                                <div class="smallText">
                                  <p>{StudentWorkExpResult?.yourrole}</p>
                                </div>
                              </li>
                            </>
                          );
                        }
                      )}
                    </ul>
                  </div>
                  <div class="workExperience">
                    <h4>Certificate</h4>
                    <ul>
                      {StudentProfile?.student_certification?.length > 0 && (
                        <>
                          {StudentProfile?.student_certification?.map(
                            (StudentCertificateResult) => {
                              return (
                                <>
                                  <li>
                                    <div class="jobPosition">
                                      <span class="bolded">
                                        {StudentCertificateResult?.certificate}
                                      </span>
                                      <span>
                                        <small className="fw-normal small">
                                          {moment
                                            .utc(
                                              StudentCertificateResult?.certification_date
                                            )
                                            .format("MM/DD/YYYY")}
                                        </small>
                                      </span>
                                    </div>
                                    <div class="projectName">
                                      <p>
                                        {StudentCertificateResult?.organication}
                                      </p>
                                    </div>
                                  </li>
                                </>
                              );
                            }
                          )}
                        </>
                      )}
                    </ul>
                  </div>
                  <div class="workExperience">
                    <h4>Training Program</h4>
                    <ul>
                      {StudentProfile?.complete_course?.length > 0 && (
                        <>
                          {StudentProfile?.complete_course?.map(
                            (StudentCompleteCourseResult) => {
                              return (
                                <>
                                  <li>
                                    <div class="jobPosition">
                                      <span class="bolded">
                                        {StudentCompleteCourseResult?.title}
                                      </span>
                                    </div>
                                    <div class="projectName">
                                      <p>
                                        {StudentCompleteCourseResult?.duration}
                                      </p>
                                    </div>
                                    <div class="projectName">
                                      <p>
                                        {
                                          StudentCompleteCourseResult?.description
                                        }
                                      </p>
                                    </div>
                                  </li>
                                </>
                              );
                            }
                          )}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </page>
          </div>
        </Modal.Body>
      </Modal>

      {/* <---------- Student Resume section's ------------> */}
      <Modal
        size="xl"
        show={ShowStudentResumeModal}
        onHide={() => setShowStudentResumeModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Student Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="resumefile">
            <page size="A4">
              <div class="d-flex">
                <div class="leftPanel">
                  {StudentProfile?.student_info?.profileimg != null ? (
                    <>
                      <img src={StudentProfile?.student_info?.profileimg} />
                    </>
                  ) : (
                    <>
                      <img src={CommonImageTransport?.userLogo} />
                    </>
                  )}
                  <div class="details">
                    <div class="item bottomLineSeparator">
                      <div class="smallText">
                        <p>
                          <i
                            class="fa fa-phone contactIcon"
                            aria-hidden="true"
                          ></i>
                          <p>
                            {StudentProfile?.student_info?.fname}{" "}
                            {StudentProfile?.student_info?.lname}
                          </p>
                        </p>
                      </div>

                      <div class="smallText">
                        <p>
                          <i
                            class="fa fa-phone contactIcon"
                            aria-hidden="true"
                          ></i>{" "}
                          {StudentProfile?.student_info?.mobile}
                        </p>
                        <p>
                          <i
                            class="fa fa-envelope contactIcon"
                            aria-hidden="true"
                          ></i>
                          <a href="mailto:lorem@ipsum.com@gmail.com">
                            {StudentProfile?.student_info?.email}
                          </a>
                        </p>
                        <p>
                          <i
                            class="fa fa-map-marker contactIcon"
                            aria-hidden="true"
                          ></i>
                          {StudentProfile?.student_info?.city},{" "}
                          {StudentProfile?.student_info?.state},{" "}
                          {StudentProfile?.student_info?.country}
                        </p>
                      </div>
                    </div>
                    <div class="item bottomLineSeparator">
                      <h2>SKILLS</h2>
                      <div class="smallText">
                        {StudentProfile?.student_skills?.map(
                          (StudentSkillsResult) => {
                            return (
                              <>
                                <div class="skill">
                                  <div>
                                    <span>{StudentSkillsResult?.skill}</span>
                                  </div>
                                  <div class="yearsOfExperience">
                                    <span class="alignright">
                                      {StudentSkillsResult?.expertise}
                                    </span>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div class="item">
                      <h2>EDUCATION</h2>
                      <div class="smallText">
                        {StudentProfile?.student_educations?.map(
                          (StudentEdutionResult) => {
                            return (
                              <>
                                <div class="smallText">
                                  <p class="bolded white">
                                    {StudentEdutionResult?.degreetype}
                                  </p>
                                  <p class="bolded white">
                                    {StudentEdutionResult?.degreename}
                                  </p>
                                  <p>{StudentEdutionResult?.university}</p>
                                  <p>
                                    {moment
                                      .utc(StudentEdutionResult?.startdate)
                                      .format("MM/YYYY")}{" "}
                                    -{" "}
                                    {moment
                                      .utc(StudentEdutionResult?.enddate)
                                      .format("MM/YYYY")}
                                  </p>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rightPanel pt-0">
                  <div class="workExperience">
                    <h4>Work Experience</h4>
                    <ul>
                      {StudentProfile?.st_experience?.map(
                        (StudentProfessnalExp, index) => {
                          return (
                            <>
                              <li>
                                <div class="jobPosition">
                                  <span class="bolded">
                                    <h3>
                                      {StudentProfessnalExp?.p_title}{" "}
                                      <span>
                                        ({StudentProfessnalExp?.emp_type})
                                      </span>
                                    </h3>
                                    <h6>{StudentProfessnalExp?.company} </h6>
                                  </span>

                                  {StudentProfessnalExp?.start_date && (
                                    <small className="fw-normal small">
                                      {moment
                                        .utc(StudentProfessnalExp?.start_date)
                                        .format("MM/DD/YYYY")}{" "}
                                      -{" "}
                                      <>
                                        {StudentProfessnalExp?.end_date !=
                                          null && (
                                            <>
                                              {moment
                                                .utc(
                                                  StudentProfessnalExp?.end_date
                                                )
                                                .format("MM/DD/YYYY")}
                                            </>
                                          )}
                                      </>
                                    </small>
                                  )}
                                </div>
                                <p>{StudentProfessnalExp?.job_desc} </p>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <div className="agree-label d-flex gap-2">
                                      {StudentProfessnalExp?.currently_working ==
                                        1 && (
                                          <>
                                            <input
                                              type="checkbox"
                                              name="currently_working"
                                              checked={
                                                StudentProfessnalExp?.currently_working ===
                                                1
                                              }
                                              id={`chb${index}`}
                                              className="termcheck"
                                              readOnly
                                            />
                                            <label
                                              htmlFor={`chb${index}`}
                                              className="small text-muted"
                                            >
                                              I am currently working in this role
                                            </label>
                                          </>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </>
                          );
                        }
                      )}
                    </ul>
                  </div>
                  <div class="workExperience">
                    <h4>Projects</h4>
                    <ul>
                      {StudentProfile?.university_projects?.map(
                        (StudentWorkExpResult) => {
                          return (
                            <>
                              <li>
                                <div class="jobPosition">
                                  <span class="bolded">
                                    {StudentWorkExpResult?.title}
                                  </span>
                                </div>
                                <div>
                                  {StudentWorkExpResult?.websitelink && (
                                    <a
                                      href={StudentWorkExpResult.websitelink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {StudentWorkExpResult.websitelink}
                                    </a>
                                  )}
                                </div>

                                <div class="smallText">
                                  <p>{StudentWorkExpResult?.yourrole}</p>
                                </div>
                              </li>
                            </>
                          );
                        }
                      )}
                    </ul>
                  </div>
                  <div class="workExperience">
                    <h4>Certificate</h4>
                    <ul>
                      {StudentProfile?.student_certification?.length > 0 && (
                        <>
                          {StudentProfile?.student_certification?.map(
                            (StudentCertificateResult) => {
                              return (
                                <>
                                  <li>
                                    <div class="jobPosition">
                                      <span class="bolded">
                                        {StudentCertificateResult?.certificate}
                                      </span>
                                      <span>
                                        <small className="fw-normal small">
                                          {moment
                                            .utc(
                                              StudentCertificateResult?.certification_date
                                            )
                                            .format("MM/DD/YYYY")}
                                        </small>
                                      </span>
                                    </div>
                                    <div class="projectName">
                                      <p>
                                        {StudentCertificateResult?.organication}
                                      </p>
                                    </div>
                                  </li>
                                </>
                              );
                            }
                          )}
                        </>
                      )}
                    </ul>
                  </div>
                  <div class="workExperience">
                    <h4>Training Program</h4>
                    <ul>
                      {StudentProfile?.complete_course?.length > 0 && (
                        <>
                          {StudentProfile?.complete_course?.map(
                            (StudentCompleteCourseResult) => {
                              return (
                                <>
                                  <li>
                                    <div class="jobPosition">
                                      <span class="bolded">
                                        {StudentCompleteCourseResult?.title}
                                      </span>
                                    </div>
                                    <div class="projectName">
                                      <p>
                                        {StudentCompleteCourseResult?.duration}
                                      </p>
                                    </div>
                                    <div class="projectName">
                                      <p>
                                        {
                                          StudentCompleteCourseResult?.description
                                        }
                                      </p>
                                    </div>
                                  </li>
                                </>
                              );
                            }
                          )}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </page>
          </div>
        </Modal.Body>
      </Modal>

      <Recruiter_Footer />
    </div>
  );
};

export default NewProfile;
