import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import moment from 'moment-timezone';
import CommonImageTransport from '../../../common/images/CommonImages';
import { Team_Email_Check, Team_Send_Otp } from '../../../../api/Auth';

const Team_Member_Login = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false)
    const [rememberMe, setRememberMe] = useState(false);

    // <------- Set the Time Zone here ------------->
    const DateDataFirst = new Date();
    const timeZone = moment.tz.guess();
    const timeZoneAbbr = moment.tz(DateDataFirst, timeZone).format('z');
    console.log("timeZoneAbbr", timeZoneAbbr)

    // <------ get the Active Tabs from the Localstorage ------------->
    const GetActiveTabs = localStorage.getItem("ActiveTabs");

    const handleUsernameSubmit = async (e) => {
        const enteredEmail = e.target.value;
        setEmail(enteredEmail);
        try {
            const response = await Team_Email_Check({ email: enteredEmail })
            console.log("email_check_respone", response)
            if (response.data.data.is_exists === true) {
                setShowPasswordField(true);
                setError('');

            } else if (response.data.data.is_exists === false) {
                setShowPasswordField(false);
                setError(response.data?.msg);
            }
        } catch (err) {
            setError(err.response?.data.message || err.message || 'An error occurred');
        }
    };

    const LoginApi = async (e) => {
        e.preventDefault()
        setIsLoading(true);
        try {
            if (!email || !password) {
                setError('Please enter email and password.');
                setIsLoading(false);
                return;
            }
            const data = {
                type: "login",
                email: email,
                password: password,
                 time_zone:"utc"
            };
            const response = await Team_Send_Otp(data);
            console.log("response", response?.data?.msg);
            if (response.data.statusCode === "200") {
                toast.success("Otp sent to your email, please check");
                setEmail('');
                setPassword('');
                localStorage.setItem("user_Email", response.data.data.email);
                localStorage.setItem("user_Login_Data", JSON.stringify(data));
                // if (rememberMe) {
                //     localStorage.setItem('rememberedUser', JSON.stringify({ email, password }));
                // } else {
                //     localStorage.removeItem('rememberedUser');
                // }
                navigate("/team_member_login_otp_verify");
                setIsLoading(false);
            } else {
                if (response?.data?.statusCode == "400") {
                    setIsLoading(false);
                    alert(response?.data?.msg)
                } else {
                    toast.error("Error: " + response?.data?.msg);
                    setIsLoading(false);
                }
            }
        } catch (error) {
            console.log("Error:", error);
            setIsLoading(false);
        }
    };
    // Function to handle checkbox change
    const handleCheckboxChange = () => {
        setRememberMe(!rememberMe);
    };

    // Effect to load remembered user if present
    useEffect(() => {
        const rememberedUser = localStorage.getItem('rememberedUser');
        if (rememberedUser) {
            const { username } = JSON.parse(rememberedUser);
            setEmail(username);
            setRememberMe(false);
        }
    }, []);
    useEffect(() => {
        document.title = '::Team_Member_Login::';
    }, []);

    return (
        <div className="login-signup-bg">
            {
                isLoading ? (
                    <div className="chat-window text-center">
                        <div style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            margin: "auto",
                        }} className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                ) : (
                    <div className="user-area p-2 p-md-4 p-lg-5 pt-lg-3">
                        <div className="container">
                            <div className="row">
                                <ToastContainer style={{ marginTop: "120px" }} />
                                <div className="col-lg-6 d-none d-md-none d-lg-block p-0">
                                    <div className="user_login">
                                        <img src={CommonImageTransport.loginbg} className="img-fluid w-100" alt="login-banner" />
                                    </div>
                                </div>
                                <div className="col-lg-6 d-flex p-0">
                                    <div className="user-all-form userlogin position-relative">
                                        <div className="login_bottom">
                                            <img src={CommonImageTransport.loginBottom} alt="" />
                                        </div>
                                        <div className="contact-form">

                                            <h3 className="user-title"> Log In to your Account</h3>
                                            <p>Welcome back! Select method to log in:</p>
                                            <form id="contactForms" className="mt-5" onSubmit={LoginApi}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-floating form-group">
                                                            <input type="email" required className="form-control" id="floatingInput"
                                                                placeholder=""
                                                                onChange={handleUsernameSubmit} />
                                                            <label htmlFor="floatingInput">Email ID</label>
                                                        </div>
                                                    </div>
                                                    {showPasswordField && (
                                                        <div className="col-12">
                                                            {/* <div className="form-floating form-group">
                                                            <input type="password" required className="form-control" id="floatingPassword"
                                                                placeholder="xxxxxxxx" 
                                                                onChange={(e) => setPassword(e.target.value)} />
                                                            <label htmlFor="floatingPassword">Password</label>
                                                        </div> */}
                                                            <div className="form-floating form-group">
                                                                <input
                                                                    type={showPassword ? "text" : "password"}
                                                                    className="form-control"
                                                                    id="floatingPassword"
                                                                    placeholder="xxxxxxxx"
                                                                    value={password}
                                                                    onChange={(e) => setPassword(e.target.value)}
                                                                />
                                                                <label htmlFor="floatingPassword">Password</label>
                                                                <span onClick={() => setShowPassword(!showPassword)}
                                                                    className="password-toggle eye">
                                                                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                                                                </span>
                                                            </div>

                                                        </div>
                                                    )}
                                                    <div className="col-lg-12 form-condition">
                                                        <div className="agree-label">
                                                            <input type="checkbox" id="chb1" checked={rememberMe}
                                                                onChange={handleCheckboxChange} />
                                                            <label htmlFor="chb1">
                                                                Remember Me <Link className="forget"
                                                                    to="/SendOtpForgotPass">Forgot
                                                                    Password?</Link>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <button type="submit"
                                                            className={`default-btn w-100 rounded ${showPasswordField ? '' : 'disabled'}`}
                                                        >
                                                            Get OTP
                                                        </button>
                                                        {error && <p className="text-danger mt-2">{error}</p>}
                                                        <h6 className="mt-4 text-center fw-normal text-muted">
                                                            Don’t have an account?
                                                            <Link className="fw-bold"
                                                                // to={
                                                                //     GetActiveTabs === "student"
                                                                //         ? "/Student_SignUp"
                                                                //         : GetActiveTabs === "Recruiter"
                                                                //             ? "/Recruiter_SignUp"
                                                                //             : GetActiveTabs === "Mentor"
                                                                //                 ? "/Mentor_SignUp"
                                                                //                 : "/Student_SignUp" 
                                                                // }
                                                                to="/Student_SignUp"
                                                            >
                                                                Create an account
                                                            </Link>
                                                        </h6>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default Team_Member_Login