import React, { useState, useEffect } from "react";
import Student_Header from "../student_header/Student_Header";
import CommonImageTransport from "../../../common/images/CommonImages";
import { GetStudentResume, StudentProfile } from "../../../../api/Student";
import moment, { duration } from "moment";
import Loader from "../../../../loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Student_Footer from "../student_footer/Student_Footer";

const Student_Resume = () => {
  const navigate = useNavigate();
  const [StudentResume, setStudentResume] = useState([]);
  const [EditProjectsMode, setEditProjectsMode] = useState(false);
  const [EditCertificateMode, setEditCertificateMode] = useState(false);
  const [EditTrainingProgramMode, setEditTrainingProgramMode] = useState(false);
  const [EditEductionMode, setEditEductionMode] = useState(false);
  const [EditSkillsMode, setEditSkillsMode] = useState(false);

  // <----- Student Work Exp Sections --------------->
  const [p_title, setP_title] = useState("");
  const [emp_type, setEmp_Type] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [Exp_start_date, setExp_start_date] = useState("");
  const [Exp_end_date, setExp_end_date] = useState("");
  const [job_desc, setJob_Desc] = useState("");

  // <----------- Student Projects sections ------------->
  const [projects, setProjects] = useState([]);
  const [CertificateSection, setCertificateSection] = useState([]);
  const [TraningProgramSection, setTraningProgramSection] = useState([]);
  const [ExpSection, setExpSection] = useState([]);
  const [EductionSection, setEductionSection] = useState([]);
  const [SkillSection, setSkillSection] = useState([]);
  const [projectTitle, setprojectTitle] = useState("");
  const [projectWebsitelink, setProjectWebsitelink] = useState("");
  const [projectYourrole, setprojectYourrole] = useState("");

  // <------ Student Certicate Section ------------------>
  const [certificate, setCertificate] = useState("");
  const [certification_date, setCertification_date] = useState("");
  const [organication, setOrganisation] = useState("");

  // <------ Student Certicate Section ------------------>
  const [EductionStartDate, setEductionStartDate] = useState("");
  const [EductionEndDate, setEductionEndDate] = useState("");
  const [Eductionuniversity, setEductionuniversity] = useState("");
  const [EductionDegreeType, setEductionDegreeType] = useState("");

  // <------ Student Certicate Section ------------------>
  const [Skills, setSkills] = useState("");
  const [SkillExperience, setSkillExperience] = useState("");
  const [is_Loading, setIs_Loading] = useState(false);

  // <------ Student Certicate Section ------------------>
  const [degreetype, setdegreetype] = useState("");
  const [degreename, setdegreename] = useState("");
  const [university, setuniversity] = useState("");
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");

  // <----------- User Authentication token's -------------->
  const token = localStorage.getItem("User_Token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // <----------Show Add the Traning Program Section's -------------->
  const [AddTraningProgramShow, setAddTraningProgramShow] = useState(false);

  // <---------- Add the Traning Program Section's -------------->
  const [AddTraningProgram, setAddTraningProgram] = useState([]);
  console.log("AddTraningProgram_AddTraningProgram");
  const [AddTraningProgramEditMode, setAddTraningProgramEditMode] =
    useState(false);

  // <----------- Get the Course List here --------------------->
  const [CourseList, setCourseList] = useState([]);

  // <------------ Add the Skills section's --------------------->
  const [newSkill, setNewSkill] = useState({ skill: "", expertise: "" });

  // <------------Show The New Add Skills section's --------------------->
  const [isAddingSkill, setIsAddingSkill] = useState(false);

  // <---------- Add the Certificate section's ------------------------>
  const [newCertificate, setNewCertificate] = useState({
    certificate: "",
    certification_date: "",
    organication: "",
  });

  // <---------- Add the Certificate section's ------------------------>
  const [newTraingProgram, setNewTraingProgram] = useState({
    title: "",
    duration: "",
    description: "",
  });

  // <---------- Show the New Certificate section's  ------------->
  const [isAddingCertificate, setIsAddingCertificate] = useState(false);

  // <---------- Show the New Certificate section's  ------------->
  const [isAddingTrainingProgram, setIsAddingTrainingProgram] = useState(false);

  // <------------ Add the Eduction section's -------------------->
  const [newEduction, setNewEduction] = useState({
    degreetype: "",
    university: "",
    startdate: "",
    enddate: "",
  });

  // <---------- Show the Edit Mode of The  Eduction section's  ------------->
  const [EditWorkExpMode, setEditWorkExpMode] = useState(false);

  // <-------------- Add The  New Experience Section's -------------->
  // const [newExperience, setNewExperience] = useState({
  //   p_title: "",
  //   emp_type: "",
  //   company: "",
  //   start_date: "",
  //   end_date: "",
  //   location: "",
  //   is_current_work: "8",
  // });
  const [editedIndex, setEditedIndex] = useState(null);

  // <---------- Show the New Eduction section's  ------------->
  const [isAddingEduction, setIsAddingEduction] = useState(false);

  // <-------------- Show the New Projects Section's -------------->
  const [isAddingNewProject, setIsAddingNewProject] = useState(false);
  // <-------------- Show the New Projects Section's -------------->
  const [isAddingNewExperience, setIsAddingNewExp] = useState(false);

  // <-------------- Add The  New Projects Section's -------------->
  const [newProject, setNewProject] = useState({
    title: "",
    websitelink: "",
    yourrole: "",
  });
  const [checkedval, setCheckedval] = useState("0");
  // <-------------- Add The  New Projects Section's -------------->
  const [newExp, setNewExp] = useState({
    p_title: "",
    location: "",
    emp_type: "",
    company: "",
    start_date: "",
    end_date: "",
    currently_working: "0",
  });

  // Function to validate the URL
  const isValidUrl = (url) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z0-9][a-z0-9-]*[a-z0-9]?)\\.)+[a-z]{2,}|" + // domain name
      "localhost|" + // localhost
      "\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|" + // ipv4
      "\\[?[a-fA-F0-9]*:[a-fA-F0-9:]+\\]?)" + // ipv6
      "(\\:\\d+)?(\\/[-a-zA-Z0-9@:%_+.~#?&=]*)*$"
    );
    return pattern.test(url);
  };

  // <---------- Handle save the New Projects -------------------->
  const handleSaveNewProject = async (e) => {
    e.preventDefault();
    if (!newProject.title.trim() || !newProject.yourrole.trim()) {
      toast.error("Please fill all the fields for the project except the project link");
      return;
    }

    // Validate the role description length (at least 100 characters)
    // const roleLengthWithoutSpaces = newProject.yourrole.replace(
    //   /\s+/g,
    //   ""
    // ).length;
    // if (roleLengthWithoutSpaces < 100) {
    //   toast.error(
    //     "Your role description must be at least 100 characters long!"
    //   );
    //   return;
    // }

    // Temporarily add the new project to the state
    const updatedProjects = [...StudentResume?.university_projects, newProject];
    setStudentResume((prev) => ({
      ...prev,
      university_projects: updatedProjects,
    }));

    const StudentProfessionalexperience = {
      certificates: StudentResume?.student_certification,
      experience: StudentResume?.st_experience,
      education: StudentResume?.student_educations,
      skills: StudentResume?.student_skills,
      project: updatedProjects,
      complete_course: "",
      is_current_work: "8",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await StudentProfile(StudentProfessionalexperience, headers);
      await HandleGetStudentResume();
      setNewProject({ title: "", websitelink: "", yourrole: "" });
      setIsAddingNewProject(false);
      toast.success("New project saved successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error saving new project:", error);
      toast.error("Failed to save the new project. Please try again.");
    }
  };


  const HandleEditProjectSection = async (e) => {
    e.preventDefault();
    // Validation: Ensure all fields are filled except the Project Link
    const areProjectsValid = StudentResume?.university_projects.every(
      (project) => {
        return project.title?.trim() && project.yourrole?.trim();
      }
    );

    if (!areProjectsValid) {
      toast.error("Please fill in all required fields except the Project Link.");
      return;
    }

    // Prepare the data to send to the API
    const StudentProfessionalexperience = {
      certificates: StudentResume?.student_certification,
      education: StudentResume?.student_educations,
      experience: StudentResume?.st_experience,
      skills: StudentResume?.student_skills,
      project: StudentResume?.university_projects,
      complete_course: "",
      is_current_work: "8",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await StudentProfile(StudentProfessionalexperience, headers);
      await HandleGetStudentResume();
      setEditProjectsMode(false);
      setIsAddingNewProject(false);
      toast.success("Project data saved successfully!");
    } catch (error) {
      console.error("Error saving project data:", error);
      toast.error("Failed to save project. Please try again.");
    }
  };

  // Handle Remove Project
  const handleRemoveProject = async (index) => {
    const updatedProjects = [...StudentResume.university_projects];
    updatedProjects.splice(index, 1);
    setStudentResume((prev) => ({
      ...prev,
      university_projects: updatedProjects,
    }));
    const StudentProfessionalexperience = {
      certificates: StudentResume?.student_certification,
      education: StudentResume?.student_educations,
      experience: StudentResume?.st_experience,
      skills: StudentResume?.student_skills,
      project: updatedProjects,
      complete_course: "",
      is_current_work: "8",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await StudentProfile(StudentProfessionalexperience, headers);
      HandleGetStudentResume();
      toast.success("Project removed successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error removing project:", error);
      toast.error("Failed to remove project. Please try again.");
    }
  };


  // Handle  handleRemoveExperience
  const handleRemoveExperience = async (index) => {
    const updatedExperience = [...StudentResume.st_experience];
    console.log("updatedExperienceupdatedExperienceupdatedExperience", index);

    updatedExperience?.splice(index, 1);
    setStudentResume((prev) => ({
      ...prev,
      st_experience: updatedExperience,
    }));
    const StudentProfessionalexperience = {
      certificates: StudentResume?.student_certification,
      education: StudentResume?.student_educations,
      experience: updatedExperience,
      skills: StudentResume?.student_skills,
      project: StudentResume?.university_projects,
      complete_course: "",
      is_current_work: "8",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await StudentProfile(StudentProfessionalexperience, headers);
      HandleGetStudentResume();
      toast.success("Project removed successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error removing project:", error);
      toast.error("Failed to remove project. Please try again.");
    }
  };

  // Handle changes to project input fields
  const handleNewProjectChange = (field, value) => {
    setNewProject((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Handle changes to project input fields
  const handleNewExpChange = (field, value) => {
    setNewExp((prev) => {
      let updatedExp = { ...prev, [field]: value };
      if (field === "currently_working" && value === 1) {
        updatedExp.end_date = "";
      }
      if (field === "end_date" && value) {
        updatedExp.currently_working = 0;
      }
      if (field === "start_date" && updatedExp.end_date && !updatedExp.currently_working) {
        const startDate = new Date(updatedExp.start_date);
        const endDate = new Date(updatedExp.end_date);

        // If start date is after end date, reset end date and show an error
        if (startDate > endDate) {
          updatedExp.end_date = ""; // Reset end date
          toast.error("Start date cannot be greater than End date.");
        }
      }

      // Validate end_date against start_date if start_date is provided and currently_working is not checked
      if (field === "end_date" && updatedExp.start_date && !updatedExp.currently_working) {
        const startDate = new Date(updatedExp.start_date);
        const endDate = new Date(value);

        // If end date is not greater than start date, reset end date and show an error
        if (endDate <= startDate) {
          updatedExp.end_date = ""; // Reset end date
          toast.error("End date must be greater than Start date.");
        }
      }

      return updatedExp;
    });
  };


  const handleProjectsChange = (index, field, value) => {
    const updatedProjects = [...StudentResume?.university_projects];
    updatedProjects[index] = {
      ...updatedProjects[index],
      [field]: value,
    };
    setStudentResume((prev) => ({
      ...prev,
      university_projects: updatedProjects,
    }));
  };

  // <--------Get Student Resume details  ------------->
  const HandleGetStudentResume = async (StudnetId) => {
    setIs_Loading(true);
    const StudentResumeData = {
      id: StudnetId,
    };
    try {
      const response = await GetStudentResume(StudentResumeData, headers);
      setStudentResume(response?.data?.data);
      setP_title(response?.data?.data?.student_detail?.p_title);
      setEmp_Type(response?.data?.data?.student_detail?.emp_type);
      setCompany(response?.data?.data?.student_detail?.company);
      setLocation(response?.data?.data?.student_detail?.location);
      setExp_start_date(response?.data?.data?.student_detail?.start_date);
      setExp_end_date(response?.data?.data?.student_detail?.end_date);
      setJob_Desc(response?.data?.data?.student_detail?.job_desc);
      setprojectTitle(response?.data?.data?.university_projects?.projectTitle);
      setAddTraningProgram(response?.data?.data?.complete_course);
      setCourseList(response?.data?.data?.course_list);
      setIs_Loading(false);
      if(response?.data?.statusCode == 401){
        localStorage.clear();
        navigate("/Signin")
        window.location.reload()
       }
    } catch (error) {
      setIs_Loading(true);
    }
  };

  console.log("CourseList", CourseList);

  const handleEditWorkExp = () => {
    setEditWorkExpMode(true);
  };

  // Function to handle adding a new project and enabling edit mode
  const handleAddProject = (e) => {
    e.preventDefault();
    setIsAddingNewProject(true);

    handleEditProjects();
  };

  const handleEditProjects = () => {
    setEditProjectsMode(true);
  };

  const handleEditCertificate = () => {
    setEditCertificateMode(true);
  };

  // Remove education from the list
  const handleRemoveEduction = async (index) => {
    const updatedEducations = [...StudentResume?.student_educations];
    updatedEducations.splice(index, 1);

    // Update state with modified education list
    setStudentResume((prev) => ({
      ...prev,
      student_educations: updatedEducations,
    }));

    try {
      const StudentProfessionalexperience = {
        certificates: StudentResume?.student_certification,
        experience: StudentResume?.st_experience,
        education: updatedEducations, // Updated education list after removal
        skills: StudentResume?.student_skills,
        project: StudentResume?.university_projects,
        complete_course: "",
        enddate,
        startdate,
        university,
        degreename,
        degreetype,
        is_current_work: "8",
      };

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Call the API with the updated data
      await StudentProfile(StudentProfessionalexperience, headers);

      // Fetch updated data to show changes in the UI
      await HandleGetStudentResume();

      toast.success("Education removed and changes saved successfully!");

      // Reload the page to reflect the latest data
      setTimeout(() => {
        window.location.reload();
      }, 1000); // Delay to ensure toast message displays before reload
    } catch (error) {
      console.error("Error removing education:", error);
      toast.error("Failed to save changes. Please try again.");
    }
  };

  const handleEditEduction = () => {
    setEditEductionMode(true);
  };

  const HandleAddCertifications = (e) => {
    e.preventDefault();
    setEditCertificateMode(true);
    setIsAddingCertificate(true);
  };

  const HandleAddTraningProgram = (e) => {
    e.preventDefault();
    setEditTrainingProgramMode(true);
    setIsAddingTrainingProgram(true);
  };

  const handleCertificateChange = (index, field, value) => {
    const newProjects = [...StudentResume?.student_certification];
    newProjects[index][field] = value;
    setCertificateSection(newProjects);
  };

  const handleTraingProgramChange = (index, field, value) => {
    const newProjects = [...StudentResume?.complete_course];
    newProjects[index][field] = value;
    setTraningProgramSection(newProjects);
  };

  const handleExpChange = (index, field, value) => {
    const newProjects = [...StudentResume?.st_experience];
    newProjects[index][field] = value;
    setExpSection(newProjects);
  };

  const handleEductionChange = (index, field, value) => {
    const updatedEducation = [...StudentResume?.student_educations];

    // Only normalize the date format for date fields
    if (field === "startdate" || field === "enddate") {
        updatedEducation[index][field] = moment(value, "YYYY-MM-DD").isValid()
            ? moment(value, "YYYY-MM-DD").format("YYYY-MM-DD")
            : ""; // Handle invalid date gracefully
    } else {
        updatedEducation[index][field] = value; // Directly assign value for non-date fields
    }

    setEductionSection(updatedEducation);
};


  const handleSkillChange = (index, field, value) => {
    const newProjects = [...StudentResume?.student_skills];
    newProjects[index][field] = value;
    setSkillSection(newProjects);
  };

  const handleProgramChange = (index, field, value) => {
    const newProjects = [...StudentResume?.complete_course];
    newProjects[index][field] = value;
    setTraningProgramSection(newProjects);
  };

  useEffect(() => {
    HandleGetStudentResume();
  }, []);

  // Function to handle experience input changes
  // const handleExperienceChange = (index, field, value) => {
  //   const updatedExperiences = [...StudentResume?.st_experience];
  //   const experience = updatedExperiences[index];

  //   // Normalize date fields to UTC
  //   if (field === "start_date" || field === "end_date") {
  //     value = value ? new Date(value + "T00:00:00Z").toISOString() : ""; // Convert to UTC
  //   }

  //   if (field === "currently_working" && value === 1) {
  //     // If "currently working" checkbox is checked, clear the end date
  //     experience.end_date = "";
  //   } else if (field === "end_date" && value) {
  //     // If an end date is provided, uncheck "currently working"
  //     experience.currently_working = 0;
  //   }

  //   // Update the state with the changed value
  //   experience[field] = value;

  //   // Perform date validation if needed
  //   if (experience.start_date && experience.end_date && experience.end_date <= experience.start_date) {
  //     // If end date is not after start date, reset the end date
  //     experience.end_date = "";
  //     toast.error("End date must be after Start date.");
  //   }

  //   setExpSection(updatedExperiences); // Update state with the new experiences array
  // };

  const handleExperienceChange = (index, field, value) => {
    const updatedExperiences = [...StudentResume?.st_experience];
    const experience = updatedExperiences[index];
    // Normalize date fields
    if (field === "start_date" || field === "end_date") {
      value = value
        ? moment.utc(value, "YYYY-MM-DD").format("YYYY-MM-DD") // Normalize to consistent format
        : ""; // Handle empty value
    }
    if (field === "currently_working" && value === 1) {
      // Clear the end date if "currently working" is checked
      experience.end_date = "";
    } else if (field === "end_date" && value) {
      // Uncheck "currently working" if an end date is provided
      experience.currently_working = 0;
    }
    // Update the experience field
    experience[field] = value;
    // Perform date validation
    if (
      experience.start_date &&
      experience.end_date &&
      new Date(experience.end_date) <= new Date(experience.start_date)
    ) {
      experience.end_date = ""; // Reset end date if validation fails
      toast.error("End date must be after the Start date.");
    }
    setExpSection(updatedExperiences); // Update state
  };

  // Add a new experience
  const handleAddExperience = (e) => {
    e.preventDefault();
    setIsAddingNewExp(true);
  };

  const handleSaveNewExperience = async (e) => {
    e.preventDefault();

    const isCurrentlyWorking = newExp.currently_working === 1;

    // If currently working is checked, clear end_date
    if (isCurrentlyWorking) {
      newExp.end_date = ""; // Clear end date if "currently working" is checked
    }

    // Validation: Ensure all required fields are filled
    const isRequiredFieldMissing =
      !newExp.p_title?.trim() ||
      !newExp.emp_type?.trim() ||
      !newExp.company?.trim() ||
      !newExp.location?.trim() ||
      !newExp.start_date?.trim();

    // Validation: Ensure either end_date is filled or "currently working" is checked
    const isEndDateOrCurrentlyWorkingMissing =
      !isCurrentlyWorking && !newExp.end_date?.trim();

    // Log values for debugging
    console.log("New Experience Data:", newExp);
    console.log("Required Field Missing:", isRequiredFieldMissing);
    console.log("End Date or Currently Working Missing:", isEndDateOrCurrentlyWorkingMissing);

    if (isRequiredFieldMissing || isEndDateOrCurrentlyWorkingMissing) {
      toast.error("Please fill in all required fields. If not currently working, provide an End Date.");
      return;
    }

    const updatedExperience = [...StudentResume?.st_experience, newExp];
    setStudentResume((prev) => ({
      ...prev,
      st_experience: updatedExperience,
    }));

    // Prepare data to send to the backend
    const StudentProfessionalexperience = {
      certificates: StudentResume?.student_certification,
      education: StudentResume?.student_educations,
      experience: updatedExperience,
      skills: StudentResume?.student_skills,
      project: StudentResume?.university_projects,
      complete_course: "",
      is_current_work: "8",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await StudentProfile(StudentProfessionalexperience, headers);
      toast.success("Experience added and saved successfully!");
      HandleGetStudentResume();
    } catch (error) {
      console.error("Error saving experience:", error);
      toast.error("Failed to save experience. Please try again.");
    }

    // Reset the experience fields and exit "Edit Experience" mode
    setNewExp({
      p_title: "",
      emp_type: "",
      company: "",
      start_date: "",
      end_date: "",
      currently_working: false, // Reset checkbox state
    });
    setIsAddingNewExp(false);
  };
  ;

  const prepareExperienceForSave = (experience) => {
    return experience.map((exp) => ({
      ...exp,
      start_date: exp.start_date?.split("T")[0],
      end_date: exp.end_date?.split("T")[0],
    }));
  };

  const HandleProfessionalexperience = async (e) => {
    e.preventDefault();

    // Normalize dates before sending to the backend
    const normalizedExperiences = prepareExperienceForSave(StudentResume?.st_experience);

    const StudentProfessionalexperience = {
      certificates: StudentResume?.student_certification,
      experience: normalizedExperiences,
      education: StudentResume?.student_educations,
      skills: StudentResume?.student_skills,
      project: StudentResume?.university_projects,
      complete_course: "",
      is_current_work: "8", // assuming this is a default or placeholder value
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await StudentProfile(StudentProfessionalexperience, headers);
      toast.success("Experience updated and saved successfully!");
      HandleGetStudentResume();
      window.location.reload()
    } catch (error) {
      console.error("Error saving experience:", error);
      toast.error("Failed to save experience. Please try again.");
    }
  };




  // <----------- edit Certification sections ----------------->
  const HandleEditCertificateSection = async (e) => {
    e.preventDefault();

    // Validation: Ensure all fields are filled for each certificate
    const areCertificatesValid = StudentResume?.student_certification?.every(
      (certificate) => {
        return (
          certificate?.certificate?.trim() &&
          certificate?.certification_date?.trim() &&
          certificate?.organication?.trim()
        );
      }
    );

    // If validation fails, show an error and stop the function
    if (!areCertificatesValid) {
      toast.error(
        "Please fill in all fields for each certificate before submitting."
      );
      return;
    }

    // Prepare the data to send to the API
    const StudentProfessionalexperience = {
      certificates: StudentResume?.student_certification,
      experience: StudentResume?.st_experience,
      education: StudentResume?.student_educations,
      skills: StudentResume?.student_skills,
      project: StudentResume?.university_projects,
      complete_course: "",
      certification_date,
      certificate,
      organication,
      is_current_work: "8",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      // Call the API to save the data
      await StudentProfile(StudentProfessionalexperience, headers);

      // Refresh the resume data
      await HandleGetStudentResume();

      // Exit edit mode
      setEditCertificateMode(false);
      toast.success("Certificate data saved successfully!");
    } catch (error) {
      console.error("Error saving certificate data:", error);
      toast.error("Failed to save certificate. Please try again.");
    }
  };

  const handleSaveNewCertificate = async (e) => {
    e.preventDefault();
    if (
      !newCertificate.certificate.trim() ||
      !newCertificate.organication.trim() ||
      !newCertificate.certification_date
    ) {
      toast.error("Please fill in all certificate fields!");
      return;
    }
    const updatedCertificates = [
      ...StudentResume?.student_certification,
      newCertificate,
    ];
    setStudentResume((prev) => ({
      ...prev,
      student_certification: updatedCertificates,
    }));
    const StudentProfessionalexperience = {
      certificates: updatedCertificates,
      education: StudentResume?.student_educations,
      experience: StudentResume?.st_experience,
      skills: StudentResume?.student_skills,
      project: StudentResume?.university_projects,
      complete_course: "",
      certification_date: newCertificate.certification_date,
      certificate: newCertificate.certificate,
      organication: newCertificate.organication,
      is_current_work: "8",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await StudentProfile(StudentProfessionalexperience, headers);
      toast.success("Certificate added and saved successfully!");
      HandleGetStudentResume();
      window.location.reload();
    } catch (error) {
      console.error("Error saving certificate:", error);
      toast.error("Failed to save certificate. Please try again.");
    }
    setNewCertificate({
      certificate: "",
      certification_date: "",
      organication: "",
    });
    setIsAddingCertificate(false);
  };

  const handleSaveNewTraingProgram = async (e) => {
    e.preventDefault();
    if (
      !newTraingProgram.title ||
      !newTraingProgram.duration ||
      !newTraingProgram.description
    ) {
      toast.error("Please fill in all fields for the Training Program!");
      return;
    }
    const updatedCertificates = [
      ...StudentResume?.complete_course,
      newTraingProgram,
    ];
    setStudentResume((prev) => ({
      ...prev,
      complete_course: updatedCertificates,
    }));
    const StudentProfessionalexperience = {
      certificates: StudentResume?.student_certification,
      education: StudentResume?.student_educations,
      experience: StudentResume?.st_experience,
      skills: StudentResume?.student_skills,
      project: StudentResume?.university_projects,
      complete_course: updatedCertificates,
      is_current_work: "8",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await StudentProfile(StudentProfessionalexperience, headers);
      toast.success("Training Program added and saved successfully!");
      await HandleGetStudentResume();
      window.location.reload();
    } catch (error) {
      console.error("Error saving training program:", error);
      toast.error("Failed to save training program. Please try again.");
    }
    setNewTraingProgram({
      title: "",
      duration: "",
      description: "",
    });
    setIsAddingTrainingProgram(false);
  };

  const handleNewCertificateChange = (field, value) => {
    setNewCertificate((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleNewTraingProgramChange = (field, value) => {
    setNewTraingProgram((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Remove certificate from the list
  const handleRemoveCertificate = async (index) => {

    // Remove the certificate from the local state
    const updatedCertificates = [...StudentResume?.student_certification];
    updatedCertificates.splice(index, 1);

    setStudentResume((prev) => ({
      ...prev,
      student_certification: updatedCertificates,
    }));

    // Now update the backend with the new list of certificates
    const StudentProfessionalexperience = {
      certificates: updatedCertificates,
      education: StudentResume?.student_educations,
      experience: StudentResume?.st_experience,
      skills: StudentResume?.student_skills,
      project: StudentResume?.university_projects,
      complete_course: "",
      is_current_work: "8", // or whatever field you need to set
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      // Call the API to save the updated data
      await StudentProfile(StudentProfessionalexperience, headers);
      toast.success("Certificate removed successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error removing certificate:", error);
      toast.error("Failed to remove certificate. Please try again.");
    }
  };

  // Remove certificate from the list
  const HandleRemoveTraningProgram = async (index) => {
    const updatedTraningProgram = [...StudentResume?.complete_course];
    updatedTraningProgram.splice(index, 1);

    setStudentResume((prev) => ({
      ...prev,
      complete_course: updatedTraningProgram,
    }));

    // Now update the backend with the new list of certificates
    const StudentProfessionalexperience = {
      certificates: StudentResume?.student_certification,
      education: StudentResume?.student_educations,
      experience: StudentResume?.st_experience,
      skills: StudentResume?.student_skills,
      project: StudentResume?.university_projects,
      complete_course: updatedTraningProgram,
      is_current_work: "8", // or whatever field you need to set
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      // Call the API to save the updated data
      await StudentProfile(StudentProfessionalexperience, headers);
      toast.success("Certificate removed successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error removing certificate:", error);
      toast.error("Failed to remove certificate. Please try again.");
    }
  };

  // State to track if an immediate save is needed after adding an education
  const [needsImmediateEducationSave, setNeedsImmediateEducationSave] =
    useState(false);

  // Save the new education and add it to the education list temporarily
  const handleEductionNew = (e) => {
    e.preventDefault();

    // Validate the new education fields
    if (
      !newEduction.startdate.trim() ||
      !newEduction.enddate.trim() ||
      !newEduction.degreetype.trim() ||
      !newEduction.university.trim()
    ) {
      toast.error("Please fill all the fields of the education!");
      return;
    }

    // Update the local StudentResume state with the new education
    const updatedEducations = [
      ...(StudentResume?.student_educations || []),
      newEduction,
    ];
    setStudentResume((prev) => ({
      ...prev,
      student_educations: updatedEducations,
    }));

    // Set the flag to trigger immediate save
    setNeedsImmediateEducationSave(true);

    // Reset the new education fields and exit "Add Education" mode
    setNewEduction({
      degreetype: "",
      university: "",
      enddate: "",
      startdate: "",
    });
    setIsAddingEduction(false);
  };

  // Cancel adding new education and reset the input fields
  const handleCancelNewEducation = (e) => {
    e.preventDefault();
    setNewEduction({
      degreetype: "",
      university: "",
      enddate: "",
      startdate: "",
    });
    setIsAddingEduction(false);
  };

  useEffect(() => {
    // If StudentResume has been updated and needs an immediate save, call HandleEditEductionSection
    if (needsImmediateEducationSave && StudentResume?.student_educations) {
      HandleEditEductionSection();
      setNeedsImmediateEducationSave(false); // Reset the flag after saving
    }
  }, [StudentResume, needsImmediateEducationSave]);

  const HandleEditEductionSection = async (e) => {
    e?.preventDefault();

    // Prepare the data for saving
    const StudentProfessionalexperience = {
      certificates: StudentResume?.student_certification,
      education: StudentResume?.student_educations,
      experience: StudentResume?.st_experience,
      skills: StudentResume?.student_skills,
      project: StudentResume?.university_projects,
      complete_course: "",
      enddate: EductionEndDate,
      startdate: EductionStartDate,
      university: Eductionuniversity,
      degreetype: EductionDegreeType,
      is_current_work: "8",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await StudentProfile(StudentProfessionalexperience, headers);
      HandleGetStudentResume();
      setEditEductionMode(false);
      toast.success("Education section updated successfully!");
    } catch (error) {
      console.error("Error saving education section:", error);
      toast.error("Failed to save education. Please try again.");
    }
  };

  const handleNewEductionChange = (field, value) => {
    setNewEduction((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    handleNewEductionChange("startdate", selectedStartDate);

    // Validate if end date is earlier than start date
    if (
      newEduction.enddate &&
      new Date(newEduction.enddate).getTime() < new Date(selectedStartDate).getTime()
    ) {
      alert("End date must be greater than start date.");
      handleNewEductionChange("enddate", ""); // Clear end date if validation fails
    }
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;

    // Validate if end date is earlier than start date
    if (
      newEduction.startdate &&
      new Date(selectedEndDate).getTime() < new Date(newEduction.startdate).getTime()
    ) {
      alert("End date must be greater than start date.");
      handleNewEductionChange("enddate", ""); // Clear end date if validation fails
    } else {
      handleNewEductionChange("enddate", selectedEndDate);
    }
  };

  // State to track if an immediate save is needed after adding a skill
  const [needsImmediateSave, setNeedsImmediateSave] = useState(false);

  // Save the new skill and add it to the skills list temporarily
  const handleSaveNewSkill = (e) => {
    e.preventDefault();

    // Validate the new skill fields
    if (!newSkill.skill.trim() || !newSkill.expertise.trim()) {
      toast.error("Please fill in both skill and expertise fields!");
      return;
    }

    // Update the local StudentResume state with the new skill
    const updatedSkills = [...(StudentResume?.student_skills || []), newSkill];
    setStudentResume((prev) => ({
      ...prev,
      student_skills: updatedSkills,
    }));

    // Set the flag to trigger immediate save
    setNeedsImmediateSave(true);

    // Reset the new skill fields and exit "Add Skill" mode
    setNewSkill({ skill: "", expertise: "" });
    setIsAddingSkill(false);
  };

  useEffect(() => {
    // If StudentResume has been updated and needs an immediate save, call HandleEditSkillSection
    if (needsImmediateSave && StudentResume?.student_skills) {
      HandleEditSkillSection();
      setNeedsImmediateSave(false); // Reset the flag after saving
    }
  }, [StudentResume, needsImmediateSave]);

  const HandleEditSkillSection = async (e) => {
    e?.preventDefault();

    // Validate skills before saving
    const hasEmptyFields = StudentResume?.student_skills?.some(
      (skill) => !skill.skill?.trim()
    );
    const hasEmptyExpertise = StudentResume?.student_skills?.some(
      (skill) => !skill.expertise?.trim()
    );

    if (hasEmptyFields || hasEmptyExpertise) {
      toast.error(
        "Please fill in all skill and expertise fields before saving!"
      );
      return;
    }

    const hasValidSkill = StudentResume?.student_skills?.some(
      (skill) => skill.skill?.trim() && skill.expertise?.trim()
    );

    if (!hasValidSkill) {
      toast.error("At least one skill or expertise must be filled!");
      return;
    }

    // Prepare the data for saving
    const StudentProfessionalexperience = {
      certificates: StudentResume?.student_certification,
      education: StudentResume?.student_educations,
      experience: StudentResume?.st_experience,
      skills: StudentResume?.student_skills,
      project: StudentResume?.university_projects,
      complete_course: "",
      skill: Skills,
      expertise: SkillExperience,
      is_current_work: "8",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await StudentProfile(StudentProfessionalexperience, headers);
      HandleGetStudentResume();
      setEditSkillsMode(false);
      toast.success("Skills updated successfully!");
    } catch (error) {
      console.error("Error saving skills:", error);
      toast.error("Failed to save skills. Please try again.");
    }
  };

  // Cancel adding new skill and reset the input fields
  const handleCancelNewSkill = (e) => {
    e.preventDefault();
    setNewSkill({ skill: "", expertise: "" });
    setIsAddingSkill(false);
  };

  // Remove skill from the list
  const handleRemoveSkill = async (index) => {
    const updatedSkills = [...StudentResume?.student_skills];
    updatedSkills.splice(index, 1);
    setStudentResume((prev) => ({
      ...prev,
      student_skills: updatedSkills,
    }));
    try {
      const StudentProfessionalexperience = {
        certificates: StudentResume?.student_certification,
        education: StudentResume?.student_educations,
        experience: StudentResume?.st_experience,
        skills: updatedSkills,
        project: StudentResume?.university_projects,
        complete_course: "",
        skill: Skills,
        expertise: SkillExperience,
        is_current_work: "8",
      };

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      await StudentProfile(StudentProfessionalexperience, headers);
      HandleGetStudentResume();
      toast.success("Skill removed and changes saved successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Error saving skills:", error);
      toast.error("Failed to save changes. Please try again.");
    }
  };

  const handleNewSkillChange = (field, value) => {
    setNewSkill((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (AddTraningProgram?.length === 0) {
      setAddTraningProgram([{ id: 1, title: "", duration: "" }]);
    }
  }, [AddTraningProgram]);

  const [isAddingMode, setIsAddingMode] = useState(false);

  // const HandleAddTraningProgram = () => {
  //   setAddTraningProgramShow(true);
  //   setAddTraningProgramEditMode(true); // Enter edit mode on add
  //   setAddTraningProgram((prev) => [
  //     ...prev,
  //     { id: Date.now(), title: "", duration: "" },
  //   ]);
  // };

  // Function to handle 'Edit' button click
  const HandleEditTraningProgram = () => {
    setIsAddingMode(false); // Set to edit mode
    setAddTraningProgramEditMode(true); // Enable edit mode
    setAddTraningProgramShow(true); // Show the form
  };

  // Function to handle 'Save' button click
  const HandleSaveTrainingProgram = () => {
    // Perform save actions here
    setAddTraningProgramEditMode(false); // Exit edit mode after saving
    setAddTraningProgramShow(false); // Hide form after saving
  };

  // const HandleRemoveTraningProgram = async (programId) => {
  //   // Update the local state by removing the program
  //   const updatedPrograms = AddTraningProgram.filter((program) => program.id !== programId);
  //   setAddTraningProgram(updatedPrograms); // Update the frontend state

  //   const StudentProfessionalexperience = {
  //     certificates: StudentResume?.student_certification,
  //     experience: StudentResume?.st_experience,
  //     education: StudentResume?.student_educations,
  //     skills: StudentResume?.student_skills,
  //     project: StudentResume?.university_projects,
  //     complete_course: updatedPrograms, // Update the backend with the new list
  //     is_current_work: "8",
  //   };

  //   const headers = {
  //     Authorization: `Bearer ${token}`,
  //   };

  //   try {
  //     // Call the backend directly to save the removed training program update
  //     await StudentProfile(StudentProfessionalexperience, headers);
  //     toast.success("Training program removed successfully!");

  //     // Optionally refresh data from the server
  //     await HandleGetStudentResume();
  //     window.location.reload();
  //   } catch (error) {
  //     console.error("Error removing training program:", error);
  //     toast.error("Failed to remove training program. Please try again.");
  //   }
  // };

  const HandleTraningProgramSection = async (e) => {
    e.preventDefault();

    // Validate each training program entry
    for (let i = 0; i < AddTraningProgram.length; i++) {
      const program = AddTraningProgram[i];
      if (!program.title || !program.duration || !program.description) {
        toast.error(
          "Please fill in all fields for each training program before submitting."
        );
        return; // Prevent form submission if any field is empty
      }
    }

    // Prepare data to send if all fields are filled
    const StudentProfessionalexperience = {
      certificates: StudentResume?.student_certification,
      experience: StudentResume?.st_experience,
      education: StudentResume?.student_educations,
      skills: StudentResume?.student_skills,
      project: StudentResume?.university_projects,
      complete_course: AddTraningProgram, // Current list of programs
      is_current_work: "8",
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      // Save the data if validation passed
      await StudentProfile(StudentProfessionalexperience, headers);
      toast.success("Training programs saved successfully!");

      // Optionally refresh data from the server
      await HandleGetStudentResume();
      window.location.reload();
    } catch (error) {
      console.error("Error saving training programs:", error);
      toast.error("Failed to save training programs. Please try again.");
    }
  };

  // <------------ Show the DOB bydeafult Here ---------------->
  useEffect(() => {
    setExp_start_date(formatDateForDisplay(Exp_start_date));
    setExp_end_date(formatDateForDisplay(Exp_end_date));
    setExp_end_date(formatDateForDisplay(Exp_end_date));
    setCertification_date(formatDateForDisplay(certification_date));
  }, [Exp_start_date, Exp_end_date, certification_date]);

  // <------------ Show the DOB format Date ---------------->
  const formatDateForDisplay = (dateString) => {
    if (!dateString) return "";
    // return moment(dateString, "YYYY-MM-DD").format("YYYY-MM-DD");
    return moment(dateString, "YYYY-MM-DD").format("YYYY-MM-DD")
  };

  useEffect(() => {
    // If editing, populate the form fields with existing data
    if (editedIndex !== null) {
      const experience = StudentResume?.student_experiences[editedIndex];
      setP_title(experience?.p_title || "");
      setEmp_Type(experience?.emp_type || "");
      setCompany(experience?.company || "");
      setLocation(experience?.location || "");
      setExp_start_date(experience?.start_date || "");
      setExp_end_date(experience?.end_date || "");
      setJob_Desc(experience?.job_desc || "");
    }
  }, [editedIndex, StudentResume]);

  useEffect(() => {
    // Scroll to the top of the page when the component is rendered
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="bg_nav-slid bg-white">
        {/* <-------- Student Header section's ----------> */}
        <Student_Header />
      </div>
      <div className="header_space"></div>
      <div className="stepbg">
        <div className="client_filter-job pt-2 pb-5">
          <div className="stepsForm">
            <form method="post" id="msform">
              <div className="container">
                <div className="row">
                  {is_Loading ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <div className="col-md-12 col-lg-9 mx-auto">
                      <div className="flexdr position-relative shadow-lg">
                        <ToastContainer style={{ marginTop: "50px" }} />
                        <div className="resumefile">
                          <page size="A4">
                            <div className="d-flex">
                              <div className="leftPanel">
                                {StudentResume?.student_info?.profileimg !=
                                  null ? (
                                  <>
                                    <img
                                      src={
                                        StudentResume?.student_info?.profileimg
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img src={CommonImageTransport?.userLogo} />
                                  </>
                                )}
                                <div className="details">
                                  <div className="item bottomLineSeparator">
                                    <div className="smallText">
                                      <p>
                                        {StudentResume?.student_info?.fname}{" "}
                                        {StudentResume?.student_info?.lname}
                                      </p>
                                      <p>
                                        <i
                                          className="fa fa-phone contactIcon"
                                          aria-hidden="true"
                                        ></i>
                                        {StudentResume?.student_info?.mobile?.replace(
                                          /null/g,
                                          ""
                                        )}
                                      </p>
                                      <p>
                                        <i
                                          className="fa fa-envelope contactIcon"
                                          aria-hidden="true"
                                        ></i>
                                        <p>
                                          {StudentResume?.student_info?.email}
                                        </p>
                                      </p>
                                      {/* <p>
                                          <strong> DOB:</strong>({moment(
                                           StudentResume?.student_detail?.dob
                                          ).format("DD-MM-YYYY")})
                                        
                                      </p> */}
                                      <p>
                                        <i
                                          className="fa fa-map-marker contactIcon"
                                          aria-hidden="true"
                                        ></i>
                                        {StudentResume?.student_info?.city},{" "}
                                        {StudentResume?.student_info?.country}
                                      </p>
                                      {/* <p>
                                        <i
                                          className="fa fa-map-marker contactIcon"
                                          aria-hidden="true"
                                        ></i>
                                        ({StudentResume?.student_detail?.nationality})
                                      </p> */}
                                    </div>
                                  </div>

                                  {/* <------- Skills Sections -------------> */}
                                  <div className="item bottomLineSeparator">
                                    <div className="d-flex justify-content-between">
                                      <h2>SKILLS</h2>
                                      <div className="text-end">
                                        {/*<--------- Add new skill button ------------>*/}
                                        {!EditSkillsMode && (
                                          <>
                                            {!isAddingSkill && (
                                              <button
                                                className="btn btn-primary"
                                                onClick={() =>
                                                  setIsAddingSkill(true)
                                                }
                                              >
                                                Add+
                                              </button>
                                            )}
                                          </>
                                        )}
                                        {/* <---------- If here the No any Skills Available here -----------> */}
                                        {StudentResume?.student_skills
                                          ?.length != 0 && (
                                            <>
                                              {!isAddingSkill && (
                                                <>
                                                  {EditSkillsMode ? (
                                                    <div>
                                                      <button
                                                        className="btn btn-primary m-1"
                                                        onClick={
                                                          HandleEditSkillSection
                                                        }
                                                      >
                                                        Save
                                                      </button>
                                                      <button
                                                        className="btn btn-secondary m-1"
                                                        onClick={() =>
                                                          setEditSkillsMode(false)
                                                        }
                                                      >
                                                        Cancel
                                                      </button>
                                                    </div>
                                                  ) : (
                                                    <button
                                                      className="btn btn-primary m-1"
                                                      onClick={() =>
                                                        setEditSkillsMode(true)
                                                      }
                                                    >
                                                      Edit
                                                    </button>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>

                                    {/*<----------- Display skills list -----------------> */}
                                    <div className="smallText">
                                      {StudentResume?.student_skills?.map(
                                        (skill, index) => (
                                          <div
                                            className="skill d-flex justify-content-between align-items-center"
                                            key={index}
                                          >
                                            <div>
                                              <span>
                                                {EditSkillsMode ? (
                                                  <input
                                                    type="text"
                                                    value={skill?.skill}
                                                    onChange={(e) =>
                                                      handleSkillChange(
                                                        index,
                                                        "skill",
                                                        e.target.value
                                                      )
                                                    }
                                                    style={{
                                                      border: "none",
                                                      borderBottom:
                                                        "1px solid grey",
                                                      marginRight: "10px",
                                                    }}
                                                    placeholder="Skill"
                                                  />
                                                ) : (
                                                  <>* {skill?.skill}</>
                                                )}
                                              </span>
                                              <span>
                                                {EditSkillsMode ? (
                                                  <select
                                                    value={
                                                      skill?.expertise || ""
                                                    } // Default to empty if skill.expertise is undefined
                                                    onChange={(e) =>
                                                      handleSkillChange(
                                                        index,
                                                        "expertise",
                                                        e.target.value
                                                      )
                                                    }
                                                    style={{
                                                      border: "none",
                                                      borderBottom:
                                                        "1px solid grey",
                                                      padding: "5px",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    {/* Placeholder option */}
                                                    <option value="" disabled>
                                                      Select Expertise
                                                    </option>

                                                    {/* Options */}
                                                    <option value="Beginner">
                                                      Beginner
                                                    </option>
                                                    <option value="Intermediate">
                                                      Intermediate
                                                    </option>
                                                    <option value="Advanced">
                                                      Advanced
                                                    </option>
                                                    <option value="Expert">
                                                      Expert
                                                    </option>
                                                  </select>
                                                ) : (
                                                  `(${skill?.expertise})`
                                                )}
                                              </span>
                                            </div>

                                            {/*<-------- Remove skill button in edit mode ------------------->*/}
                                            {EditSkillsMode && (
                                              <button
                                                className="btn btn-danger btn-sm"
                                                onClick={() =>
                                                  handleRemoveSkill(index)
                                                }
                                              >
                                                Remove
                                              </button>
                                            )}
                                          </div>
                                        )
                                      )}

                                      {/*<------------- Show input fields when adding a new skill -------------->*/}
                                      {isAddingSkill && (
                                        <div className="skill align-items-center">
                                          <div className="row">
                                            <div className="col-12">
                                              <input
                                                type="text"
                                                value={newSkill?.skill}
                                                onChange={(e) =>
                                                  handleNewSkillChange(
                                                    "skill",
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  border: "none",
                                                  borderBottom:
                                                    "1px solid grey",
                                                  marginRight: "10px",
                                                }}
                                                placeholder="New Skill"
                                              />
                                            </div>
                                            <div className="col-12">
                                              <select
                                                value={newSkill?.expertise} // Default to empty if newSkill.expertise is undefined
                                                onChange={(e) =>
                                                  handleNewSkillChange(
                                                    "expertise",
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  border: "none",
                                                  borderBottom:
                                                    "1px solid grey",
                                                  padding: "5px",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                {/* Placeholder option */}
                                                <option value="" disabled>
                                                  Select Expertise
                                                </option>

                                                {/* Expertise options */}
                                                <option value="Beginner">
                                                  Beginner
                                                </option>
                                                <option value="Intermediate">
                                                  Intermediate
                                                </option>
                                                <option value="Advanced">
                                                  Advanced
                                                </option>
                                                <option value="Expert">
                                                  Expert
                                                </option>
                                              </select>
                                            </div>
                                          </div>

                                          {/*<------------ Save/Cancel buttons for new skill ------------->*/}
                                          <div className="row">
                                            <div className="col-12">
                                              <button
                                                className="btn btn-success btn-sm m-1"
                                                onClick={handleSaveNewSkill}
                                              >
                                                Save
                                              </button>
                                            </div>
                                            <div className="col-12">
                                              <button
                                                className="btn btn-secondary btn-sm m-1"
                                                onClick={handleCancelNewSkill}
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  {/* <------------ Eduction Sections ---------------> */}
                                  <div className="item">
                                    <div className="d-flex justify-content-between">
                                      <h2>EDUCATION</h2>
                                    </div>
                                    <div className="d-flex ">
                                      <div>
                                        {!EditEductionMode && (
                                          <>
                                            {!isAddingEduction && (
                                              <button
                                                className="btn btn-primary m-1"
                                                onClick={() =>
                                                  setIsAddingEduction(true)
                                                }
                                              >
                                                Add+
                                              </button>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      {/* <---------- If here the No any Eductions Available here -----------> */}
                                      <div>
                                        {!isAddingEduction &&
                                          StudentResume?.student_educations
                                            ?.length > 0 && (
                                            <>
                                              {EditEductionMode ? (
                                                <div>
                                                  <button
                                                    className="btn btn-primary m-1"
                                                    onClick={
                                                      HandleEditEductionSection
                                                    }
                                                  >
                                                    Save
                                                  </button>
                                                  <button
                                                    className="btn btn-secondary m-1"
                                                    onClick={() =>
                                                      setEditEductionMode(false)
                                                    }
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              ) : (
                                                <button
                                                  className="btn btn-primary m-1"
                                                  onClick={handleEditEduction}
                                                >
                                                  Edit
                                                </button>
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                    {/* <--------- List of the Student Eductions section's -----------> */}
                                    {StudentResume?.student_educations?.map(
                                      (education, index) => (
                                        <div
                                          className="smallText border-bottom mt-2"
                                          key={index}
                                        >
                                          <p className="bolded white">
                                            {/* <------ Show the Input feilds in the Edit Mode ----------> */}
                                            {EditEductionMode ? (
                                              <input
                                                type="text"
                                                value={education?.degreetype}
                                                onChange={(e) =>
                                                  handleEductionChange(
                                                    index,
                                                    "degreetype",
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  border: "none",
                                                  borderBottom:
                                                    "1px solid grey",
                                                }}
                                              />
                                            ) : (
                                              education.degreetype
                                            )}
                                          </p>
                                          <p className="bolded white">
                                            {EditEductionMode ? (
                                              <input
                                                type="text"
                                                value={education?.degreename}
                                                onChange={(e) =>
                                                  handleEductionChange(
                                                    index,
                                                    "degreename",
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  border: "none",
                                                  borderBottom:
                                                    "1px solid grey",
                                                }}
                                              />
                                            ) : (
                                              education.degreename
                                            )}
                                          </p>
                                          <p>
                                            {EditEductionMode ? (
                                              <input
                                                type="text"
                                                value={education.university}
                                                onChange={(e) =>
                                                  handleEductionChange(
                                                    index,
                                                    "university",
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  border: "none",
                                                  borderBottom:
                                                    "1px solid grey",
                                                }}
                                              />
                                            ) : (
                                              education.university
                                            )}
                                          </p>
                                          <p>
                                            {EditEductionMode ? (
                                              <>
                                                <label>Start Date</label>
                                                <input
                                                  type="date"
                                                  value={moment
                                                    .utc(education.startdate, "YYYY-MM-DD")
                                                    .format("YYYY-MM-DD")} // Ensure UTC is used to normalize the date
                                                  onChange={(e) =>
                                                    handleEductionChange(index, "startdate", e.target.value)
                                                  }
                                                  style={{
                                                    border: "none",
                                                    borderBottom: "1px solid grey",
                                                    width: "100%",
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                <label>End Date</label>
                                                <input
                                                  type="date"
                                                  value={moment
                                                    .utc(education.enddate, "YYYY-MM-DD")
                                                    .format("YYYY-MM-DD")}
                                                  onChange={(e) =>
                                                    handleEductionChange(
                                                      index,
                                                      "enddate",
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    border: "none",
                                                    borderBottom:
                                                      "1px solid grey",
                                                    width: "100%",
                                                    marginRight: "10px",
                                                  }}
                                                />
                                              </>
                                            ) : (
                                              `${moment.utc(
                                                education.startdate
                                              ).format("MM/YYYY")} - ${moment.utc(
                                                education.enddate
                                              ).format("MM/YYYY")}`
                                            )}
                                          </p>

                                          {/*<--------- Remove Button for Education Section's in the Edit Mode ----------->*/}
                                          {EditEductionMode && (
                                            <button
                                              className="btn btn-danger btn-sm"
                                              onClick={() =>
                                                handleRemoveEduction(index)
                                              } // Call remove function
                                            >
                                              Remove
                                            </button>
                                          )}
                                        </div>
                                      )
                                    )}

                                    {/* Show input fields when adding a new Eduction */}
                                    {isAddingEduction && (
                                      <>
                                        <div className="skill align-items-center">
                                          <div className="row">
                                            <div className="col-12">
                                              <input
                                                type="text"
                                                value={newEduction?.degreetype}
                                                onChange={(e) =>
                                                  handleNewEductionChange(
                                                    "degreetype",
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  border: "none",
                                                  borderBottom:
                                                    "1px solid grey",
                                                  marginRight: "10px",
                                                }}
                                                placeholder="Degree type"
                                              />
                                            </div>
                                            <div className="col-12">
                                              <input
                                                type="text"
                                                value={newEduction?.degreename}
                                                onChange={(e) =>
                                                  handleNewEductionChange(
                                                    "degreename",
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  border: "none",
                                                  borderBottom:
                                                    "1px solid grey",
                                                  marginRight: "10px",
                                                }}
                                                placeholder="Degree Name"
                                              />
                                            </div>
                                            <div className="col-12">
                                              <input
                                                type="text"
                                                value={newEduction?.university}
                                                onChange={(e) =>
                                                  handleNewEductionChange(
                                                    "university",
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  border: "none",
                                                  borderBottom:
                                                    "1px solid grey",
                                                }}
                                                placeholder="University"
                                              />
                                            </div>


                                            <div className="col-12">
                                              <label>Start Date</label>
                                              <input
                                                type="date"
                                                value={newEduction.startdate}
                                                onChange={handleStartDateChange}
                                                style={{
                                                  border: "none",
                                                  borderBottom: "1px solid grey",
                                                  width: "100%",
                                                  marginRight: "10px",
                                                }}
                                              />
                                            </div>
                                            <div className="col-12">
                                              <label>End Date</label>
                                              <input
                                                type="date"
                                                value={newEduction.enddate}
                                                onChange={handleEndDateChange}
                                                style={{
                                                  border: "none",
                                                  borderBottom: "1px solid grey",
                                                  width: "100%",
                                                  marginRight: "10px",
                                                }}
                                                min={newEduction.startdate || ""}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {/*<------------- Save/Cancel buttons for new skill ---------------->*/}
                                        <div className="row">
                                          <div className="col-6">
                                            <button
                                              className="btn btn-success btn-sm m-1"
                                              onClick={handleEductionNew}
                                            >
                                              Save
                                            </button>
                                          </div>
                                          <div className="col-6">
                                            <button
                                              className="btn btn-secondary btn-sm m-1"
                                              onClick={handleCancelNewEducation}
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    {/* Add new skill button */}
                                  </div>
                                </div>
                              </div>
                              <div className="rightPanel">
                                {/* <div>
                                  <h3>
                                    {StudentResume?.student_info?.fname}{" "}
                                    {StudentResume?.student_info?.lname}
                                  </h3>
                                </div> */}

                                {/* <------ Working Experience sections -------------> */}
                                <div className="workExperience">
                                  <div className="d-flex justify-content-between">
                                    <h4>Work Experience</h4>
                                    <div className="text-end">
                                      {StudentResume?.st_experience?.length ===
                                        0 ? (
                                        !isAddingNewExperience && (
                                          <button
                                            className="btn btn-primary m-1"
                                            onClick={handleAddExperience}
                                          >
                                            Add Experience
                                          </button>
                                        )
                                      ) : (
                                        <>
                                          {!isAddingNewExperience &&
                                            (EditWorkExpMode ? (
                                              <div>
                                                <button
                                                  className="btn btn-primary m-1"
                                                  onClick={
                                                    HandleProfessionalexperience
                                                  }
                                                >
                                                  Save
                                                </button>
                                                <button
                                                  className="btn btn-secondary m-1"
                                                  onClick={() =>
                                                    setEditWorkExpMode(false)
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            ) : (
                                              <>
                                                <button
                                                  className="btn btn-primary m-1"
                                                  onClick={handleAddExperience}
                                                >
                                                  Add Experience
                                                </button>
                                                <button
                                                  className="btn btn-primary m-1"
                                                  onClick={handleEditWorkExp}
                                                >
                                                  Edit
                                                </button>
                                              </>
                                            ))}
                                        </>
                                      )}
                                    </div>
                                  </div>

                                  {/* Work Experience List Rendering */}
                                  <ul>
                                    {StudentResume?.st_experience?.map(
                                      (experience, index) => (
                                        <li key={index}>
                                          <div className="jobPosition">
                                            <span className="bolded w-100">
                                              {EditWorkExpMode ? (
                                                <input
                                                  type="text"
                                                  placeholder="Job Title"
                                                  value={experience.p_title}
                                                  onChange={(e) =>
                                                    handleExperienceChange(
                                                      index,
                                                      "p_title",
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    border: "none",
                                                    borderBottom:
                                                      "1px solid grey",
                                                  }}
                                                />
                                              ) : (
                                                <>
                                                  {experience?.p_title && (
                                                    <h3>
                                                      {experience?.p_title}{" "}
                                                      <span>
                                                        ({experience?.emp_type})
                                                      </span>
                                                    </h3>
                                                  )}
                                                </>
                                              )}
                                            </span>
                                          </div>
                                          <div className="projectName bolded">
                                            <span>
                                              {EditWorkExpMode ? (
                                                <input
                                                  placeholder="Company"
                                                  value={experience?.company}
                                                  onChange={(e) =>
                                                    handleExperienceChange(
                                                      index,
                                                      "company",
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    border: "none",
                                                    borderBottom:
                                                      "1px solid grey",
                                                  }}
                                                />
                                              ) : (
                                                <div className="d-flex justify-content-between align-items-center gap-3">
                                                  <h6>{experience?.company}</h6>
                                                  {experience?.start_date && (
                                                    <small className="fw-normal small">
                                                      {moment.utc(
                                                        experience?.start_date
                                                      ).format(
                                                        "MM/DD/YYYY"
                                                      )}{" "}
                                                      -{" "}
                                                      <>
                                                        {experience?.end_date !=
                                                          null && (
                                                            <>
                                                              {moment.utc(
                                                                experience?.end_date
                                                              ).format(
                                                                "MM/DD/YYYY"
                                                              )}
                                                            </>
                                                          )}
                                                      </>
                                                    </small>
                                                  )}
                                                </div>
                                              )}
                                            </span>
                                          </div>

                                          {/* Employment Type */}
                                          <div className="smallText">
                                            <p>
                                              {EditWorkExpMode ? (
                                                <select
                                                  type="text"
                                                  placeholder="Employment Type"
                                                  value={
                                                    experience?.emp_type || ""
                                                  }
                                                  onChange={(e) =>
                                                    handleExperienceChange(
                                                      index,
                                                      "emp_type",
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    border: "none",
                                                    borderBottom:
                                                      "1px solid grey",
                                                  }}
                                                >
                                                  <option value="">
                                                    Select Employment Type
                                                  </option>{" "}
                                                  {/* Default option for empty value */}
                                                  <option value="Full time">
                                                    Full time
                                                  </option>
                                                  <option value="Part-time">
                                                    Part-time
                                                  </option>
                                                  <option value="Freelance">
                                                    Freelance
                                                  </option>
                                                  <option value="Trainee">
                                                    Trainee
                                                  </option>
                                                </select>
                                              ) : null // When not in edit mode, show the value
                                              }
                                            </p>
                                          </div>

                                          {/* Start Date */}
                                          <div className="smallText">
                                            <p>
                                              {EditWorkExpMode ? (
                                                // <input
                                                //   type="date"
                                                //   placeholder="Start Date"
                                                //   // value={
                                                //   //   experience?.start_date
                                                //   //     ? moment(experience.start_date).format("YYYY-MM-DD") // Convert to UTC and format
                                                //   //     : ""
                                                //   // }

                                                //   value={moment
                                                //     .utc(experience.start_date, "YYYY-MM-DD")
                                                //     .format("YYYY-MM-DD")}
                                                //   onChange={(e) =>
                                                //     handleExperienceChange(index, "start_date", e.target.value)
                                                //   }
                                                //   style={{
                                                //     border: "none",
                                                //     borderBottom: "1px solid grey",
                                                //   }}
                                                // />
                                                <input
                                                  type="date"
                                                  placeholder="Start Date"
                                                  value={
                                                    experience?.start_date
                                                      ? moment.utc(experience.start_date, "YYYY-MM-DD").format("YYYY-MM-DD") // Convert to UTC and format
                                                      : ""
                                                  }
                                                  onChange={(e) =>
                                                    handleExperienceChange(index, "start_date", e.target.value)
                                                  }
                                                  style={{
                                                    border: "none",
                                                    borderBottom: "1px solid grey",
                                                  }}
                                                />
                                              ) : null}
                                            </p>

                                          </div>

                                          {/* End Date */}
                                          <div className="smallText">
                                            <p>
                                              {EditWorkExpMode ? (
                                                <input
                                                  type="date"
                                                  placeholder="End Date"
                                                  // value={
                                                  //   experience?.end_date
                                                  //     ? moment(experience.end_date).format("YYYY-MM-DD") // Convert to UTC and format
                                                  //     : ""
                                                  // }
                                                  value={
                                                    experience?.end_date
                                                      ? moment.utc(experience.end_date, "YYYY-MM-DD").format("YYYY-MM-DD") // Convert to UTC and format
                                                      : ""
                                                  }
                                                  onChange={(e) =>
                                                    handleExperienceChange(index, "end_date", e.target.value)
                                                  }
                                                  style={{
                                                    border: "none",
                                                    borderBottom: "1px solid grey",
                                                  }}
                                                  min={
                                                    experience?.start_date
                                                      ? moment.utc(experience.start_date).format("YYYY-MM-DD") // Convert to UTC and format
                                                      : ""
                                                  }
                                                />
                                              ) : null}
                                            </p>

                                          </div>

                                          {/* Job Description */}
                                          <div className="smallText">
                                            <p>
                                              {EditWorkExpMode ? (
                                                <input
                                                  type="text"
                                                  placeholder="Job Description"
                                                  value={experience?.job_desc}
                                                  onChange={(e) =>
                                                    handleExperienceChange(
                                                      index,
                                                      "job_desc",
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    border: "none",
                                                    borderBottom:
                                                      "1px solid grey",
                                                  }}
                                                />
                                              ) : (
                                                <p>{experience?.job_desc}</p>
                                              )}
                                            </p>
                                          </div>
                                          {EditWorkExpMode ? (
                                            <div className="col-md-12 mb-3">
                                              <div className="form-group">
                                                <div className="agree-label d-flex gap-2">
                                                  <input
                                                    type="checkbox"
                                                    name="currently_working"
                                                    checked={experience?.currently_working === 1} // Checkbox state reflects the current value
                                                    onChange={(e) =>
                                                      handleExperienceChange(index, "currently_working", e.target.checked ? 1 : 0)
                                                    } // Updates currently_working based on checkbox state
                                                    id={`chb${index}`}
                                                    className="termcheck"
                                                  />
                                                  <label htmlFor={`chb${index}`} className="small text-muted">
                                                    I am currently working in this role
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="col-md-12 mb-3">
                                              <div className="form-group">
                                                <div className="agree-label d-flex gap-2">
                                                  {experience?.currently_working ==
                                                    1 && (
                                                      <>
                                                        <input
                                                          type="checkbox"
                                                          name="currently_working"
                                                          checked={
                                                            experience?.currently_working ===
                                                            1
                                                          } // Non-edit mode, only show the checked state
                                                          id={`chb${index}`}
                                                          className="termcheck"
                                                          readOnly // Prevents any change in non-edit mode
                                                        />
                                                        <label
                                                          htmlFor={`chb${index}`}
                                                          className="small text-muted"
                                                        >
                                                          I am currently working
                                                          in this role
                                                        </label>
                                                      </>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          {/* Remove Button */}
                                          {EditWorkExpMode && (
                                            <button
                                              className="btn btn-danger btn-sm mt-1"
                                              onClick={() =>
                                                handleRemoveExperience(index)
                                              }
                                            >
                                              Remove
                                            </button>
                                          )}
                                        </li>
                                      )
                                    )}
                                  </ul>

                                  {/* Form to add or edit an experience */}
                                  {isAddingNewExperience && (
                                    <div className="mt-4">
                                      <input
                                        type="text"
                                        placeholder="Job Title"
                                        value={newExp?.p_title}
                                        onChange={(e) =>
                                          handleNewExpChange(
                                            "p_title",
                                            e.target.value
                                          )
                                        }
                                        style={{
                                          border: "none",
                                          borderBottom: "1px solid grey",
                                          marginBottom: "10px",
                                        }}
                                      />

                                      {/* Employment Type Dropdown */}
                                      <select
                                        value={newExp?.emp_type}
                                        onChange={(e) =>
                                          handleNewExpChange(
                                            "emp_type",
                                            e.target.value
                                          )
                                        }
                                        style={{
                                          border: "none",
                                          borderBottom: "1px solid grey",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <option value="">
                                          Select Employment Type
                                        </option>
                                        <option value="Full time">
                                          Full time
                                        </option>
                                        <option value="Part-time">
                                          Part-time
                                        </option>
                                        <option value="Freelance">
                                          Freelance
                                        </option>
                                        <option value="Trainee">Trainee</option>
                                      </select>

                                      {/* Company Name Input */}
                                      <input
                                        type="text"
                                        placeholder="Company Name"
                                        value={newExp?.company}
                                        onChange={(e) =>
                                          handleNewExpChange(
                                            "company",
                                            e.target.value
                                          )
                                        }
                                        style={{
                                          border: "none",
                                          borderBottom: "1px solid grey",
                                          marginBottom: "10px",
                                        }}
                                      />

                                      {/* Start and End Dates */}
                                      <div>
                                        <input
                                          type="date"
                                          placeholder="Start Date"
                                          value={newExp?.start_date}
                                          onChange={(e) =>
                                            handleNewExpChange(
                                              "start_date",
                                              e.target.value
                                            )
                                          }
                                          style={{
                                            border: "none",
                                            borderBottom: "1px solid grey",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <input
                                          type="date"
                                          placeholder="End Date"
                                          value={newExp?.end_date}
                                          onChange={(e) => handleNewExpChange("end_date", e.target.value)}
                                          min={newExp?.start_date}
                                          style={{
                                            border: "none",
                                            borderBottom: "1px solid grey",
                                            marginLeft: "5px",
                                          }}
                                        />

                                      </div>

                                      {/* Location Input */}
                                      <input
                                        type="text"
                                        placeholder="Location"
                                        value={newExp?.location}
                                        onChange={(e) =>
                                          handleNewExpChange(
                                            "location",
                                            e.target.value
                                          )
                                        }
                                        style={{
                                          border: "none",
                                          borderBottom: "1px solid grey",
                                          marginBottom: "10px",
                                        }}
                                      />

                                      {/* Job Description Text Area */}
                                      <textarea
                                        placeholder="Job Description"
                                        value={newExp?.job_desc}
                                        onChange={(e) =>
                                          handleNewExpChange(
                                            "job_desc",
                                            e.target.value
                                          )
                                        }
                                        style={{
                                          border: "none",
                                          borderBottom: "1px solid grey",
                                        }}
                                      />

                                      <div className="form-group mt-2">
                                        <input
                                          type="checkbox"
                                          name="currently_working"
                                          checked={newExp?.currently_working === 1} // Ensure the checkbox reflects the current state
                                          onChange={(e) => {
                                            handleNewExpChange("currently_working", e.target.checked ? 1 : 0);
                                            setCheckedval(e.target.checked); // Optionally, you can manage the checked state here as well
                                          }}
                                          id="currentlyWorkingCheckbox"
                                          className="termcheck"
                                        />
                                        <label
                                          htmlFor="currentlyWorkingCheckbox"
                                          className="small text-muted"
                                        >
                                          I am currently working in this role
                                        </label>
                                      </div>


                                      {/* Save and Cancel Buttons */}
                                      <div className="mt-2">
                                        <button
                                          className="btn btn-success mt-2"
                                          onClick={handleSaveNewExperience}
                                        >
                                          Save
                                        </button>
                                        <button
                                          className="btn btn-secondary mt-2 ms-2"
                                          onClick={() => {
                                            setIsAddingNewExp(false);
                                            setEditWorkExpMode(false);
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                {/* <------ Projects sections -------------> */}
                                <div className="workExperience">
                                  <div className="d-flex justify-content-between">
                                    <h4>Projects</h4>
                                    <div className="text-end">
                                      {StudentResume?.university_projects
                                        ?.length === 0 ? (
                                        <>
                                          {!isAddingNewProject && (
                                            <button
                                              className="btn btn-primary m-1"
                                              onClick={handleAddProject}
                                            >
                                              Add Project
                                            </button>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {!isAddingNewProject && (
                                            <>
                                              {EditProjectsMode ? (
                                                <div>
                                                  <button
                                                    className="btn btn-primary m-1"
                                                    onClick={
                                                      HandleEditProjectSection
                                                    }
                                                  >
                                                    Save
                                                  </button>
                                                  <button
                                                    className="btn btn-secondary m-1"
                                                    onClick={() =>
                                                      setEditProjectsMode(false)
                                                    }
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              ) : (
                                                <>
                                                  <button
                                                    className="btn btn-primary m-1"
                                                    onClick={handleAddProject}
                                                  >
                                                    Add Project
                                                  </button>
                                                  <button
                                                    className="btn btn-primary m-1"
                                                    onClick={handleEditProjects}
                                                  >
                                                    Edit
                                                  </button>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>

                                  {/* Project List Rendering */}
                                  <ul>
                                    {StudentResume?.university_projects?.map(
                                      (project, index) => (
                                        <li key={index}>
                                          <div className="jobPosition">
                                            <span className="bolded">
                                              {EditProjectsMode ? (
                                                <input
                                                  type="text"
                                                  placeholder="Project Title"
                                                  value={project.title}
                                                  onChange={(e) =>
                                                    handleProjectsChange(
                                                      index,
                                                      "title",
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    border: "none",
                                                    borderBottom:
                                                      "1px solid grey",
                                                  }}
                                                />
                                              ) : (
                                                project.title
                                              )}
                                            </span>
                                          </div>
                                          <div className="projectName bolded">
                                            <span>
                                              {EditProjectsMode ? (
                                                <textarea
                                                  placeholder="Project Link"
                                                  value={project.websitelink}
                                                  onChange={(e) =>
                                                    handleProjectsChange(
                                                      index,
                                                      "websitelink",
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    border: "none",
                                                    borderBottom:
                                                      "1px solid grey",
                                                  }}
                                                />
                                              ) : (
                                                <a
                                                  href={project.websitelink}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {project.websitelink}
                                                </a>
                                              )}
                                            </span>
                                          </div>
                                          <div className="smallText">
                                            <p>
                                              {EditProjectsMode ? (
                                                <input
                                                  type="text"
                                                  placeholder="Your Role"
                                                  value={project.yourrole}
                                                  onChange={(e) =>
                                                    handleProjectsChange(
                                                      index,
                                                      "yourrole",
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    border: "none",
                                                    borderBottom:
                                                      "1px solid grey",
                                                  }}
                                                />
                                              ) : (
                                                project.yourrole
                                              )}
                                            </p>
                                          </div>

                                          {/* Remove Button */}
                                          {EditProjectsMode && (
                                            <button
                                              className="btn btn-danger btn-sm mt-1"
                                              onClick={() =>
                                                handleRemoveProject(index)
                                              }
                                            >
                                              Remove
                                            </button>
                                          )}
                                        </li>
                                      )
                                    )}
                                  </ul>

                                  {/* Input fields for adding a new project */}
                                  {isAddingNewProject && (
                                    <div className="mt-4">
                                      <input
                                        type="text"
                                        placeholder="Project Title"
                                        value={newProject.title}
                                        onChange={(e) =>
                                          handleNewProjectChange(
                                            "title",
                                            e.target.value
                                          )
                                        }
                                        style={{
                                          border: "none",
                                          borderBottom: "1px solid grey",
                                          marginBottom: "10px",
                                        }}
                                      />
                                      <input
                                        type="text"
                                        placeholder="Project Link"
                                        value={newProject.websitelink}
                                        onChange={(e) =>
                                          handleNewProjectChange(
                                            "websitelink",
                                            e.target.value
                                          )
                                        }
                                        style={{
                                          border: "none",
                                          borderBottom: "1px solid grey",
                                          marginBottom: "10px",
                                        }}
                                      />
                                      <input
                                        type="text"
                                        placeholder="Your Role"
                                        value={newProject.yourrole}
                                        onChange={(e) =>
                                          handleNewProjectChange(
                                            "yourrole",
                                            e.target.value
                                          )
                                        }
                                        style={{
                                          border: "none",
                                          borderBottom: "1px solid grey",
                                        }}
                                      />
                                      <div className="mt-2">
                                        <button
                                          className="btn btn-success mt-2"
                                          onClick={handleSaveNewProject}
                                        >
                                          Save
                                        </button>
                                        <button
                                          className="btn btn-secondary mt-2 ms-2"
                                          onClick={() => {
                                            setIsAddingNewProject(false);
                                            setEditProjectsMode(false);
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                {/* <------ Certificate sections -------------> */}
                                <div className="workExperience">
                                  <div className="d-flex justify-content-between">
                                    <h4>Certificate</h4>
                                    <div className="text-end">
                                      {!isAddingCertificate &&
                                        !EditCertificateMode &&
                                        StudentResume?.student_certification
                                          ?.length > 0 && (
                                          <button
                                            className="btn btn-primary "
                                            onClick={HandleAddCertifications}
                                          >
                                            Add Certificate
                                          </button>
                                        )}
                                      {StudentResume?.student_certification
                                        ?.length === 0 ? (
                                        <button
                                          className="btn btn-primary m-1"
                                          onClick={HandleAddCertifications}
                                        >
                                          Add Certificate
                                        </button>
                                      ) : (
                                        <>
                                          {!isAddingCertificate && (
                                            <>
                                              {EditCertificateMode ? (
                                                <div>
                                                  <button
                                                    className="btn btn-primary m-1"
                                                    onClick={
                                                      HandleEditCertificateSection
                                                    }
                                                  >
                                                    Save
                                                  </button>
                                                  <button
                                                    className="btn btn-secondary m-1"
                                                    onClick={() =>
                                                      setEditCertificateMode(
                                                        false
                                                      )
                                                    }
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              ) : (
                                                <button
                                                  className="btn btn-primary m-1"
                                                  onClick={() =>
                                                    setEditCertificateMode(true)
                                                  }
                                                >
                                                  Edit
                                                </button>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <ul>
                                    {StudentResume?.student_certification?.map(
                                      (certificate, index) => (
                                        <li key={index}>
                                          <div className="jobPosition">
                                            <span className="bolded">
                                              {EditCertificateMode ? (
                                                <input
                                                  type="text"
                                                  value={
                                                    certificate?.certificate
                                                  }
                                                  name="certificate"
                                                  onChange={(e) =>
                                                    handleCertificateChange(
                                                      index,
                                                      "certificate",
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    border: "none",
                                                    borderBottom:
                                                      "1px solid grey",
                                                    width: "250px",
                                                  }}
                                                />
                                              ) : (
                                                certificate.certificate
                                              )}
                                            </span>
                                            <span>
                                              {EditCertificateMode ? (
                                                <input
                                                  type="date"
                                                  name="certification_date"
                                                  // value={moment(
                                                  //   certificate?.certification_date
                                                  // ).format("YYYY-MM-DD")}
                                                  value={
                                                    certificate?.certification_date
                                                      ? moment.utc(certificate?.certification_date, "YYYY-MM-DD").format("YYYY-MM-DD") // Convert to UTC and format
                                                      : ""
                                                  }
                                                  onChange={(e) =>
                                                    handleCertificateChange(
                                                      index,
                                                      "certification_date",
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    border: "none",
                                                    borderBottom:
                                                      "1px solid grey",
                                                  }}
                                                />
                                              ) : (
                                                <p>
                                                  (
                                                  {moment.utc(
                                                    certificate?.certification_date
                                                  ).format("MM/DD/YYYY")}
                                                  )
                                                </p>
                                              )}
                                            </span>
                                          </div>
                                          <div className="projectName bolded">
                                            <span className="bolded">
                                              {EditCertificateMode ? (
                                                <input
                                                  type="text"
                                                  value={
                                                    certificate?.organication
                                                  }
                                                  name="organication"
                                                  onChange={(e) =>
                                                    handleCertificateChange(
                                                      index,
                                                      "organication",
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    border: "none",
                                                    borderBottom:
                                                      "1px solid grey",
                                                  }}
                                                />
                                              ) : (
                                                <p>
                                                  {certificate?.organication}
                                                </p>
                                              )}
                                            </span>
                                          </div>
                                          {/* Remove button */}
                                          {EditCertificateMode && (
                                            <button
                                              className="btn btn-danger btn-sm m-1"
                                              onClick={() =>
                                                handleRemoveCertificate(index)
                                              }
                                            >
                                              Remove
                                            </button>
                                          )}
                                        </li>
                                      )
                                    )}

                                    {/* Show input fields when adding a new certificate */}
                                    {isAddingCertificate && (
                                      <li>
                                        <div className="jobPosition">
                                          <span className="bolded">
                                            <input
                                              type="text"
                                              value={newCertificate.certificate}
                                              onChange={(e) =>
                                                handleNewCertificateChange(
                                                  "certificate",
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                border: "none",
                                                borderBottom: "1px solid grey",
                                                width: "250px",
                                              }}
                                              placeholder="New Certificate"
                                            />
                                          </span>
                                          <span>
                                            <input
                                              type="date"
                                              value={
                                                newCertificate.certification_date
                                              }
                                              onChange={(e) =>
                                                handleNewCertificateChange(
                                                  "certification_date",
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                border: "none",
                                                borderBottom: "1px solid grey",
                                              }}
                                              placeholder="Date"
                                            />
                                          </span>
                                        </div>
                                        <div className="projectName bolded">
                                          <span className="bolded">
                                            <input
                                              type="text"
                                              value={
                                                newCertificate.organication
                                              }
                                              name="organication"
                                              onChange={(e) =>
                                                handleNewCertificateChange(
                                                  "organication",
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                border: "none",
                                                borderBottom: "1px solid grey",
                                              }}
                                              placeholder="Organisation"
                                            />
                                          </span>
                                        </div>

                                        {/* Save/Cancel buttons for new certificate */}
                                        <button
                                          className="btn btn-success btn-sm m-1"
                                          onClick={handleSaveNewCertificate}
                                        >
                                          Save
                                        </button>
                                        <button
                                          className="btn btn-secondary btn-sm m-1"
                                          onClick={() => {
                                            setIsAddingCertificate(false);
                                            setTimeout(() => {
                                              window.location.reload();
                                            }, 1000);
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      </li>
                                    )}

                                    {/* Add new certificate button (conditional) */}
                                  </ul>
                                </div>

                                {/* <------ Training Program sections -------------> */}
                                {/* <--------- Old Training Program code here ----------------> */}
                                {/* <div className="workExperience">
                                  <div className="d-flex justify-content-between">
                                    <h5>Training Program</h5>
                                    <div className="text-end">
                                      <div className="float-right mb-3">
                                        {AddTraningProgramEditMode ? (
                                          <Link
                                            to="#"
                                            id="save"
                                            onClick={HandleTraningProgramSection}
                                            className="btn btn-primary m-1"
                                          >
                                            Save
                                          </Link>
                                        ) : (
                                          <>
                                            <Link
                                              to="#"
                                              id="add"
                                              onClick={HandleAddTraningProgram}
                                              className="btn btn-primary m-1"
                                            >
                                              Add +
                                            </Link>
                                            <Link
                                              to="#"
                                              id="edit"
                                              onClick={HandleEditTraningProgram}
                                              className="btn btn-primary m-1"
                                            >
                                              Edit
                                            </Link>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {AddTraningProgram?.map((program, index) => (
                                    <div key={program.id}>
                                      <hr />
                                      <div className="professional-sp row">
                                        {AddTraningProgramShow && (
                                          <>

                                            <div className="text-end">
                                              <IoClose
                                                style={{
                                                  color: "red",
                                                  fontSize: "2rem",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => HandleRemoveTraningProgram(program.id)}
                                              />
                                            </div>

                                          </>
                                        )}
                                        <ul>
                                          <li>
                                            <div className="col-md-12">
                                              <div className="form-group">
                                                {(isAddingMode || AddTraningProgramEditMode) ? (
                                                  <>
                                                    <select
                                                      name={`title${program?.id}`}
                                                      value={program?.title}
                                                      onChange={(e) => {
                                                        const updatedPrograms = [...AddTraningProgram];
                                                        updatedPrograms[index].title = e.target.value;
                                                        setAddTraningProgram(updatedPrograms);
                                                      }}
                                                    >
                                                      <option value="" disabled>Select Certification...</option>
                                                      {CourseList?.map((CourseListResult) => (
                                                        <option key={CourseListResult.id} value={CourseListResult.title}>
                                                          {CourseListResult.title}
                                                        </option>
                                                      ))}
                                                    </select>

                                                    <input
                                                      type="text"
                                                      name={`duration${program.id}`}
                                                      value={program?.duration}
                                                      onChange={(e) => {
                                                        const updatedPrograms = [...AddTraningProgram];
                                                        updatedPrograms[index].duration = e.target.value;
                                                        setAddTraningProgram(updatedPrograms);
                                                      }}
                                                      placeholder="Duration..."
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <h3>{program?.title}</h3>
                                                    <p>{program?.duration}</p>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ))}
                                </div> */}

                                {/* New Training Program code here -----  */}
                                <div className="workExperience">
                                  <div className="d-flex justify-content-between">
                                    <h4>Training Program</h4>
                                    <div className="text-end">
                                      {!isAddingTrainingProgram &&
                                        !EditTrainingProgramMode &&
                                        StudentResume?.complete_course?.length >
                                        0 && (
                                          <button
                                            className="btn btn-primary "
                                            onClick={HandleAddTraningProgram}
                                          >
                                            Add
                                          </button>
                                        )}
                                      {StudentResume?.complete_course
                                        ?.length === 0 ? (
                                        <button
                                          className="btn btn-primary m-1"
                                          onClick={HandleAddTraningProgram}
                                        >
                                          Add
                                        </button>
                                      ) : (
                                        <>
                                          {!isAddingTrainingProgram && (
                                            <>
                                              {EditTrainingProgramMode ? (
                                                <div>
                                                  <button
                                                    className="btn btn-primary m-1"
                                                    onClick={
                                                      HandleTraningProgramSection
                                                    }
                                                  >
                                                    Save
                                                  </button>
                                                  <button
                                                    className="btn btn-secondary m-1"
                                                    onClick={() =>
                                                      setEditTrainingProgramMode(
                                                        false
                                                      )
                                                    }
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              ) : (
                                                <button
                                                  className="btn btn-primary m-1"
                                                  onClick={() =>
                                                    setEditTrainingProgramMode(
                                                      true
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </button>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <ul>
                                    {StudentResume?.complete_course?.map(
                                      (certificate, index) => (
                                        <li key={index}>
                                          <div className="jobPosition">
                                            <span className="bolded">
                                              {EditTrainingProgramMode ? (
                                                // <input
                                                //   type="text"
                                                //   value={
                                                //     certificate?.title
                                                //   }
                                                //   name="certificate"
                                                //   onChange={(e) =>
                                                //     handleTraingProgramChange(
                                                //       index,
                                                //       "certificate",
                                                //       e.target.value
                                                //     )
                                                //   }
                                                //   style={{
                                                //     border: "none",
                                                //     borderBottom:
                                                //       "1px solid grey",
                                                //     width: "250px",
                                                //   }}
                                                // />

                                                <select
                                                  name="title"
                                                  // value={program?.title || ""}  // This will default to empty if no program title is selected
                                                  onChange={(e) =>
                                                    handleTraingProgramChange(
                                                      index,
                                                      "title",
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    Select Training Program...
                                                  </option>{" "}
                                                  {/* Default option */}
                                                  {CourseList?.map(
                                                    (CourseListResult) => (
                                                      <option
                                                        key={
                                                          CourseListResult.id
                                                        }
                                                        value={
                                                          CourseListResult.title
                                                        }
                                                      >
                                                        {CourseListResult.title}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              ) : (
                                                certificate?.title
                                              )}
                                            </span>
                                          </div>
                                          <div>
                                            {EditTrainingProgramMode ? (
                                              <input
                                                type="text"
                                                name="duration"
                                                value={certificate?.duration}
                                                onChange={(e) =>
                                                  handleTraingProgramChange(
                                                    index,
                                                    "duration",
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  border: "none",
                                                  borderBottom:
                                                    "1px solid grey",
                                                }}
                                                placeholder="duration"
                                              />
                                            ) : (
                                              <p>{certificate?.duration}</p>
                                            )}
                                          </div>

                                          <div>
                                            {EditTrainingProgramMode ? (
                                              <input
                                                type="text"
                                                name="description"
                                                value={certificate?.description}
                                                onChange={(e) =>
                                                  handleTraingProgramChange(
                                                    index,
                                                    "description",
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  border: "none",
                                                  borderBottom:
                                                    "1px solid grey",
                                                }}
                                                placeholder="description"
                                              />
                                            ) : (
                                              <div>
                                                <p>
                                                  {certificate?.description}
                                                </p>
                                              </div>
                                            )}
                                          </div>
                                          {/* Remove button */}
                                          {EditTrainingProgramMode && (
                                            <button
                                              className="btn btn-danger btn-sm m-1"
                                              onClick={() =>
                                                HandleRemoveTraningProgram(
                                                  index
                                                )
                                              }
                                            >
                                              Remove
                                            </button>
                                          )}
                                        </li>
                                      )
                                    )}

                                    {/* Show input fields when adding a new certificate */}
                                    {isAddingTrainingProgram && (
                                      <li>
                                        <div className="jobPosition">
                                          <span className="bolded">
                                            {/* <input
                                              type="text"
                                              value={newCertificate.certificate}
                                              onChange={(e) =>
                                                handleNewCertificateChange(
                                                  "certificate",
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                border: "none",
                                                borderBottom: "1px solid grey",
                                                width: "250px",
                                              }}
                                              placeholder="New Certificate"
                                            /> */}

                                            <select
                                              value={newTraingProgram?.title} // Default to empty if newSkill.expertise is undefined
                                              onChange={(e) =>
                                                handleNewTraingProgramChange(
                                                  "title",
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option value="" disabled>
                                                Select Certification...
                                              </option>
                                              {CourseList?.map(
                                                (CourseListResult) => (
                                                  <option
                                                    key={CourseListResult.id}
                                                    value={
                                                      CourseListResult.title
                                                    }
                                                  >
                                                    {CourseListResult.title}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </span>
                                        </div>
                                        <div>
                                          <input
                                            type="text"
                                            value={newCertificate.duration}
                                            onChange={(e) =>
                                              handleNewTraingProgramChange(
                                                "duration",
                                                e.target.value
                                              )
                                            }
                                            style={{
                                              border: "none",
                                              borderBottom: "1px solid grey",
                                            }}
                                            placeholder="Duration"
                                          />
                                        </div>
                                        <div>
                                          <input
                                            type="text"
                                            value={newCertificate.description}
                                            onChange={(e) =>
                                              handleNewTraingProgramChange(
                                                "description",
                                                e.target.value
                                              )
                                            }
                                            style={{
                                              border: "none",
                                              borderBottom: "1px solid grey",
                                            }}
                                            placeholder="Description"
                                          />
                                        </div>
                                        {/* Save/Cancel buttons for new certificate */}
                                        <button
                                          className="btn btn-success btn-sm m-1"
                                          onClick={handleSaveNewTraingProgram}
                                        >
                                          Save
                                        </button>
                                        <button
                                          className="btn btn-secondary btn-sm m-1"
                                          onClick={() => {
                                            setIsAddingTrainingProgram(false);
                                            setTimeout(() => {
                                              window.location.reload();
                                            }, 1000);
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      </li>
                                    )}

                                    {/* Add new certificate button (conditional) */}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </page>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <-------- Student Footer section's ----------> */}
      <Student_Footer />
    </div>
  );
};

export default Student_Resume;
