


import React, { useState, useEffect } from 'react';
import { Student_Attempt_Update } from '../../../../api/Student';

const Student_Timer_Countdown = () => {
  const [timeLeft, setTimeLeft] = useState(null);

  
  // <---------- User Authentciation token's ------------>
  const token = localStorage.getItem("User_Token");
  const headers = {
    "Authorization": `Bearer ${token}`
  }

  useEffect(() => {
    const timerStartDate = new Date(localStorage.getItem('timer_start_date'));
    const timerDate = new Date(localStorage.getItem('timer_date'));
    const currentDate = new Date();

    if (currentDate >= timerStartDate) {
      startCountdown(timerDate);
    }
  }, []);

  const startCountdown = (endDate) => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const newTimeLeft = endDate - now;

      if (newTimeLeft <= 0) {
        clearInterval(intervalId);
        setTimeLeft(0);
        Handle_Student_Attempt_Update(); // Call API and reload on completion
      } else {
        setTimeLeft(newTimeLeft);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  };

  const Handle_Student_Attempt_Update = async () => {
    const course_id = localStorage.getItem("course_id");
    const current_timer_id = localStorage.getItem("current_timer_id");
    const User_id = localStorage.getItem("User_id");

    const data = {
      course_id,
      class_id: current_timer_id,
      student_id: User_id,
    };

    try {
      const response = await Student_Attempt_Update(data,headers);
      console.log("Update Successful:", response);
      if(response?.data?.statusCode == "200"){
        alert(response?.data?.msg)
        window.location.reload(); 
      }
      else if(response?.data?.statusCode == "400"){
        alert(response?.data?.msg)
      }
    } catch (error) {
      console.error("Update Failed:", error);
    }
  };

  const formatTimeLeft = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <div>
      {timeLeft !== null && (
        <h6 className="text-success">{formatTimeLeft(timeLeft)}</h6>
      ) }
    </div>
  );
};

export default Student_Timer_Countdown;
