// import React, { useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';

// const AutoLogout = ({ timeout = 15 * 60 * 1000 }) => {
//   const timerRef = useRef(null);
//   const navigate = useNavigate();

//   const logout = () => {
//     localStorage.clear();
//     navigate('/Signin'); 
//     window.location.reload();
//   };

//   const resetTimer = () => {
//     if (timerRef.current) clearTimeout(timerRef.current);
//     timerRef.current = setTimeout(logout, timeout);
//   };

//   useEffect(() => {
//     const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];

//     events.forEach((event) => window.addEventListener(event, resetTimer));
//     resetTimer();

//     return () => {
//       events.forEach((event) => window.removeEventListener(event, resetTimer));
//       if (timerRef.current) clearTimeout(timerRef.current);
//     };
//   }, [timeout]);

//   return null;
// };

// export default AutoLogout;


// // import React, { useEffect, useRef } from 'react';
// // import { useNavigate, useLocation } from 'react-router-dom';

// // const AutoLogout = ({ timeout = 1 * 60 * 1000 }) => {
// //   const timerRef = useRef(null);
// //   const navigate = useNavigate();
// //   const location = useLocation();

// //   const logout = () => {
// //     localStorage.clear();
// //     navigate('/Signin'); // Redirect to the Signin page
// //   };

// //   const resetTimer = () => {
// //     if (timerRef.current) clearTimeout(timerRef.current);
// //     timerRef.current = setTimeout(() => {
// //       if (location.pathname !== '/Signin') {
// //         logout();
// //       }
// //     }, timeout);
// //   };

// //   useEffect(() => {
// //     const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];

// //     events.forEach((event) => window.addEventListener(event, resetTimer));
// //     resetTimer();

// //     return () => {
// //       events.forEach((event) => window.removeEventListener(event, resetTimer));
// //       if (timerRef.current) clearTimeout(timerRef.current);
// //     };
// //   }, [timeout, location]);

// //   useEffect(() => {
// //     // Automatically redirect if the user navigates after logout
// //     if (!localStorage.getItem('authToken') && location.pathname !== '/Signin') {
// //       navigate('/Signin');
// //     }
// //   }, [navigate, location]);

// //   return null;
// // };

// // export default AutoLogout;

import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AutoLogout = ({ timeout = 120 * 60 * 1000 }) => {
  const timerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    localStorage.clear();
    navigate('/Signin');
    window.location.reload();
  };

  const resetTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(logout, timeout);
  };

  const debounceResetTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    setTimeout(resetTimer, 300); // Debounce by 300ms to avoid rapid resets
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        resetTimer();
      }
    };

    const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart', 'visibilitychange'];

    events.forEach((event) => window.addEventListener(event, debounceResetTimer));
    document.addEventListener('visibilitychange', handleVisibilityChange);

    resetTimer();

    return () => {
      events.forEach((event) => window.removeEventListener(event, debounceResetTimer));
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [timeout, location]);

  return null;
};

export default AutoLogout;
