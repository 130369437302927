import React, { Component, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const Team_Member_LoggedIn_Protected = (props) => {
    const {Component} = props
    const navigate = useNavigate();
    useEffect(() => {
        let team_token = localStorage.getItem("team_token")
        if(team_token){
         navigate("/team_member_feedback")
        }
       
    },[navigate])
    return (
        <div>
         <Component/>
        </div>
    )
}

export default Team_Member_LoggedIn_Protected