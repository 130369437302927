import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Home1 from '../../../../../assets/images/Home-1-Video.mp4';
import CommonImageTransport from '../../../../common/images/CommonImages';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
const MentorSupportAndStatics = () => {
      const navigate = useNavigate();
      function Counter({ icon, targetCount, label }) {
            const [count, setCount] = useState(0);
            useEffect(() => {
              const duration = 3000; 
              const interval = duration / targetCount; 
          
              const intervalId = setInterval(() => {
                setCount(prevCount => {
                  if (prevCount === targetCount) {
                    clearInterval(intervalId);
                    return targetCount;
                  } else {
                    return prevCount + 1;
                  }
                });
              }, interval);
          
              return () => clearInterval(intervalId);
            }, [targetCount]); 
          
            return (
              <div className="col-lg-6 col-6">
                <div className="counter-content">
                  <i className={icon}></i>
                  <h3>{count}</h3>
                  <p>{label}</p>
                </div>
              </div>
            );
          }
          
          const AddAllMentors = async (e) => {
            e.preventDefault();
            const role_id = localStorage.getItem("role_id");
            if (role_id === "2") {
                  navigate("/Student/Student_Mentor_List");
            } else if (role_id === "3" || role_id === "4") {
                  toast.error("You Can't access !!!");
                  setTimeout(() => {
                        navigate("/home");
                  }, 2000);
            }
            else {
                  toast.error("User Not Sign in here !!!");
                  setTimeout(() => {
                        navigate("/Signin");
                  }, 2000);
            }
      }
      return (
            <>
                  <div className="enrolled-area-two py-5">
                        <div className="container">
                              <div className="row align-items-center">
                                    <div className="col-lg-6">
                                          <div className="row">
                                                <div className="video_players">
                                                      <video width="100%" className="rounded" autoPlay muted playsInline loop>
                                                            <source src={Home1} type="video/mp4" />
                                                      </video>
                                                </div>
                                          </div>
                                    </div>

                                    <div className="col-lg-6">
                                          <div className="enrolled-content mb-30">
                                                <div className="section-title">
                                                      <span>FLEXIBLE SUPPORTED LEARNING</span>
                                                      <h2>Expertise Across All Disciplines with</h2>
                                                      <p>Education is a cornerstone of human development, equipping individuals with knowledge, skills,
                                                             and opportunities to learn and grow.
                                                             It includes both formal learning institutions and broader avenues for acquiring new information.</p>
                                                </div>
                                                {/* <div className="row">
                                                      <div className="col-lg-6 col-6">
                                                            <div className="counter-content">
                                                                  <i className="flaticon-online-course"></i>
                                                                  <h3><span class="odometer" data-count="15000">00000</span>+</h3>
                                                                  <p>Courses & videos</p>
                                                            </div>
                                                      </div>
                                                      <div className="col-lg-6 col-6">
                                                            <div className="counter-content">
                                                                  <i className="flaticon-student"></i>
                                                                  <h3><span class="odometer" data-count="145000">000000</span>+</h3>
                                                                  <p>Students enrolled</p>
                                                            </div>
                                                      </div>
                                                      <div className="col-lg-6 col-6">
                                                            <div className="counter-content">
                                                                  <i className="flaticon-online-course-1"></i>
                                                                  <h3><span class="odometer" data-count="10000">00000</span>+</h3>
                                                                  <p>Courses instructors</p>
                                                            </div>
                                                      </div>
                                                      <div className="col-lg-6 col-6">
                                                            <div className="counter-content">
                                                                  <i className="flaticon-customer-satisfaction"></i>
                                                                  <h3><span class="odometer" data-count="100">000</span>%</h3>
                                                                  <p>Satisfaction rate</p>
                                                            </div>
                                                      </div>
                                                </div> */}
                                                <div className="row">
                                                      <Counter icon="flaticon-online-course" targetCount={15000} label="Courses & videos" />
                                                      <Counter icon="flaticon-student" targetCount={145000} label="Students enrolled" />
                                                      <Counter icon="flaticon-online-course-1" targetCount={10000} label="Courses instructors" />
                                                      <Counter icon="flaticon-customer-satisfaction" targetCount={100} label="Satisfaction rate" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>

                  <div className="featured-area bg-light py-5">
                        <div className="container">
                              <div className="row align-items-center mb-45">
                                    <div className="col-lg-8 col-md-9">
                                          <div className="section-title mt-rs-20">
                                                <h2>Personalised Mentor Support</h2>
                                                <p>Select a mentor from a pool of industry experts & get 1-on-1 mentorship!</p>
                                          </div>
                                    </div>
                                    <div className="col-lg-4  col-md-3 text-end">
                                          <Link to="/Mentorship" className="default-btn border-radius-50">View more</Link>
                                    </div>
                              </div>
                              <div className="row">
                                    <div className="col-lg-12 col-12">
                                          <div className="featured-item-one">
                                                <img src={CommonImageTransport.menter} alt="" className="rounded img-fluid" />
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div></>
      )
}

export default MentorSupportAndStatics