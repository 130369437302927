import React, { useState, useEffect } from 'react';
import Student_Header from '../student_header/Student_Header';
import Typewriter from 'typewriter-effect';
import CommonImageTransport from '../../../common/images/CommonImages';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import { GetStudentmentorbookinglist } from '../../../../api/Mentor';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../../../loader/Loader';
import Student_Footer from '../student_footer/Student_Footer';

const Student_Upcoming_Mentor_list = () => {
    const navigate = useNavigate()
    const [is_Loading, setIs_Loading] = useState(false);
    const [MentorListById, setMentorListById] = useState("");
    const [MentorUpcomingList, setMentorUpcomingList] = useState([])
    const [ActiveTabs, setActiveTabs] = useState(MentorUpcomingList?.id)
    const [showSearchFields, setShowSearchFields] = useState(false);
    const token = localStorage.getItem("User_Token");
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredUpComingMentorList, setFilteredUpComingMentorList] = useState([]);
    const defaultTabIndex = 0;
    // <------- User Authorization Token's ----------->
    const headers = {
        "Authorization": `Bearer ${token}`
    }
    // <----------- User Active & UnActive -----------> 
    const HandleActiveTabs = (tabs) => {
        setActiveTabs(tabs)
    }

    // <--------  Get Mentor List By Id---------------->
    const GetMentorListById = (id) => {
        setMentorListById(id);
    }

    // <-------------- Handle the seach Filter data ---------->
    const handlesearchinputChange = (e) => {
        setSearchQuery(e.target.value)
    };

    // <----------- Get Student Mentor Booking list------------>
    const GetMentorUpcomingSession = async () => {
        setIs_Loading(true)
        try {
            const HandleStudentmentorbookinglistData = {
                status: 2
            }
            const response = await GetStudentmentorbookinglist(HandleStudentmentorbookinglistData, headers);
            if (response?.data?.statusCode == 401) {
                localStorage.clear();
                navigate("/Signin")
                window.location.reload()
              }
            setMentorUpcomingList(response?.data?.data?.booking)
            setIs_Loading(false)
        } catch (error) {
            setIs_Loading(true)
        }

    }

    // <--------- get the Filterd data when we render on this page ------------>
    useEffect(() => {
        const searchTerms = searchQuery.toLowerCase().split(/[\s,]+/); // Split search query by spaces or commas
    
        const filtered = MentorUpcomingList?.filter((MentorListResult) => {
            const fullName = `${MentorListResult?.fname} ${MentorListResult?.lname}`.toLowerCase();
    
            // Check if any of the search terms match city, state, country, or other fields
            const matchesCityStateCountry = searchTerms?.some((term) =>
                MentorListResult?.city?.toLowerCase().includes(term) ||
                MentorListResult?.state?.toLowerCase().includes(term) ||
                MentorListResult?.country?.toLowerCase().includes(term)
            );
    
            return (
                MentorListResult?.fname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                MentorListResult?.lname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                fullName.includes(searchQuery.toLowerCase()) || // Check full name (fname + space + lname)
                matchesCityStateCountry || // Check for matches in city, state, country
                MentorListResult?.profession?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                MentorListResult?.email?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        });
    
        setFilteredUpComingMentorList(filtered);
    }, [MentorUpcomingList, searchQuery]);
    


    // <-------- Get the Mentor Upcoming session's ------------->
    useEffect(() => {
        GetMentorListById();
        GetMentorUpcomingSession();
    }, [])



     // Automatically update ActiveTabs when the filteredList changes
     useEffect(() => {
        if (filteredUpComingMentorList?.length > 0 && (!ActiveTabs || !filteredUpComingMentorList?.some(job => job.id === ActiveTabs))) {
            const firstActiveTab = filteredUpComingMentorList[0];
            if (firstActiveTab) {
                HandleActiveTabs(firstActiveTab?.id);
            }
        }
    }, [filteredUpComingMentorList, ActiveTabs]);


    return (
        <div>
            {
                is_Loading ? (
                    <>
                        <Loader />
                    </>

                ) : (
                    <>
                        <div>
                            <div className="bg_nav-slid bg-white">
                                <Student_Header />
                            </div>
                            <div className="header_space"></div>
                            <div className="recruiter_list pb-5">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 my-2">
                                            <ul className="nav nav-pills tabs_signup hiredpppp justify-content-start gap-2" id="pills-tab"
                                                role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <Link className='nav-link ' to="/Student/Student_Mentor_List" id="pills-home-tab" data-bs-toggle="pill"
                                                        data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                                        aria-selected="true">All Mentors</Link>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <Link className="nav-link active" to="/Student/Student_Upcoming_Mentor_list" id="pills-disabled-tab" data-bs-toggle="pill"
                                                        data-bs-target="#pills-Upcoming" type="button" role="tab" aria-controls="pills-Upcoming"
                                                        aria-selected="false">Upcoming Session</Link>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <Link className="nav-link " to="/Student/Student_Mentor_Complate_session" id="pills-profile-tab" data-bs-toggle="pill"
                                                        data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                                                        aria-selected="false">Complete Session</Link>
                                                </li>
                                            </ul>
                                        </div>


                                    </div>
                                    {
                                        MentorUpcomingList && MentorUpcomingList?.length > 0 ? (
                                            <>
                                                <div className="col-12">
                                                    <form>
                                                        <div className="searchlocation">
                                                            <div className="iconfield">
                                                                <div className="icon_search">
                                                                    <img src={CommonImageTransport?.searchicon} alt="" />
                                                                </div>

                                                                {!showSearchFields ? (
                                                                    <div
                                                                        onClick={() => setShowSearchFields(true)}>
                                                                        <Typewriter
                                                                            options={{
                                                                                loop: true,
                                                                            }}
                                                                            onInit={(typewriter) => {
                                                                                typewriter.typeString('Search by title')
                                                                                    .callFunction(() => {
                                                                                    })
                                                                                    .pauseFor(2500)
                                                                                    .deleteAll()
                                                                                    .callFunction(() => {
                                                                                    })
                                                                                    .typeString('Search by location')
                                                                                    .pauseFor(2500)
                                                                                    .start();
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <input type="text" onChange={handlesearchinputChange}
                                                                            placeholder=""
                                                                            style={{ border: "none", outline: "none" }} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="iconfield">
                                                                <div className="icon_search">
                                                                    <img src={CommonImageTransport?.locationicon} alt="" />
                                                                </div>
                                                                <div className="input-field">
                                                                    <input type="text" placeholder="USA, Florida" onChange={handlesearchinputChange}
                                                                        className="form-control"
                                                                        required="" />
                                                                </div>
                                                            </div>
                                                            <div className="input-field ad121">
                                                                <button type="button" className="submit_btn">Search</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                {
                                                    is_Loading ? (
                                                        <>
                                                            <Loader />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="tab-content" id="pills-tabContent">
                                                                {/* <------- Upcoming Sessions ----------------> */}
                                                                <div className="tab-pane fade show active" id="pills-Upcoming" role="tabpanel"
                                                                    aria-labelledby="pills-Upcoming-tab"
                                                                    tabindex="0">
                                                                    <div className="left_recruiterlists row">
                                                                        <div className="col-md-4 col-lg-4">
                                                                            <div className="sticky-header">
                                                                                <div className="nav flex-column recruiter_all_lists nav-pills" id="v-pills-tab"
                                                                                    role="tablist" aria-orientation="vertical">
                                                                                    {
                                                                                        filteredUpComingMentorList?.map((MentorBookListResult, index) => {
                                                                                            return (
                                                                                                <div key={index}>
                                                                                                    <div key={index} className={`nav-link ${ActiveTabs === MentorBookListResult?.id ? "active" : ""}`}
                                                                                                        onClick={() => HandleActiveTabs(MentorBookListResult?.id)}
                                                                                                        id="v-pills-home-tab" data-bs-toggle="pill"
                                                                                                        data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
                                                                                                        aria-selected="true">
                                                                                                        <div className="card_rec position-relative">

                                                                                                            <div className="mentersvies0121">
                                                                                                                <div className="position-relative">
                                                                                                                    {
                                                                                                                        MentorBookListResult?.profileimg != null ? (
                                                                                                                            <>
                                                                                                                                <img src={MentorBookListResult?.profileimg}
                                                                                                                                    className="img-fluid rounded" alt="Instructor" />
                                                                                                                            </>
                                                                                                                        ) : (
                                                                                                                            <>
                                                                                                                                <img src={CommonImageTransport?.userLogo}
                                                                                                                                    className="img-fluid rounded" alt="Instructor" />
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    }

                                                                                                                    <span className="live_user">Available</span>
                                                                                                                </div>
                                                                                                                <div className="instructors-details-contents">
                                                                                                                    <div className="">
                                                                                                                        <div className="comnam">
                                                                                                                            <h3>{MentorBookListResult?.fname} {MentorBookListResult?.lname} </h3>
                                                                                                                            <ul className="nav mentor4541s text-dark align-items-center">
                                                                                                                                {/* <li className="me-2"><i className="ri-mail-line"></i>
                                                                                                                                    {MentorBookListResult?.email}
                                                                                                                                </li>
                                                                                                                                <li className="me-2"><i className="ri-phone-line"></i>
                                                                                                                                    {MentorBookListResult?.mobile}</li> */}
                                                                                                                                <li className="me-2">
                                                                                                                                    <i className="ri-map-pin-fill"></i>{MentorBookListResult?.city}, {MentorBookListResult?.state}, {MentorBookListResult?.country}</li>
                                                                                                                            </ul>
                                                                                                                            {/* <h6>Web designer</h6> */}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="d-flex justify-content-between 
                                                                                                align-items-center">
                                                                                                                <div>
                                                                                                                    <div> {MentorBookListResult?.profession?.replace(/[{}"]/g, '')?.split(", ").map((professionResult, index) => (
                                                                                                                        <div className='d-inline text-dark'
                                                                                                                            key={index}>{professionResult}</div>
                                                                                                                    ))}</div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }



                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <------ Upcoming Interview detail's --------------> */}
                                                                        {
                                                                            filteredUpComingMentorList?.find(job => job.id === ActiveTabs) && (
                                                                                <div className="col-md-8 col-lg-8">
                                                                                    <div className="tab-content" id="v-pills-tabContent">
                                                                                        <div className={`tab-pane fade ${ActiveTabs ? "show active" : ""}`}
                                                                                            id={`v-pills-${ActiveTabs}`}
                                                                                            role="tabpanel"
                                                                                            aria-labelledby={`v-pills-${ActiveTabs}-tab`}>

                                                                                            <div className="interviewstudent m-0">
                                                                                                <h3>Mentor Books</h3>
                                                                                                <div className="slider_descd">
                                                                                                    <div className="bg-light p-3">
                                                                                                        <div className="firsthiresd">
                                                                                                            <div className="card">
                                                                                                                <div className="card-body p-0">
                                                                                                                    <div
                                                                                                                        className="profileinterview position-relative p-3 d-md-flex gap-3">
                                                                                                                        {
                                                                                                                            filteredUpComingMentorList?.find(job => job.id === ActiveTabs)?.profileimg != null ? (
                                                                                                                                <>
                                                                                                                                    <img width="40" className="rounded-pill" height="40"
                                                                                                                                        src={filteredUpComingMentorList?.find(job => job.id === ActiveTabs)?.profileimg}
                                                                                                                                        onerror="src='../assets/images/img_avatar.png'"
                                                                                                                                        alt="" />
                                                                                                                                </>
                                                                                                                            ) : (
                                                                                                                                <>
                                                                                                                                    <img width="40" className="rounded-pill" height="40"
                                                                                                                                        src={CommonImageTransport?.userLogo}
                                                                                                                                        onerror="src='../assets/images/img_avatar.png'"
                                                                                                                                        alt="" />
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        }

                                                                                                                        <div>Interviewer:-
                                                                                                                            <h6 className="m-0">{filteredUpComingMentorList?.find(job => job.id === ActiveTabs)?.fname} {filteredUpComingMentorList?.find(job => job.id === ActiveTabs)?.lname}</h6>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="float-starts p-3 border-top">
                                                                                                                        <h6 className="mb-0">Session Book time & date</h6>
                                                                                                                        <div className="skillstopic">
                                                                                                                            <div><span
                                                                                                                                className="fw-bold text-primary">Date:</span>{moment(filteredUpComingMentorList?.find(job => job.id === ActiveTabs)?.bookig_date).format("MM/DD/YYYY")}
                                                                                                                            </div>
                                                                                                                            <div><span
                                                                                                                                className="fw-bold text-primary">Time:</span>{filteredUpComingMentorList?.find(job => job.id === ActiveTabs)?.slot_time
                                                                                                                                    ?.replace(/[{}"\\]/g, '')
                                                                                                                                    ?.split(/,\s*/)
                                                                                                                                    ?.map((skill, index, array) => (
                                                                                                                                        <span key={index}>
                                                                                                                                            {skill.trim()}
                                                                                                                                            {index < array.length - 1 && ', '}
                                                                                                                                        </span>
                                                                                                                                    ))}
                                                                                                                            </div>
                                                                                                                            <div><span
                                                                                                                                className="fw-bold text-primary">Day:</span>{filteredUpComingMentorList?.find(job => job.id === ActiveTabs)?.booking_day}
                                                                                                                            </div>
                                                                                                                            <div> <strong>Meeting Link:</strong><a className='text-primary' target="_blank"
                                                                                                                                href={filteredUpComingMentorList?.find(job => job.id === ActiveTabs)?.meeting_link}>{filteredUpComingMentorList?.find(job => job.id === ActiveTabs)?.meeting_link}</a>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </>

                                                    )
                                                }

                                            </>
                                        ) : (
                                            <>
                                                <div className='text-center mt-5'>
                                                    <h2>Not Avaliable Mentor Upcoming Session here...</h2>
                                                </div>
                                            </>
                                        )
                                    }


                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            <Student_Footer />
        </div>
    )
}

export default Student_Upcoming_Mentor_list