import React, { useState, useEffect } from "react";
import StudentHeader from "../student_header/Student_Header";
import { Link, useNavigate } from "react-router-dom";
import CommonImageTransport from "../../../common/images/CommonImages";
import { StudentAppliedJob } from "../../../../api/Student";
import ReactHtmlParser from "react-html-parser";
import Typewriter from "typewriter-effect";
import Loader from "../../../../loader/Loader";
import Student_Footer from "../student_footer/Student_Footer";
import moment from 'moment';
import { FaMoneyBill1Wave } from "react-icons/fa6";

const Student_Applied_Job = () => {
  const navigate = useNavigate()
  const [is_Loading, setIs_Loading] = useState(false);
  const [AppliedJobList, setAppliedJobList] = useState([]);
  const [SearchAppliedJobs, setSearchAppliedJobs] = useState([]);
  const [ActiveTabs, setActiveTabs] = useState(AppliedJobList?.id);
  const [showSearchFields, setShowSearchFields] = useState(false);

  // <------- User Authentication token's ------------>
  const token = localStorage.getItem("User_Token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // <---------- Handle the Active Tabs sections here ----------->
  const HandleActiveTabs = (tabId) => {
    setActiveTabs(tabId);
  };

  // <---------- Handle the Search Student Applied Jobs ---------------->
  const handleSearchStudentAllAppliedJobs = (e) => {
    setSearchAppliedJobs(e.target.value.toLowerCase());
  };

  // <--------- Get the Student Applied All Jobs section's ----------->
  const GetStudentAppliedJobs = async () => {
    setIs_Loading(true);
    try {
      const response = await StudentAppliedJob(headers);
      if (response?.data?.statusCode == 401) {
        localStorage.clear();
        navigate("/Signin")
        window.location.reload()
      }
      setIs_Loading(false);
      setAppliedJobList(response?.data?.data);
    } catch (error) {
      setIs_Loading(false);
    }
  };

  // <------ Filter the Jobs By Job title and Job Location's ------------->
  const filteredAllJobsList = AppliedJobList?.filter((filteredAllJobsListResult) =>
    filteredAllJobsListResult?.title?.toLowerCase().includes(SearchAppliedJobs) ||
    filteredAllJobsListResult?.location?.toLowerCase().includes(SearchAppliedJobs) ||
    filteredAllJobsListResult?.unique_id?.toLowerCase().includes(SearchAppliedJobs) ||
    filteredAllJobsListResult?.organization?.toLowerCase().includes(SearchAppliedJobs) ||
    filteredAllJobsListResult?.expertise?.toLowerCase().includes(SearchAppliedJobs) ||
    filteredAllJobsListResult?.currencytype?.toLowerCase().includes(SearchAppliedJobs)
  );
  console.log("AppliedJobList++++121312312", AppliedJobList)

  // <------- Render the Get the Student All Applied Jobs section's ----------->
  useEffect(() => {
    GetStudentAppliedJobs();
  }, []);

  // Automatically update ActiveTabs when filteredAppliedList changes
  useEffect(() => {
    if (filteredAllJobsList?.length > 0 && (!ActiveTabs || !filteredAllJobsList?.some(job => job.id === ActiveTabs))) {
      const firstActiveTab = filteredAllJobsList[0];
      if (firstActiveTab) {
        setActiveTabs(firstActiveTab?.id);
      }
    }
  }, [filteredAllJobsList, ActiveTabs]);

  console.log("Active Tab (Applied List):", ActiveTabs);

  return (
    <div>
      {is_Loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <StudentHeader />
          <div className="header_space"></div>
          <div className="recruiter_list pb-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 mt-2">
                  <ul className="nav nav-pills tabs_signup hiredpppp justify-content-start gap-2">
                    <li className="nav-item">
                      <Link className="nav-link " to="/Student/Student_jobs">
                        All Jobs
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        to="/Student/Student_Applied_Job"
                      >
                        Applied
                      </Link>
                    </li>
                  </ul>
                </div>
                {AppliedJobList?.length > 0 ? (
                  <>
                    <div className="col-12">
                      <form>
                        <div className="searchlocation">
                          <div className="iconfield">
                            <div className="icon_search">
                              <img
                                src={CommonImageTransport?.searchicon}
                                alt=""
                              />
                            </div>
                            {!showSearchFields ? (
                              <div onClick={() => setShowSearchFields(true)}>
                                <Typewriter
                                  options={{
                                    loop: true,
                                  }}
                                  onInit={(typewriter) => {
                                    typewriter
                                      .typeString("Search by title")
                                      .callFunction(() => { })
                                      .pauseFor(2500)
                                      .deleteAll()
                                      .callFunction(() => { })
                                      .typeString("Search by location")
                                      .pauseFor(2500)
                                      .start();
                                  }}
                                />
                              </div>
                            ) : (
                              <div>
                                <input
                                  type="text"
                                  onChange={handleSearchStudentAllAppliedJobs}
                                  placeholder=""
                                  style={{ border: "none", outline: "none" }}
                                />
                              </div>
                            )}
                          </div>
                          <div className="iconfield">
                            <div className="icon_search">
                              <img
                                src={CommonImageTransport?.locationicon}
                                alt=""
                              />
                            </div>
                            <div className="input-field">
                              <input
                                type="text"
                                onChange={handleSearchStudentAllAppliedJobs}
                                placeholder="USA, Florida"
                                className="form-control"
                                required=""
                              />
                            </div>
                          </div>
                          <div className="input-field ad121">
                            <button
                              type="button"
                              className="submit_btn"
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="left_recruiterlists row">
                      <div className="col-md-4 col-lg-4">
                        <div className="sticky-header">
                          <div
                            className="nav flex-column recruiter_all_lists nav-pills"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical">
                            {filteredAllJobsList?.reverse()?.map(
                              (AppliedJobListResult, index) => {
                                if (AppliedJobListResult?.status == "0") {
                                  return null;
                                }
                                return (
                                  <>
                                    <div
                                      key={index}
                                      className={`nav-link ${ActiveTabs === AppliedJobListResult?.id ? "active" : ""}`}
                                      onClick={() => HandleActiveTabs(AppliedJobListResult?.id)}
                                      id="v-pills-home-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#v-pills-home"
                                      type="button"
                                      role="tab"
                                      aria-controls="v-pills-home"
                                      aria-selected="true">
                                      <div className="card_rec position-relative">
                                        <div className="mentersvies0121">
                                          <div>
                                            {
                                              AppliedJobListResult?.job_image != null ? (
                                                <>
                                                  <img src={AppliedJobListResult?.job_image}
                                                    className="img-fluid rounded" alt="Instructor" />
                                                </>

                                              ) : (
                                                <>
                                                  <img src={CommonImageTransport?.companybuilding}
                                                    className="img-fluid rounded" alt="Instructor" />
                                                </>
                                              )
                                            }

                                          </div>
                                          <div className="instructors-details-contents">
                                            <div className="comnam">
                                              <h3>
                                                {AppliedJobListResult?.title}{" "}
                                              </h3>
                                              <h6 className="sub-title">Job Id:{AppliedJobListResult?.unique_id}</h6>
                                              <h6 className="sub-title">
                                                <i className="ri-community-line"></i>
                                                {AppliedJobListResult?.organization}
                                              </h6>
                                            </div>
                                            <p className="perographsv">
                                              <i className="ri-map-pin-fill"></i>
                                              {AppliedJobListResult?.location}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="skillstopic">
                                            <div>
                                              {AppliedJobListResult?.employmenttype}
                                            </div>
                                          </div>
                                          <div className="skillstopic">
                                            <div>
                                              {AppliedJobListResult?.expertise}
                                            </div>
                                          </div>
                                          <div className="skillstopic">
                                            <div>
                                              <strong>
                                                {AppliedJobListResult?.currencytype}
                                                :
                                              </strong>
                                              {AppliedJobListResult?.salarypackage}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>

                      {
                        filteredAllJobsList?.find(job => job.id === ActiveTabs && job.status == "1") && (
                          <div className="col-md-8 col-lg-8">
                            <div className="tab-content" id="v-pills-tabContent">
                              <div
                                className={`tab-pane fade ${ActiveTabs ? "show active" : ""}`}
                                id={`v-pills-${ActiveTabs}`}
                                role="tabpanel"
                                aria-labelledby={`v-pills-${ActiveTabs}-tab`}>
                                <div className="instructors-details-img m-0">
                                  <div className="card-instrutors shadow position-relative bg-white p-4">
                                    <div className="mentersvies0121 recruiter101s">
                                      <div>
                                        {
                                          filteredAllJobsList?.find(job => job.id === ActiveTabs)?.job_image != null ? (
                                            <>
                                              <img src={filteredAllJobsList?.find(job => job.id === ActiveTabs)?.job_image}
                                                className="img-fluid rounded" alt="Instructor" />
                                            </>

                                          ) : (
                                            <>
                                              <img src={CommonImageTransport?.companybuilding}
                                                className="img-fluid rounded" alt="Instructor" />
                                            </>
                                          )
                                        }
                                      </div>

                                      <div className="instructors-details-contents">
                                        <h3>
                                          {filteredAllJobsList?.find((job) => job.id === ActiveTabs)?.title}
                                        </h3>
                                        <h6>
                                          Job Id: {filteredAllJobsList?.find((job) => job.id === ActiveTabs)?.unique_id}
                                        </h6>

                                        <p>
                                          <a href="#">
                                            <i className="ri-community-line"></i>
                                            {filteredAllJobsList?.find((job) => job.id === ActiveTabs)?.organization}
                                          </a>
                                        </p>
                                        <p>
                                          <i className="ri-map-pin-fill"></i>{" "}
                                          {
                                            filteredAllJobsList?.find((job) => job.id === ActiveTabs)?.location}
                                        </p>
                                        <div className="d-flex justify-content-around align-items-center">
                                          <p className="small m-0 fw-bold">
                                            <span className="fw-normal">
                                              Application Start :
                                            </span>
                                            {moment(AppliedJobList?.find((job) => job.id === ActiveTabs)?.startdate).format("MM/DD/YYYY")}
                                          </p>
                                          <p className="small m-0 fw-bold">
                                            <span className="fw-normal">
                                              Application End :
                                            </span>
                                            {moment(AppliedJobList?.find((job) => job.id === ActiveTabs)?.enddate).format("MM/DD/YYYY")}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="footerapplly border-top d-flex justify-content-between mt-3 pt-2">
                                      {(() => {
                                        const job = AppliedJobList?.find((job) => job.id === ActiveTabs);
                                        if (!job) return null;

                                        // Determine the correct status to show based on the properties
                                        if (job.technical_status === 2 && job.behavioral_status !== 2) {
                                          return (
                                            <a href="#" className="default-btn bg-info rounded py-1">
                                              <i className="ri-check-double-line h6 mb-1 me-2"></i> Behavioral
                                            </a>
                                          );
                                        }

                                        if (job.behavioral_status === 2 && job.technical_status !== 2) {
                                          return (
                                            <a href="#" className="default-btn bg-info rounded py-1">
                                              <i className="ri-check-double-line h6 mb-1 me-2"></i> Technical
                                            </a>
                                          );
                                        }

                                        if (job.technical_status === 2 && job.behavioral_status === 2 && job.fianl_decision !== 3 && job.fianl_decision !== 0) {
                                          return (
                                            <a href="#" className="default-btn bg-primary rounded py-1">
                                              <i className="ri-check-double-line h6 mb-1 me-2"></i> Decision
                                            </a>
                                          );
                                        }

                                        if (job.fianl_decision === 3) {
                                          return (
                                            <a href="#" className="default-btn bg-success rounded py-1">
                                              <i className="ri-check-double-line h6 mb-1 me-2"></i> Hired
                                            </a>
                                          );
                                        }

                                        if (job.final_decision === 0) {
                                          return (
                                            <a href="#" className="default-btn bg-danger rounded py-1">
                                              <i className="ri-close-line h6 mb-1 me-2"></i> Rejected
                                            </a>
                                          );
                                        }

                                        // Default case for other statuses
                                        switch (job?.applied_status) {
                                          case 1:
                                            return (
                                              <a href="#" className="default-btn bg-warning rounded py-1">
                                                <i className="ri-check-double-line h6 mb-1 me-2"></i> Pending
                                              </a>
                                            );
                                          case 2:
                                            return (
                                              <a href="#" className="default-btn bg-success rounded py-1">
                                                <i className="ri-check-double-line h6 mb-1 me-2"></i> Accepted
                                              </a>
                                            );
                                          case 0:
                                            return (
                                              <a href="#" className="default-btn bg-danger rounded py-1">
                                                <i className="ri-close-line h6 mb-1 me-2"></i> Rejected
                                              </a>
                                            );
                                          case 3:
                                            return (
                                              <a href="#" className="default-btn bg-primary rounded py-1">
                                                <i className="ri-check-double-line h6 mb-1 me-2"></i> Processing
                                              </a>
                                            );
                                          default:
                                            return null;
                                        }
                                      })()}

                                      {
                                        filteredAllJobsList?.find(job => job.id === ActiveTabs)?.salarypackage != "0" && (
                                          <div className="d-flex gap-2 align-items-center">
                                            <div className="bg-light p-2"><FaMoneyBill1Wave /></div>
                                            <div>
                                              <p className="small m-0 fw-bold">
                                                {filteredAllJobsList?.find(job => job.id === ActiveTabs)?.currencytype} : {filteredAllJobsList?.find(job => job.id === ActiveTabs)?.salarypackage}
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      }

                                      <div className="d-flex gap-2 align-items-center">
                                        <div className="bg-light p-2"><i className="ri-eye-line"></i></div>
                                        <div>
                                          <p className="small m-0 fw-bold">
                                            <span className="fw-normal">Job Type:</span> {filteredAllJobsList?.find(job => job.id === ActiveTabs)?.employmenttype}
                                          </p>
                                        </div>
                                      </div>

                                      <div className="d-flex gap-2 align-items-center">
                                        <div className="bg-light p-2"><i className="ri-user-line"></i></div>
                                        <div>
                                          <p className="small m-0 fw-bold">
                                            <span className="fw-normal">No of Openings:</span>
                                            {filteredAllJobsList?.find(job => job.id === ActiveTabs)?.postion_no}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  data-bs-spy="scroll"
                                  data-bs-target="#navbar-example2"
                                  data-bs-offset="100"
                                  className="scrollspy-example"
                                  tabindex="0"
                                >
                                  <div
                                    id="scrollspyHeading1"
                                    className="instructors-details-contents mt-3"
                                  >
                                    <div className="card-instrutors shadow bg-white p-4">
                                      <h5>Job Description</h5>
                                      <p>
                                        {ReactHtmlParser(
                                          AppliedJobList?.find(
                                            (job) => job.id === ActiveTabs
                                          )?.aboutjob
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        )
                      }

                    </div>
                  </>
                ) : (
                  <div style={{ marginTop: "100px" }} className="text-center">
                    <h4>Not Any Applied Jobs here</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <Student_Footer />
    </div>
  );
};

export default Student_Applied_Job;
